

import React, { useState, useEffect } from "react";
import './ErrorNotification.css';
interface PopupProps {
  message?: string;
}

const Popup: React.FC<PopupProps> = ({ message = ""}) => {
  var [visible, setVisible] = React.useState(!!message);
  

  React.useEffect(() => {
    setVisible(!!message);
    

    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [message]);

  return (
    <>
      {visible && <div className="popup popupMessage">{message}</div>}
    </>
  );
};

export default Popup;