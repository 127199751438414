import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { InputProps } from 'semantic-ui-react';
import './SelectBar.css';

const TabStates = {
    myOffers: "Задание на изменение",
    childOffers: "Предложения детей",
    quality: "Оценка качества"
}

export type SelectBarState = {
}

type SelectBarProp = {
    elems: string[],
    selectedElem: string,
    notificationOfferTaskCount?: number,
    notificationOfferChangeTaskCount?: number,
    elemClick: (clickedElem:string) => void
}

export class SelectBar extends React.Component<SelectBarProp & InputProps, SelectBarState> {
    delayedVerification: any;

    constructor(props: SelectBarProp & InputProps) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <div className="select-bar">
                {this.props.elems.map((elem: string) =>
                    <div className={"select-bar-elem " + (elem === this.props.selectedElem ? "bar-elem-selected" : "")}
                        onClick={() => {
                            this.props.elemClick(elem);
                        }}
                    >
                        { elem === TabStates.childOffers && this.props.notificationOfferTaskCount && this.props.notificationOfferTaskCount! !== 0  ?
                            <span className={"ui circular label blue notificationRed counter"}>
                                {this.props.notificationOfferTaskCount! < 100 ? this.props.notificationOfferTaskCount! : "99+"}
                            </span>
                            : ""}
                        { elem === TabStates.myOffers && this.props.notificationOfferChangeTaskCount && this.props.notificationOfferChangeTaskCount! !== 0  ?
                            <span className={"ui circular label blue notificationRed counter"}>
                                {this.props.notificationOfferChangeTaskCount! < 100 ? this.props.notificationOfferChangeTaskCount! : "99+"}
                            </span>
                        : ""}
                        { elem === TabStates.quality && this.props.notificationOfferChangeTaskCount && this.props.notificationOfferChangeTaskCount! !== 0  ?
                            <span className={"ui circular label blue notificationRed counter"}>
                                {this.props.notificationOfferChangeTaskCount! < 100 ? this.props.notificationOfferChangeTaskCount! : "99+"}
                            </span>
                        : ""}


                        <p>{elem}</p>
                    </div>)
                }
            </div>
        );
    }
}