import {
    GIFT_LIST_FAILED,
    GIFT_LIST_SUCCESS,
    GIFT_LIST_PROCESS,
    GIFT_SELECT,
    GIFT_FAILED,
    GIFT_SUCCESS,
    GIFT_PROCESS,
    GIFT_FAILED_OVERBOOKED,
    GIFT_RESERVED_LIST_PROCESS,
    GIFT_RESERVED_LIST_SUCCESS,
    GIFT_RESERVED_LIST_FAILED,
    GIFT_CANCEL_PROCESS,
    GIFT_CANCEL_SUCCESS,
    GIFT_CANCEL_FAILED,
    GIFT_RATING_FAILED,
    GIFT_RATING_PROCESS, GIFT_RATING_SUCCESS,
    GIFT_WISHED_SUCCESS,
    GIFT_CATEGORIES_SUCCESS,
    GIFT_CATEGORIES_PROCESS,
    GIFT_CATEGORIES_FAILED,
    GIFT_HISTORY_SUCCESS,
    SUPER_GIFT_FAILED,
    SUPER_GIFT_FAILED_OVERBOOKED,
    SUPER_GIFT_SUCCESS,
    SUPER_GIFT_PROCESS,

    CONFIRM_GIFT_SUCCESS,
    CONFIRM_GIFT_FAILED,
    CONFIRM_GIFT_PROCESS,

    UPDATE_ERROR
} from "./types";

import {Reducer} from "redux";
import {Gift, GiftCategory, GiftReserved, GiftStore} from "../giftType";
import { emit } from "process";

const initialState = () => {
    return {
        list: [] as Gift[],
        listReserved: [] as GiftReserved[],
        reservationCompleted: false,
        selectedItem: undefined,
        errorInList: '',
        errorInProcess: '',
        errorInRatingProcess: '',
        operationInProcess: false,
        listInProcess: false,
        typeInProcess: false,
        giftCategories: [] as GiftCategory[],
        errorInType: "",

        confirmgiftProcess: false,
        errorconfirmgift: '',
        confirmedGift: false
    } as GiftStore;
};

const taskStore: Reducer = (state: GiftStore = initialState(), action: any) => {
    switch (action.type) {
        case GIFT_LIST_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcess: false
            };
        }
        case GIFT_LIST_SUCCESS: {
            return {
                ...state,
                listInProcess: false,
                list: action.data,
            };
        }
        case GIFT_LIST_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcess: true
            };
        }

        case GIFT_RESERVED_LIST_FAILED: {
            return {
                ...state,
                errorInList: action.errorMessage,
                listInProcess: false
            };
        }
        case GIFT_RESERVED_LIST_SUCCESS: {
            return {
                ...state,
                listInProcess: false,
                listReserved: action.data
            };
        }
        case GIFT_RESERVED_LIST_PROCESS: {
            return {
                ...state,
                errorInList: '',
                listInProcess: true
            };
        }

        case GIFT_SELECT: {
            return {
                ...state,
                errorInList: '',
                errorInProcess: '',
                selectedItem: action.data,
                operationInProcess: false,
                reservationCompleted: false
            };
        }

        case GIFT_FAILED: {
            return {
                ...state,
                errorInProcess: action.errorMessage,
                operationInProcess: false
            };
        }
        case GIFT_FAILED_OVERBOOKED: {
            return {
                ...state,
                operationInProcess: false,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data : todo
                ),
                selectedItem: action.data,
                reservationCompleted: false,
                errorInProcess: 'Количество запрашиваемых к резервированию подарков меньше доступного.'

            };
        }        
        case GIFT_SUCCESS: {
            return {
                ...state,
                operationInProcess: false,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data :
                    todo
                ),
                reservationCompleted: true
                
            };
        }
        case GIFT_PROCESS: {
            return {
                ...state,
                errorInProcess: '',
                operationInProcess: true,
                reservationCompleted: false
            };
        }

        case GIFT_WISHED_SUCCESS: {
            let list = state.list.map(todo => todo.id === action.data.id ?
                action.data :
                todo
            )

            let wished = new Array;

            list.forEach((element: Gift) => {
                if (element.inWishList) {
                    wished.push(element);
                }
            });

            return {
                ...state,
                list: list,
                wishList: wished,
                operationInProcess: false,
                selectedItem: action.data
            };
        }
        case GIFT_HISTORY_SUCCESS: {
            return {
                ...state,
                list: state.list.map(todo => todo.id === action.data.id ?
                    action.data :
                    todo
                ),
                selectedItem: action.data
            };
        }
        case GIFT_CANCEL_FAILED: {
            return {
                ...state,
                errorInCancelProcess: action.errorMessage,
                operationInProcess: false
            };
        }
        case GIFT_CANCEL_SUCCESS: {
            return {
                ...state,
                operationInProcess: false,
                errorInCancelProcess: '',
                listReserved: state.listReserved.filter(item => item.id !== action.data.id),
            };
        }
        case GIFT_CANCEL_PROCESS: {
            return {
                ...state,
                errorInCancelProcess: '',
                operationInProcess: true
            };
        }

        case GIFT_RATING_FAILED: {
            return {
                ...state,
                errorInRatingProcess: action.errorMessage,
                operationInProcess: false
            };
        }
        case GIFT_RATING_SUCCESS: {
            return {
                ...state,
                operationInProcess: false,
                listReserved: state.listReserved.map(todo => todo.id === action.data.id ?
                    action.data :
                    todo
                )
            };
        }
        case GIFT_RATING_PROCESS: {
            return {
                ...state,
                errorInRatingProcess: '',
                operationInProcess: true,
                loadedPhotos: []
            };
        }
        case GIFT_CATEGORIES_FAILED: {
            return {
                ...state,
                errorInType: action.errorMessage,
                typeInProcess: false
            };
        }
        case GIFT_CATEGORIES_SUCCESS: {
            return {
                ...state,
                typeInProcess: false,
                giftCategories: action.data
            };
        }
        case GIFT_CATEGORIES_PROCESS: {
            return {
                ...state,
                errorInType: '',
                typeInProcess: true
            };
        }

        case SUPER_GIFT_FAILED: {
            return {
                ...state,
                errorInProcess: action.errorMessage,
                operationInProcess: false
            };
        }
        case SUPER_GIFT_FAILED_OVERBOOKED: {
            return {
                ...state,
                operationInProcess: false,
                list: state.list.map(g => g.id === action.data.id ?
                    action.data : g
                ),
                selectedItem: action.data,
                reservationCompleted: false,
                errorInProcess: 'Количество запрашиваемых к резервированию подарков меньше доступного.'
            };
        }        
        case SUPER_GIFT_SUCCESS: {
            return {
                ...state,
                operationInProcess: false,
                list: state.list.map(g => g.id === action.data.id ?
                    action.data : g
                ),
                selectedItem: action.data,
                reservationCompleted: true
            };
        }
        case SUPER_GIFT_PROCESS: {
            return {
                ...state,
                errorInProcess: '',
                operationInProcess: true,
                reservationCompleted: false
            };
        }
        case CONFIRM_GIFT_SUCCESS: {
            return {
                ...state,
                confirmedGift: true,
                confirmgiftProcess: true,
                listReserved: state.listReserved.filter(item => !action.data.includes(item.id))
            };
        }
        case CONFIRM_GIFT_PROCESS: {
            return {
                ...state,
                errorconfirmgift: '',
                confirmgiftProcess: false,
                
            };
        }
        case CONFIRM_GIFT_FAILED: {
            return {
                ...state,
                errorconfirmgift: action.errorMessage,
                confirmgiftProcess: false
            };
        }
        case UPDATE_ERROR: {
            return {
                ...state,
                errorconfirmgift: action.errorMessage,
                confirmedGift: false
            }
        }
        default:
            return state;
    }
};

export default taskStore;