import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Input, Icon, Image, Transition, Modal, InputOnChangeData } from 'semantic-ui-react'
import { ApprovingModel, ApprovingStore, Camper } from "../../store/approvingType";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as approvingActions from "./../../store/approving/actions"
import './CamperRow.css';
import { QRScanner } from '../../components/UI/QRScanner/QRScanner';
import { ChildReg } from './ChildReg';
import { RegisterChildInfo } from '../../store/baseType';

type CamperRowState = {
    spoilered: boolean,
    qrOpened: boolean,
    infoOpened: boolean,
    codeValue?: string,
    qrErrorMsg?: string,
}

type CamperRowProp = {
    camper: Camper,
    approveChild: Function,
    regChildAcc: (data: RegisterChildInfo) => void,
    regChildAccError?:string,
}


export class CamperRow extends React.Component<CamperRowProp, CamperRowState> {

    constructor(props: CamperRowProp) {
        super(props);
        this.state = {
            spoilered: true,
            qrOpened: false,
            infoOpened: false,
        }
    }

    render() {
        return (
            <div className="camper-row">
                <div className="camper-header" onClick={() => this.setState({ spoilered: !this.state.spoilered })}>
                    <div className="camper-sub-header">
                        <Icon color={this.props.camper?.accountId ? "grey" : "red"} size="large" name={this.props.camper?.accountId ? "linkify" : "unlink"} />
                        <h4>{this.props.camper?.name}</h4>
                    </div>
                    <Button basic icon={this.state.spoilered ? "angle down" : "angle up"} onClick={() => this.setState({ spoilered: !this.state.spoilered })} />
                </div>
                <Transition visible={!this.state.spoilered} animation="fade down" duration={350}>
                    <div>
                        {this.props.camper.accountId ?
                            <div className="camper-link-acc">
                                <Modal
                                    onOpen={() => this.setState({ infoOpened: true })}
                                    onClose={() => this.setState({ infoOpened: false })}
                                    closeIcon
                                    dimmer="blurring"
                                    trigger={<Icon onClick={() => this.setState({ infoOpened: true })} circular name="info" /> }
                                >
                                    <Modal.Header>
                                        {this.props.camper.name }
                                    </Modal.Header>
                                    <Modal.Content>
                                        <div className="camper-link-info">
                                            <p>Никнейм: <b>{this.props.camper.nickName ? (this.props.camper.nickName) : "не указан"}</b></p>
                                            <p>Email: <b>{this.props.camper.email ? (this.props.camper.email) : "не указан"}</b></p>
                                            <p>Телефон: <b>{this.props.camper.phone ? (this.props.camper.phone) : "не указан"}</b></p>
                                        </div>
                                    </Modal.Content>
                                </Modal>
                                <p><b>{this.props.camper.email}</b></p></div>
                            : ""}
                        <Input
                            className="camper-input-code"
                            placeholder="Код профиля ребенка"
                            action
                            onChange={(event, data: InputOnChangeData) => this.setState({ codeValue: data.value })}
                        >
                            <Button className="qr-btn" size="big" icon="qrcode" onClick={() => this.setState({ qrOpened: true })} />
                            <input value={this.state.codeValue} />
                            <Button disabled={this.state.codeValue ? false : true} size="medium" title="Пожтвердить" onClick={() =>
                                this.props.approveChild({
                                    key: this.state.codeValue,
                                    childId: this.props.camper?.id,
                                    link: true
                                } as ApprovingModel)} >Подтвердить</Button>
                        </Input>
                        {this.props.camper?.errorMessage ? <p className="camper-approve-error">{this.props.camper?.errorMessage}</p> : ""}
                        {this.props.camper?.accountId ?
                            "" :
                            <ChildReg regAcc={((data: RegisterChildInfo) => this.props.regChildAcc(data)) } error={ this.props.regChildAccError } camper={ this.props.camper } trigger={
                                <Button size="large" color="violet" className="reg-acc-btn">
                                    Зарегистрировать УЗ
                                </Button>
                            }
                            />

                        }
                    </div>
                </Transition>


                <QRScanner
                    error={this.state.qrErrorMsg}
                    onClose={() => this.setState({ qrOpened: false })}
                    open={this.state.qrOpened}
                    onScan={(data: string | null) => {
                        if (data) {
                            if (data.match(/\w-\w/i)) {
                                this.setState({ codeValue: data ?? undefined, qrOpened: false });
                            }
                            else {
                                this.setState({ qrErrorMsg: "Не правильный QR-Код" })
                            }
                        }
                    }}
                    onError={() => { }} />
            </div>
        );
    }
}