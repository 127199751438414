import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Input, Icon, Image, Transition, Modal, InputOnChangeData } from 'semantic-ui-react'
import { ApprovingModel, ApprovingStore, Camper } from "../../store/approvingType";
import { RegisterChildInfo } from '../../store/baseType';
import './ChildReg.css';
import _ from "lodash";

type ChildRegState = {
    opened: boolean,
    email?: string,
    phone?:string,
    nickName?:string,
    errors: string[],
}

type ChildRegProp = {
    trigger?: React.ReactNode,
    camper: Camper,
    error?: string,
    regAcc:Function,
    errorMessage?: string,
}




export class ChildReg extends React.Component<ChildRegProp, ChildRegState> {

    constructor(props: ChildRegProp) {
        super(props);
        this.state = {
            opened:false,
            errors: [],
            phone: "+7"
        }
    }

    validation = () => {
        let errors = [];

        if (!(this.state.nickName)) {
            errors.push('Не указано имя');
        }

        if (this.state.email && this.state.email.length > 0 && !(this.state.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
            errors.push('Указан некорректный адрес электронной почты');
        }

        if (!(this.state.email)) {
            errors.push('Не указана электронная почта');
        }

        if (this.state.phone && this.state.phone.length > 2 && !(this.state.phone.match(/^[+]{1}[0-9]{11}$/))) {
            errors.push('Указан некорректный номер телефона');
        }

        if (this.state.phone && this.state.phone.length <= 2) {
            errors.push('Не указан номер телефона');
        }

        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }

    render() {
        return (
            <Modal
                size="large"
                dimmer={"blurring"}
                closeIcon
                trigger={this.props.trigger}
                open={this.state.opened}
                onClose={() => this.setState({opened:false}) }
                onOpen={() => this.setState({ opened: true })}
            >
                <Modal.Header>
                    Регистрация учетной записи
                </Modal.Header>
                <Modal.Content scrolling>
                    <div>
                        <Input size="huge" icon='user' iconPosition='left' placeholder='Никнейм'
                            className="login-input"
                            value={this.state.nickName}
                            onChange={(event: React.ChangeEvent, data: InputOnChangeData) => {
                                this.setState({ nickName: data.value })
                            }}
                        />
                        <Input size="huge" icon='mail' iconPosition='left' placeholder='Электронная почта'
                            className="login-input"
                            value={this.state.email}
                            onChange={(event:React.ChangeEvent, data: InputOnChangeData) => {
                                //this.setState({email:data.value})
                                this.setState({ email: formatEmail(data.value) })
                            } }
                        />
                        <Input size="huge" icon='phone' iconPosition='left' placeholder='Телефон'
                            className="login-input"
                            value={this.state.phone}
                            onChange={(event: React.ChangeEvent, data: InputOnChangeData) => {
                                this.setState({ phone: formatPhone(data.value) })
                            }}
                        />
                        <div className="div-space">&nbsp;</div>

                        {(this.state.errors.length > 0 || this.props.errorMessage) ?
                            <ul>
                                {this.state.errors.length > 0 ? _.map(this.state.errors, (item: any) => <li
                                        className={"error-class"}>{item}</li>) :
                                    this.props.errorMessage ?
                                        <li className={"error-class"}>{this.props.errorMessage}</li> : ""}
                            </ul>
                            : ""
                        }
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div className="child-reg-actions">
                        {this.props.error ? <p className="error">{this.props.error}</p> : ""}
                        <Button color="violet" onClick={() => {
                            if (!this.validation()) {
                                return;
                            }
                            this.props.regAcc({
                                nickName: this.state.nickName,
                                email: this.state.email,
                                phone: this.state.phone,
                                camper: this.props.camper,
                            } as RegisterChildInfo);
                            this.setState({ opened: false });
                        }}>
                            Подтвердить
                        </Button>
                    </div>
                </Modal.Actions>
            </Modal>
        );
    }
}

var validEmail = "";
function formatEmail(email: string) {
    if (email.match(/^[a-z0-9._-\s@]+$/) || email.length == 0) 
    {
        validEmail = email;
    }
    return validEmail;
}

var validPhone = "+7";
function formatPhone(phone: string) {
    if (phone.match(/^[+]{1}[0-9]+$/) && phone.length <= 12) validPhone = phone;
    return validPhone;
}