import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Icon } from 'semantic-ui-react'
import * as giftActions from "./../../store/gift/actions"
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Gift, GiftCategory, GiftParameter, GiftStore } from "../../store/giftType";
import './Gift.css';
import { CurrentConfig } from '../../api/config';
import { AuthStore, BoutWinner, User, CurrentBoutOutcome } from "../../store/baseType";
import _ from "lodash";
import heart_pressed from './../../images/heart_pressed.png';
import heart_onpressed from './../../images/heart_onpressed.png';
import { GiftWishHeart } from '../../components/UI/GiftWishHeart'
import { GiftPosterComponent } from '../../components/UI/GiftPosterComponent';
import notification_icon from './../../images/notification.svg';
import Timer from 'react-compound-timerv2/build';

type GiftState = {
    param: number | undefined,
    count: number
}

type GiftCardProp = {
    backToList: Function,
    reserve: Function,
    addToWishList: Function,
    deleteFromWishList: Function,
    addLookToHistory: Function,
    updateGiftList: Function,
    counselor: boolean,
    reservationCompleted: boolean,
    selectedItem: Gift | undefined,
    errorInProcess: string,
    operationInProcess: boolean,
    winner: BoutWinner,
    reserveSuperGift: Function
    user: User;
}


class GiftCard extends React.Component<GiftCardProp, GiftState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;
    upload: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            param: undefined,
            count: 1
        }
    }

    /* резервирование подарка */
    reserve() {
        this.props.reserve({
            id: this.state.param,
            count: this.state.count
        });
    }

    /* резервирование супер-приза */
    reserveSuperGift() {
        this.props.reserveSuperGift({
            superGiftId: this.props.selectedItem?.id,
            superGiftParamId: this.state.param,
            winnerId: this.props.winner?.winnerId
        });
    }

    addToWishList() {
        this.props.addToWishList(this.props.selectedItem?.id);
        this.props.updateGiftList();
    }
    deleteFromWishList() {
        this.props.deleteFromWishList(this.props.selectedItem?.id);
        this.props.updateGiftList();
    }

    addLookToHistory() {
        this.props.addLookToHistory(this.props.selectedItem?.id);
        this.props.updateGiftList();
    }

    componentDidMount() {
        this.addLookToHistory();

        if (this.props.selectedItem?.superPrize) {
            this.setState({
                ...this.state,
                param: this.props.selectedItem?.giftParameters[0]?.id
            })
        }

        window.scrollTo({
            top: 0
        });
    }

    generateOrderButton(giftCard: GiftCard) {
        return (
            <div>
                {!giftCard.props.reservationCompleted && !giftCard.props.selectedItem?.superPrize && giftCard.props.selectedItem?.allowOrder ?
                    (<Button disabled={!giftCard.props.selectedItem?.allowOrder} id="gift-card-order-button" color={"violet"} fluid onClick={() => {
                        this.reserve();
                    }}>Заказать</Button>
                    ) :
                    ""}
            </div>
        );
    }

    generateOrderSuperButton(giftCard: GiftCard) {
        return Date.parse(this.props.winner?.startDate.toString()) < Date.now()
            && Date.parse(this.props.winner?.finishDate.toString()) > Date.now() ?
            (this.props.selectedItem?.giftParameters != undefined &&  this.props.selectedItem?.giftParameters.length > 0 ? (
                <div>
                    <Button id="gift-card-order-button" color={"violet"} fluid onClick={() => {
                        this.reserveSuperGift();
                    }}>Заказать</Button> 
                </div>
        ) : (<div>
                Закончились! 
                Попробуй забронировать другой!
            </div>)) : "";
    }

    generateOrderDeniedReasons(giftCard: GiftCard) {
        return !giftCard.props.selectedItem?.superPrize && giftCard.props.selectedItem?.orderDeniedReasons && !giftCard.props.selectedItem?.allowOrder ? (
            <div id="gift-card-notification-text">{giftCard.props.selectedItem?.orderDeniedReasons.map((item) => <li>{item}</li>)}
                <div id="gift-card-notification-icon"><img alt="" src={notification_icon} /></div>
            </div>
        ) : "";
    }

    render() {
        return (
            <div>
                <div
                    className={this.props.operationInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Загрузка...</div>
                </div>

                <div className={"gift-back-button"} onClick={() => {
                    this.props.backToList();
                }}>
                    <Icon name="arrow left" />назад
                </div>

                <div style={{height:"70vw", margin:"8%"} }>
                    <GiftPosterComponent shopCard={ false } wished={this.props.selectedItem?.inWishList ?? false} giftPhotoUrl={this.basePath + '/downloadFileByLink?id=' + this.props.selectedItem?.linkId}
                        giftWishOnClick={this.props.selectedItem?.inWishList
                            ? () => { this.deleteFromWishList(); }
                            : () => { this.addToWishList(); }}
                    />

                </div>

                <div className={"gift-card-description"}>
                    {/* <div className={"gift-card-description-title"}> */}
                    <div className={"gift-card-description-title"}>{this.props.selectedItem?.nameForShop ?? this.props.selectedItem?.name}</div>
                    {/* </div> */}
                    {!this.props.selectedItem?.superPrize ?
                        <div>
                            <table>
                                <tbody>
                                    <tr>

                                        <td className={"gift-label"}>Стоимость в баллах</td>
                                        <td align="right">
                                            <div className={"gift-value"}>{this.props.selectedItem?.price ?? 0}</div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        : ""}
                    {this.props.errorInProcess /*&& !this.props.selectedItem?.superPrize*/ ?
                        <div className={"task-error-message"}>{this.props.errorInProcess}</div> : ""}
                    <div className={"gift-label"}>Описание</div>
                    <div className={"gift-card-description2"}>{this.props.selectedItem?.description}</div>



                    {this.props.selectedItem?.giftCategory ? <div className={"gift-label"}>Вид подарка: {this.props.selectedItem?.giftCategory.name}</div> : ""}
                    <div className={"gift-params"}>
                        {_.map(this.props.selectedItem?.giftParameters, (param: GiftParameter) => ((!this.props.reservationCompleted || param.id === this.state.param) ?
                            <div className={"gift-param" + (param.id === this.state.param ? " selected" : "")}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        param: param.id
                                    })
                                }}>{param.name}</div> : ""))}
                    </div>
                    <div className="div-space">&nbsp;</div>
                    {!this.props.selectedItem?.superPrize ? (
                        (this.props.user.currentBoutState !== CurrentBoutOutcome.notBout)
                        ? <div>
                            <div className={"gift-label"}>Количество</div>
                            <div className={"gift-card-plus-minus"}>
                                {!this.props.reservationCompleted ? <div className={"gift-remove"} onClick={() => {
                                    if (this.state.count <= 1) {
                                        return;
                                    }

                                    this.setState({
                                        ...this.state,
                                        count: this.state.count - 1
                                    });
                                }}>
                                    <i className="minus icon" />
                                </div> : ""}
                                <div className={"gift-count"}>
                                    {this.state.count}
                                </div>
                                {!this.props.reservationCompleted ?
                                    <div className={"gift-plus"} onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            count: this.state.count + 1
                                        });
                                    }}>
                                        <i className="plus icon" />
                                    </div> : ""}
                            </div>
                         </div>
                        : "" 
                        ) 
                        : ("")}



                    {this.props.reservationCompleted ?
                        <div className={"reservationComplited"}>
                            {!this.props.selectedItem?.superPrize ? "Подарок " : "Супер-приз"} зарезервирован для вас
                        </div> : ""}

                    {this.props.errorInProcess && !this.props.selectedItem?.superPrize ?
                        <div id="gift-card-notification-text">{this.props.errorInProcess}
                            <div id="gift-card-notification-icon"><img alt="" src={notification_icon} /></div>
                        </div>
                        : ""
                    }

                    {(this.props.selectedItem?.superPrize && this.props.winner?.isWinner) ?
                        <div>
                            {this.generateOrderSuperButton(this)}
                        </div>
                        :
                        <div>
                            {(this.props.user.currentBoutState !== CurrentBoutOutcome.notBout)
                                ?this.generateOrderButton(this)
                                :null}
                            {this.generateOrderDeniedReasons(this)}
                            {/*{!(this.props.selectedItem?.inWishList) ?*/}
                            {/*    (<div className='gift-button'>*/}
                            {/*        <Button className={"gift-button"} color={"green"} fluid onClick={() =>*/}
                            {/*            this.addToWishList()*/}
                            {/*        }>Добавить в список желаний</Button>*/}
                            {/*    </div>) :*/}
                            {/*    (<div className='gift-button'>*/}
                            {/*        <Button className={"gift-button"} color={"green"} fluid onClick={() =>*/}
                            {/*            this.deleteFromWishList()*/}
                            {/*        }>Удалить из списка желаний</Button>*/}
                            {/*    </div>)}*/}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let giftStore: GiftStore = state.gift;
    let authStore: AuthStore = state.authentication
    return {
        counselor: authStore.counselor,
        selectedItem: giftStore.selectedItem,
        errorInProcess: giftStore.errorInProcess,
        operationInProcess: giftStore.operationInProcess,
        reservationCompleted: giftStore.reservationCompleted,
        winner: authStore?.user?.winner,
        user: authStore.user ?? {}
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(giftActions, dispatch),
    ...bindActionCreators({ refreshUserInfo }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCard);