import axios from "axios";
import {CurrentConfig} from './config';
import {ProcessOperationDto, TaskListFilter,TaskListFilterComplite,OfferTask} from "../store/taskType";

const taskApi = {
    list: (filter: TaskListFilter, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/list`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    beginTask: (id: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/begin?id=${id}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    cancelTask: (model: ProcessOperationDto, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/cancel`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    onApproving: (model: ProcessOperationDto, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/onApproving`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    done: (model: ProcessOperationDto, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/done`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    notDone: (model: ProcessOperationDto, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/notDone`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    // загрузка файла
    uploadPhoto: (data: any, fileType: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/UploadFileSave?f=${fileType}`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    onAsk: (model: ProcessOperationDto, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/onAsk`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    getTaskCategory: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/getTaskCategory`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    listComplited: (filter: TaskListFilterComplite, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/listcomplited`, filter).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    setOfferTask: (model: OfferTask, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/task/setOfferTask`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }
}

export default taskApi;