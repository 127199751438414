import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Modal, Dropdown, TextArea, Input, Label, ModalActions, Form, Icon, Segment, Dimmer, Loader, Image, SemanticICONS, Header, ModalProps } from 'semantic-ui-react';
import './QRScanner.css';
import QrScan from 'react-qr-reader'



export type QRScannerState = {
    helpModalOpened: boolean,
}

type QRScannerProp = {
    trigger?: React.ReactNode,
    open: boolean,
    onClose?: (event?: React.MouseEvent<HTMLElement>, data?: ModalProps) => void,
    onOpen?: (event?: React.MouseEvent<HTMLElement>, data?: ModalProps) => void,
    error?:string

    onScan: (data: string | null) => void,
    onError: (err: any) => void,
    onLoad?: (() => void) | undefined,
    onImageLoad?: ((event: React.SyntheticEvent<HTMLImageElement>) => void) | undefined,
    delay?: number | false | undefined,
    facingMode?: 'user' | 'environment' | undefined,
    legacyMode?: boolean | undefined,
    resolution?: number | undefined,
    showViewFinder?: boolean | undefined,
}

export class QRScanner extends React.Component<QRScannerProp, QRScannerState> {
    delayedVerification: any;


    constructor(props: QRScannerProp) {
        super(props);
        this.state = {
            helpModalOpened: false,
        }

    }


    render() {
        return (
            <Modal
                className="qr-scanner-component"
                basic
                onClose={(event, data) => {
                    this.props.onClose?.(event, data);
                }}
                onOpen={(event, data) => {
                    this.props.onOpen?.(event, data);
                }}
                open={this.props.open}
                size='large'
                trigger={this.props.trigger}
            >
                <Modal.Content>
                    <div className="scanner">
                        <div className="help-icon-container">
                            <Icon size="big" name='question circle outline' onClick={() => {
                                this.setState({ helpModalOpened: true });
                            }} />
                            <Icon size="big" name='close' onClick={() => {
                                this.props.onClose?.();
                            }} />
                        </div>
                        <QrScan
                            className="qr-scanner-window"
                            onScan={this.props.onScan}
                            onError={this.props.onError}
                            onLoad={this.props.onLoad}
                            onImageLoad={this.props.onImageLoad}
                            delay={this.props.delay}
                            facingMode={this.props.facingMode}
                            legacyMode={this.props.legacyMode}
                            resolution={this.props.resolution}
                            showViewFinder={this.props.showViewFinder}
                            style={{ height: "240px", width: "240px" }}
                        />
                        <div className="scanner-err">{ this.props.error ?? "" }</div>
                    </div>
                </Modal.Content>
                <Modal
                    open={this.state.helpModalOpened}
                    dimmer={"blurring"}
                    closeIcon
                    size="small"
                    onClose={() => this.setState({helpModalOpened:false}) }
                >
                    <Modal.Content>
                        <div>
                            <div className={"modal-subject-text help-dialog"}>
                                При возникновении проблем на устройстве iPhone выполните следующие шаги:
                            </div>
                            <div className={"modal-subject-text help-dialog"}>
                                1.Настройки/Safari/Вкладка "настройки для веб-сайтов"/Камера
                            </div>
                            <div className={"modal-subject-text help-dialog"}>
                                2.Снять запрет, поставив галочку на "Разрешить"
                            </div>
                        </div>
                    </Modal.Content>
                </Modal>
            </Modal>
        );
    }
}