export const RATING_LIST_SUCCESS = "RATING_LIST_SUCCESS";
export const RATING_LIST_FAILED = "RATING_LIST_FAILED";
export const RATING_LIST_PROCESS = "RATING_LIST_PROCESS";

export const RATING_SUCCESS = "RATING_SUCCESS";
export const RATING_FAILED = "RATING_FAILED";
export const RATING_PROCESS = "RATING_PROCESS";

export const RATING_PARTY_LIST_SUCCESS = "RATING_PARTY_LIST_SUCCESS";
export const RATING_PARTY_LIST_FAILED = "RATING_PARTY_LIST_FAILED";
export const RATING_PARTY_LIST_PROCESS = "RATING_PARTY_LIST_PROCESS";

export const RATING_USER_SELECT = "RATING_USER_SELECT"

