export const FEEDBACK_LIST_SUCCESS = "FEEDBACK_LIST_SUCCESS";
export const FEEDBACK_LIST_FAILED = "FEEDBACK_LIST_FAILED";
export const FEEDBACK_LIST_PROCESS = "FEEDBACK_LIST_PROCESS";

export const FEEDBACK_TITLES_LIST_SUCCESS = "FEEDBACK_TITLES_LIST_SUCCESS";
export const FEEDBACK_TITLES_LIST_FAILED = "FEEDBACK_TITLES_LIST_FAILED";
export const FEEDBACK_TITLES_LIST_PROCESS = "FEEDBACK_TITLES_LIST_PROCESS";

export const FEEDBACK_SEND_SUCCESS = "FEEDBACK_SEND_SUCCESS";
export const FEEDBACK_SEND_FAILED = "FEEDBACK_SEND_FAILED";
export const FEEDBACK_SEND_PROCESS = "FEEDBACK_SEND_PROCESS";

export const FEEDBACK_SET_REDIRECT_FROM_SUPPORT = "FEEDBACK_SET_REDIRECT_FROM_SUPPORT";
export const FEEDBACK_CLEAR_REDIRECT_FROM_SUPPORT = "FEEDBACK_CLEAR_REDIRECT_FROM_SUPPORT";
//export const FEEDBACK_ADD_SUPPORT_TO_TITLES = "FEEDBACK_ADD_SUPPORT_TO_TITLES";

export const FEEDBACK_SELECT = "FEEDBACK_SELECT";

export const SURVEY_TITLES_LIST_SUCCESS = "SURVEY_TITLES_LIST_SUCCESS";
export const SURVEYQR_TITLES_LIST_SUCCESS = "SURVEYQR_TITLES_LIST_SUCCESS";
export const SURVEY_TITLES_LIST_FAILED = "SURVEY_TITLES_LIST_FAILED";
export const SURVEY_TITLES_LIST_PROCESS = "SURVEY_TITLES_LIST_PROCESS";

export const SURVEY_SUCCESS = "SURVEY_SUCCESS";
export const SURVEY_FAILED = "SURVEY_FAILED";
export const SURVEY_PROCESS = "SURVEY_PROCESS";

export const SURVEY_SET_SUCCESS = "SURVEY_SUCCESS";
export const SURVEY_SET_FAILED = "SURVEY_FAILED";
export const SURVEY_SET_PROCESS = "SURVEY_PROCESS";

export const SURVEYQR_SET_SUCCESS = "SURVEYQR_SUCCESS";
export const SURVEYQR_SET_FAILED = "SURVEYQR_FAILED";
export const SURVEYQR_SET_PROCESS = "SURVEYQR_PROCESS";

export const SURVEY_GET_SUCCESS = "SURVEY_GET_SUCCESS";
export const SURVEY_GET_FAILED = "SURVEY_GET_FAILED";
export const SURVEY_GET_PROCESS = "SURVEY_GET_PROCESS";

export const SURVAY_SELECT = "SURVAY_SELECT";
export const SURVAY_REDIRECT_FROM_MAIN_TRUE = "SURVAY_REDIRECT_FROM_MAIN_TRUE";
export const SURVAY_REDIRECT_FROM_MAIN_FALSE = "SURVAY_REDIRECT_FROM_MAIN_FALSE";
