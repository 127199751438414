import {
    APPROVING_CHILD_FAILED,
    APPROVING_CHILD_PROCESS,
    APPROVING_CHILD_SUCCESS,
    APPROVING_LIST_FAILED,
    APPROVING_LIST_PROCESS,
    APPROVING_LIST_SUCCESS,
    CHILD_SELECT,
    REGISTER_CHILD_FAILED,
    REGISTER_CHILD_PROCESS,
    REGISTER_CHILD_SUCCESS
} from "./types";
import {ApprovingModel, Camper, ListFilter} from "../approvingType";
import {clearReduxStateInternal} from '../authentication/actions'
import approvingApi from "../../api/approving";
import { RegisterChildInfo } from "../baseType";

// получение списка детей
export const listChildren = (filter: ListFilter) => (dispatch: any) => {
    approvingApi.list(filter, (status: number, data: Camper[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: APPROVING_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: APPROVING_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка детей'
            });
        }
    });

    return dispatch({
        type: APPROVING_LIST_PROCESS
    });
}

// подтверждение реёбнка
export const approveChild = (model: ApprovingModel) => (dispatch: any) => {
    approvingApi.approving(model, (status: number, data: number | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: APPROVING_CHILD_SUCCESS,
                index: model.index,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: APPROVING_CHILD_FAILED,
                index: model.index,
                errorMessage: data || 'Ошибка подтверждения ребёнка'
            });
        }
    });

    return dispatch({
        type: APPROVING_CHILD_PROCESS,
        index: model.index,
    });
}

/// выбрать отдыхающего
export const selectChild = (model: Camper) => (dispatch: any) => {
    return dispatch({
        type: CHILD_SELECT,
        data: model
    });
}

export const registerChild = (model: RegisterChildInfo) => (dispatch: any) => {
    approvingApi.registerChild(model, (status: number, data: number | string | undefined) => {
        if (status === 200) {
            dispatch(listChildren( {skip: 0, take:100} as ListFilter));
            dispatch({
                type: REGISTER_CHILD_SUCCESS,
                data
            });

        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: REGISTER_CHILD_FAILED,
                //errorMessage: data || 'Ошибка регистрации ребёнка',
                errorMessage: data || 'Ошибка регистрации ребёнка'
            });
        }
    });

    return dispatch({
        type: REGISTER_CHILD_PROCESS,
    });
}