import {
    CLEAR_REDUX_STATE,
    AUTH_USER_PROCESS,
    AUTH_USER_SUCCESS,
    AUTH_USER_FAIL,
    RESTORE_USER_EMAIL,
    RESTORE_USER_SMS,
    RESTORE_ERROR,
    REGISTER_USER,
    REGISTER_ERROR,
    USER_CHANGE_PASSWORD,
    USER_CHANGE_PASSWORD_ERROR,
    PHOTO_UPLOADED,
    PHOTO_UPLOADED_ERROR,
    PHOTO_UPLOADED_PROCESS,
    REGISTER_PROCESS,
    SAVE_ERROR,
    SAVE_USER,
    SAVE_PROCESS,
    USER_CHANGE_PASSWORD_PROCESS, CLEAR_CARD_STATE, CLEAR_CARD_STATE_CLEAR, RESET_WELCOME_MODE, LOGIN_USER,
    UPDATE_THEME_SUCCESS,
    UPDATE_THEME_ERROR,
    UPDATE_THEME_PROCESS,

    AUTHORIZE_CHAT_SUCCES,
    AUTHORIZE_CHAT_ERROR,
    AUTHORIZE_CHAT_PROCESS,
    APPROVE_RULE,
    APPROVE_RULE_ERROR,
    APPROVE_RULE_PROCESS


} from "./types";
import { AuthInfo, ChangeInfo, RegisterInfo, User, Theme, AuthChat, TokenInfo } from "../baseType";
import AuthApi from "../../api/auth";
import fileApi from "../../api/file";
import { notificationCountInternal, notificationOfferTaskCountInternal, notificationOfferTaskChangeCountInternal,notificationgSurveyCount } from "../notification/actions";
import { disconnect } from "../chat/actions";
import {getPublicKey, regDevice} from "../webpush/actions";
import {WebPushDevice} from "../webPushType";
import WebPushApi from "../../api/webpush";

export const restoreByEmail = (auth: AuthInfo) => (dispatch: any) => {
    if (!auth.login) {
        return dispatch({
            type: RESTORE_ERROR,
            auth: {
                authenticated: false,
                errorMessage: 'Не указан адрес электронной почты',
                currentUser: {}
            }
        });
    }

    AuthApi.resetPasswordByEmail(auth.login, (status: number, data: any | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: RESTORE_USER_EMAIL,
                auth: {
                    message: 'Восстановление пароля пользователя запрошено'
                }
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: RESTORE_ERROR,
                auth: {
                    errorMessage: data || 'Ошибка восстановления пароля'
                }
            });
        }
    });
}

export const restoreBySms = (auth: AuthInfo) => (dispatch: any) => {
    if (!auth.login) {
        return dispatch({
            type: RESTORE_ERROR,
            auth: {
                errorMessage: 'Не указан адрес электронной почты'
            }
        });
    }

    AuthApi.resetPasswordBySms(auth.login, (status: number, data: any | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: RESTORE_USER_SMS,
                auth: {
                    message: 'Восстановление пароля пользователя запрошено'
                }
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: RESTORE_ERROR,
                auth: {
                    errorMessage: data || 'Ошибка восстановления пароля'
                }
            });
        }
    });
}
//В дальнейшем метод должен рефрешить токен
export const refreshUserInfo = () => (dispatch: any) => {
    AuthApi.refreshUserInfo((status: number, data: any | string | undefined) => {
        if (status === 200) {
            localStorage.setItem("user", JSON.stringify(data));
            dispatch({
                type: AUTH_USER_SUCCESS,
                auth: {
                    authenticated: true,
                    currentUser: data,
                    errorMessage: ''
                }
            });

            notificationCountInternal(dispatch);
            notificationOfferTaskCountInternal(dispatch);
            notificationOfferTaskChangeCountInternal(dispatch);
            notificationgSurveyCount(dispatch);
        } else {
            dispatch(clearReduxStateInternal());
        }
    });
}

export const changePassword = (auth: ChangeInfo) => (dispatch: any) => {
    if (!(auth.id)) {
        return dispatch({
            type: USER_CHANGE_PASSWORD_ERROR,
            auth: {
                errorMessage: 'Не указан идентификатор пользователя'
            }
        });
    }
    if (!auth.pass) {
        return dispatch({
            type: USER_CHANGE_PASSWORD_ERROR,
            auth: {
                errorMessage: 'Не указан новый пароль'
            }
        });
    }

    AuthApi.changePassword(auth, (status: number, data: any | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: USER_CHANGE_PASSWORD
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: USER_CHANGE_PASSWORD_ERROR,
                auth: {
                    errorMessage: data || 'Не указан новый пароль'
                }
            });
        }
    });


    return dispatch({
        type: USER_CHANGE_PASSWORD_PROCESS
    });
}

export const registerUser = (info: RegisterInfo) => (dispatch: any) => {
    if (!info.email) {
        return dispatch({
            type: REGISTER_ERROR,
            auth: {
                authenticated: false,
                errorMessage: 'Не указан адрес электронной почты',
                currentUser: {}
            }
        });
    }

    AuthApi.registerUser(info, (status: number, data: string | undefined) => {
        if (status === 200) {
            dispatch({
                type: REGISTER_USER,
                auth: {}
            });
        } else {
            return dispatch({
                type: REGISTER_ERROR,
                auth: {
                    authenticated: false,
                    errorMessage: data || 'Ошибка регистрации пользователя',
                    currentUser: {}
                }
            });
        }
    });

    return dispatch({
        type: REGISTER_PROCESS
    });
}

export const updateUser = (info: RegisterInfo) => (dispatch: any) => {
    if (!info.nickName) {
        return dispatch({
            type: SAVE_ERROR,
            auth: {
                errorMessage: 'Не указано, как к вам обращаться'
            }
        });
    }

    AuthApi.updateUser(info, (status: number, data: any | string | undefined) => {
        if (status === 200) {
            //localStorage.setItem("user", JSON.stringify(data));
            dispatch({
                type: SAVE_USER,
                auth: {
                    authenticated: true,
                    currentUser: data,
                    errorMessage: ''
                }
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: SAVE_ERROR,
                auth: {
                    errorMessage: data || 'Ошибка сохранения пользователя'
                }
            });
        }
    });

    return dispatch({
        type: SAVE_PROCESS
    });
}

export const uploadUserPhoto = (data: any) => (dispatch: any) => {
    fileApi.uploadUserPhoto(data, (status: number, data: string | undefined) => {
        if (status === 200) {
            dispatch({
                type: PHOTO_UPLOADED,
                auth: {
                    photo: data
                }
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: PHOTO_UPLOADED_ERROR,
                auth: {
                    errorMessage: data || 'Ошибка загрузки фотографии'
                }
            });
        }
    });

    dispatch({
        type: PHOTO_UPLOADED_PROCESS,
        auth: {
            photo: undefined
        }
    });
}

export const authorizeUserChat = (info: AuthChat) => (dispatch: any) => {
    if (!info.username || !info.password) {
        return dispatch({
            type: AUTH_USER_FAIL,
            auth: {
                authenticated: false,
                errorMessage: 'Не указан пользователь или пароль',
                currentUser: {}
            }
        });
    }
    AuthApi.authChat(info, (status: number, data: TokenInfo | string | undefined) => {
        if (status === 200) {
            localStorage.setItem("token", JSON.stringify(data));
            dispatch({
                type: AUTHORIZE_CHAT_SUCCES,
                data,
                authenticated: true,
                currentUser: data,
                errorMessage: ''
            });
            authorizeUser(dispatch);
            notificationCountInternal(dispatch);
            notificationOfferTaskCountInternal(dispatch);
            notificationOfferTaskChangeCountInternal(dispatch);
        } else if (status === 403) {
            dispatch({
                type: AUTHORIZE_CHAT_ERROR,
                auth: {
                    authenticated: false,
                    errorMessage: 'В связи с достижением совершеннолетия и в соответствии с Правилами доступ к приложению закрыт',
                    currentUser: {}
                }
            });
        } else if (status === 400) {
            dispatch({
                type: AUTHORIZE_CHAT_ERROR,
                auth: {
                    authenticated: false,
                    errorMessage: 'Пользователь или пароль не верен',
                    currentUser: {}
                }
            });
        } else {
            dispatch({
                type: AUTHORIZE_CHAT_ERROR,
                auth: {
                    authenticated: false,
                    errorMessage: 'Не удается получить доступ к сайту. Попробуйте ещё раз.',
                    currentUser: {}
                }
            });
        }
    });

    return dispatch({
        type: AUTHORIZE_CHAT_PROCESS,
        auth: {
            authenticated: false
        }
    });
};


/*
export const authorizeUser = (info: AuthInfo) => (dispatch: any) => {
    if (!info.login || !info.pass) {
        return dispatch({
            type: AUTH_USER_FAIL,
            auth: {
                authenticated: false,
                errorMessage: 'Не указан пользователь или пароль',
                currentUser: {}
            }
        });
    }

    AuthApi.auth(info, (status: number, data: User | string | undefined) => {
        if (status === 200) {
            let tokenInfo : TokenInfo = JSON.parse(localStorage.getItem("user") || '{}') || {}; 
            let newdata = data as User
            newdata.token = tokenInfo.access_token;
            localStorage.setItem("user", JSON.stringify(newdata));
            
            dispatch({
                type: AUTH_USER_SUCCESS,
                auth: {
                    authenticated: true,
                    currentUser: data,
                    errorMessage: ''
                }
            });

            notificationCountInternal(dispatch);
        } else if(status === 403){
            dispatch({
                type: AUTH_USER_FAIL,
                auth: {
                    authenticated: false,
                    errorMessage: 'В связи с достижением совершеннолетия и в соответствии с Правилами доступ к приложению закрыт',
                    currentUser: {}
                }
            });
        }  else {
            dispatch({
                type: AUTH_USER_FAIL,
                auth: {
                    authenticated: false,
                    errorMessage: 'Пользователь или пароль не верен',
                    currentUser: {}
                }
            });
        }
    });

    return dispatch({
        type: AUTH_USER_PROCESS,
        auth: {
            authenticated: false
        }
    });
};
*/
export const authorizeUser = (dispatch: any) => {
    console.log("authorizeUser");
    AuthApi.auth((status: number, data: User | string | undefined) => {
        if (status === 200) {
            //let tokenInfo: TokenInfo = JSON.parse(localStorage.getItem("token") || '{}') || {};
            //let newdata = data as User
            //newdata.token = tokenInfo.access_token;
            localStorage.setItem("user", JSON.stringify(data));

            dispatch({
                type: AUTH_USER_SUCCESS,
                auth: {
                    authenticated: true,
                    currentUser: data,
                    errorMessage: ''
                }
            });

            notificationCountInternal(dispatch);
            notificationOfferTaskCountInternal(dispatch);
            notificationOfferTaskChangeCountInternal(dispatch);
            getPublicKey(dispatch);
            console.log(data);
            if ((data as User).receiveNotifications){
                let webPushDevice = {
                    UserUID: (data as User).uid,
                    PushEndpoint: localStorage.getItem("push_endpoint"),
                    PushP256DH: localStorage.getItem("push_p256dh"),
                    PushAuth: localStorage.getItem("push_auth"),
                    IsDeleted: false,
                } as WebPushDevice;
                WebPushApi.regDevice(webPushDevice, (status: number, data: WebPushDevice | string | undefined) => {
                    console.log("reg Done!");
                    console.log(status);
                });
            }

            //regDevice(webPushDevice);
        } else if (status === 403) {
            dispatch({
                type: AUTH_USER_FAIL,
                auth: {
                    authenticated: false,
                    errorMessage: 'В связи с достижением совершеннолетия и в соответствии с Правилами доступ к приложению закрыт',
                    currentUser: {}
                }
            });
        } else if (status === 400) {
            dispatch({
                type: AUTH_USER_FAIL,
                auth: {
                    authenticated: false,
                    errorMessage: 'Пользователь или пароль не верен',
                    currentUser: {}
                }
            });
        } else {
            dispatch({
                type: AUTH_USER_FAIL,
                auth: {
                    authenticated: false,
                    errorMessage: 'Не удается получить доступ к сайту. Попробуйте ещё раз.',
                    currentUser: {}
                }
            });
        }
    });

    return dispatch({
        type: AUTH_USER_PROCESS,
        auth: {
            authenticated: false
        }
    });
};
export const updateTheme = (data: Theme) => (dispatch: any) => {
    AuthApi.updateTheme(data, (status: number, data: any) => {
        if (status === 200) {
            dispatch({
                type: UPDATE_THEME_SUCCESS,
                auth: data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: UPDATE_THEME_ERROR,
                auth: {
                    errorMessage: data || 'Ошибка смены темы'
                }
            });
        }
    });

    dispatch({
        type: UPDATE_THEME_PROCESS,
        auth: undefined

    });
}

export const resetWelcomeMode = () => (dispatch: any) => {
    return dispatch({ type: RESET_WELCOME_MODE });
}

export const loginUser = () => (dispatch: any) => {
    return dispatch({ type: LOGIN_USER });
}


export const clearReduxStateInternal = () => {
    localStorage.setItem("user", "{}");
    localStorage.setItem("token", "{}");
    return {
        type: CLEAR_REDUX_STATE,
        authenticated: false,
        errorMessage: '',
        currentUser: {}
    };
};

export const clearListState = () => (dispatch: any) => {
    return dispatch({ type: CLEAR_CARD_STATE });
}

export const clearListStateClear = () => (dispatch: any) => {
    return dispatch({ type: CLEAR_CARD_STATE_CLEAR });
}

export const clearReduxState = () => (dispatch: any) => {
    return dispatch(clearReduxStateInternal());
};

export const userApproveRule = () => (dispatch: any) => {
    AuthApi.userApproveRule((status: number, data: any | string | undefined) => {
        if (status === 200) {
            console.log("3")
            dispatch({
                type: APPROVE_RULE,
                auth: {
                    authenticated: true,
                    currentUser: data,
                    errorMessage: ''
                }
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: APPROVE_RULE_ERROR,
                auth: {
                    errorMessage: data || 'Ошибка принятия правил.'
                }
            });
        }
    });
    return dispatch({
        type: APPROVE_RULE_PROCESS
    });
}
