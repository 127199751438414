import axios from "axios";
import {CurrentConfig} from './config';
import {WebPushDevice} from "../store/webPushType";

const WebPushApi = {

    regDevice: (model: WebPushDevice, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/webpush/create`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    delDevice: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/webpush/deleteConfirmedUser`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },

    clearUserDevice: (userUID: string, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/webpush/deleteConfirmed`, userUID).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },    

    getPublicKey: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.get(`${config.Api}/webpush/create`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
};

export default WebPushApi;