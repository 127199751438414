import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import './Offer.css'
import { Button, Modal, Dropdown, Input, ModalActions, Icon } from 'semantic-ui-react'
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthStore, User, CurrentBoutOutcome } from "../../store/baseType";
import _ from "lodash";
import * as offerActions from "./../../store/offer/actions"
import { Offer, OfferStore } from '../../store/offerType';
import { OfferRow } from "./OfferRow"
import { MarkableTextArea } from '../../components/UI/MarkableTextArea/MarkableTextArea';
import { TaskCategory } from '../../store/taskType';
import task from '../../api/task';
import { SelectBar } from '../../components/UI/SelectBar/SelectBar';
import FileViewer from '../../components/UI/FileViwer/FileViewer';

const AcceptedImageExtensions = ["BMP", "JPEG", "JPG", "GIF", "ICO", "PNG", "TIFF"];

const TabStates = {
    myOffers: "Задание на изменение",
    childOffers: "Предложения детей",
}

type OfferListState = {
    currentTab: string,
    offerModalOpened: boolean,

    offerTaskTitle?: string,
    offerTaskCategory?: number,
    offerTaskDescription?: string,
    offerTaskPrice?: string,
    offerTaskCountOnBout?: string,
    offerTaskDuration?: string,

    offerTaskTitleEmpty: boolean,
    offerTaskCategoryEmpty: boolean,
    offerTaskDescriptionEmpty: boolean,
    offerTaskPriceEmpty: boolean,
    offerTaskCountOnBoutEmpty: boolean
    offerTaskDurationEmpty: boolean,
    offerTaskImgEmpty: boolean

    sendButtonDisabled: boolean,

    titleDeniedCount?: number,
    descDeniedCount?: number,

    taskCategories?: TaskCategory[],
    durationValues: any[],

    text?: string,
    offerBadImgExt: boolean,
    
    selectedFile: any | null,
    errorUpload: string,
}

type OfferListProp = {
    user: User,
    list: Offer[],
    listOffer: Function,
    newOffer: Function,
    setOfferState: Function,
    setReadOffer: Function,
    inProcess: boolean,
    refreshUserInfo: Function,
    notificationOfferTaskCount?: number,
    notificationOfferChangeTaskCount?: number,
    
}

class OfferList extends React.Component<OfferListProp, OfferListState> {
    toUpload: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            currentTab: this.props.user.admin ? TabStates.myOffers : TabStates.childOffers,
            offerModalOpened: false,
            
            offerTaskPriceEmpty: false,
            offerTaskTitleEmpty: false,
            offerTaskCategoryEmpty: false,
            offerTaskCountOnBoutEmpty: false,
            offerTaskDescriptionEmpty: false,
            offerTaskDurationEmpty: false,
            offerTaskImgEmpty: false,
            offerBadImgExt: false,
            sendButtonDisabled: true,

            offerTaskPrice:'',
            offerTaskCountOnBout:'',
            errorUpload: '',
            selectedFile: null,
            durationValues: [
                { key: 1, text: "1 день", value: "1440" },
                { key: 2, text: "3 дня", value: "4320" },
                { key: 3, text: "7 дней", value: "10080" }
            ],
        }
    }

    updateDisableButton() {
        this.setState({ sendButtonDisabled: (this.state.titleDeniedCount ?? 1) + (this.state.descDeniedCount ?? 1) > 0 })
    }

    componentDidMount() {
        this.props.refreshUserInfo();
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.listOffer();

        task.getTaskCategory((status: number, data: TaskCategory[]) => {
            if (status === 200) {
                this.setState({ taskCategories: data });
            }
        });
    }
 
    renderSelector() {
        let elems = [TabStates.myOffers, TabStates.childOffers];
        return this.props.user.admin ? (
            <div className={"offer-selectors-block"}>
                <SelectBar elems={elems} selectedElem={this.state.currentTab}
                    elemClick={(elem: string) => this.setState({currentTab:elem}) }
                    notificationOfferTaskCount={this.props.notificationOfferTaskCount}
                    notificationOfferChangeTaskCount={this.props.notificationOfferChangeTaskCount}
                />

            </div>
        ) : "";
    }
    onButtonClick = () => {
        if (this.toUpload && this.toUpload.click) {
            this.toUpload.click();
        }
    };
    fileChangedHandler = (event: any) => {
        const file = event.target.files[0];
        const maxSizeImage = 10 * 1024 * 1024; 
        let error = '';
        if (file) {
            if (file.type.startsWith('image/') && file.size >= maxSizeImage) {
                error = "Размер файла превысил ограничение (10 МБ) для файлов изображений.";
                this.setState({selectedFile: null, errorUpload: error });
            } else if(file.type.startsWith('image/')){
                this.setState({ ...this.state, 
                    selectedFile: file, 
                    errorUpload: error,
                    offerTaskImgEmpty: false
                });   
            } else{
                error = "Файл не соответствует ожидаемому типу или размеру";   
                this.setState({ selectedFile: null, errorUpload: error });
            }
        }
    }

    renderOfferList() {
        let myOffers: Offer[] = [];
        let childOffers: Offer[] = [];

        this.props.list.forEach((offer: Offer) => {
            if (offer.sourcePersonType === -1) childOffers.push(offer);
            else if (offer.sourcePersonType === 2) myOffers.push(offer);
        });

        switch (this.state.currentTab) {
            case TabStates.myOffers:
                {
                    return myOffers.map((elem: Offer) => <OfferRow refreshUserInfo={this.props.refreshUserInfo} setReadOffer={this.props.setReadOffer} setOfferState={this.props.setOfferState} adminMode={this.props.user.admin} offer={elem} />);
                }
            case TabStates.childOffers: {
                return childOffers.map((elem: Offer) => <OfferRow refreshUserInfo={this.props.refreshUserInfo} setReadOffer={this.props.setReadOffer} setOfferState={this.props.setOfferState} adminMode={this.props.user.admin} offer={elem} />);
            }
        }
    }

    renderAddOffer() {
        return (<div>
            {
                !this.props.user.admin && !this.props.user.counselor && this.props.user.currentBoutState === CurrentBoutOutcome.OnBout
                    ?
                    <div>
                        <Modal
                            size="large"
                            open={this.state.offerModalOpened}
                            onClose={() => { this.setState({ ...this.state, offerModalOpened: false }) }}
                            onOpen={() => { this.setState({ ...this.state, offerModalOpened: true }) }}
                            trigger={<Button
                                style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive}
                            > Предложить задание</Button >}>

                            <Modal.Header id="contact-header" className="modal-backgroud">
                                <div onClick={() => { this.setState({ ...this.state, offerModalOpened: false }) }} className="back-btn"><Icon name="arrow left" />назад</div>
                            </Modal.Header>
                            <Modal.Content image scrolling className="modal-backgroud">
                                <Modal.Description>
                                    <div className="new-offer">
                                        <div className="offer-row-title">
                                            <label>Название задания</label>
                                            {this.state.offerTaskTitleEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <MarkableTextArea
                                            className="new-offer-input"
                                            text={this.state.offerTaskTitle ?? ""}
                                            type="area"
                                            verifyDelay={500}
                                            onChange={(value: any) => {
                                                this.setState({
                                                    sendButtonDisabled: true,
                                                    offerTaskTitle: (value || '').toString(),
                                                    offerTaskTitleEmpty: false
                                                })
                                            }}
                                            onRangesUpdated={(ranges: []) => {
                                                this.setState({ titleDeniedCount: ranges.length });
                                                this.updateDisableButton();
                                            }}
                                        />

                                        <div className="offer-row-title">
                                            <label>Тема задания</label>
                                            {this.state.offerTaskCategoryEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <Dropdown selection options={
                                            //[{ key: 1, text: "Хозяйственные", value: 1 },
                                            //{ key: 2, text: "Творческие", value: 2 },
                                            //{ key: 3, text: "Режимные", value: 3 },
                                            //{ key: 4, text: "Организаторские", value: 4 }]
                                            this.state.taskCategories?.map((tc: TaskCategory) => ({ key: tc.id, text: tc.name, value: tc.id }))
                                        }
                                            onChange={(event, data) => this.setState({
                                                ...this.state,
                                                offerTaskCategory: data.value as number,
                                                offerTaskCategoryEmpty: false
                                            })} />

                                        <div className="offer-row-title">
                                            <label>Описание задания</label>
                                            {this.state.offerTaskDescriptionEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <MarkableTextArea
                                            className="new-offer-input"
                                            text={this.state.offerTaskDescription ?? ""}
                                            type="area"
                                            verifyDelay={500}
                                            onChange={(value: any) => {
                                                this.setState({
                                                    sendButtonDisabled: true,
                                                    offerTaskDescription: (value || '').toString(),
                                                    offerTaskDescriptionEmpty: false
                                                })
                                            }}
                                            onRangesUpdated={(ranges: []) => {
                                                this.setState({ descDeniedCount: ranges.length });
                                                this.updateDisableButton();
                                            }}
                                        />

                                        <div className="offer-row-title">
                                            <label>Стоимость</label>
                                            {this.state.offerTaskPriceEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <Input
                                            value={this.state.offerTaskPrice}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                                ...this.state,
                                                offerTaskPrice: formatPriceAmount(data.value),
                                                offerTaskPriceEmpty: false
                                            })}
                                            />                                   
                                        <div className="offer-row-title">
                                            <label>Одновременное кол-во</label>
                                            {this.state.offerTaskCountOnBoutEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <Input 
                                            value={this.state.offerTaskCountOnBout}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                                ...this.state,
                                                offerTaskCountOnBout: formatPrice(data.value),
                                                offerTaskCountOnBoutEmpty: false
                                            })}/>

                                        <div className="offer-row-title">
                                            <label>Продолжительность</label>
                                            {this.state.offerTaskDurationEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                        </div>

                                        <Dropdown selection options={
                                            this.state.durationValues?.map((tc: any) => ({ key: tc.key, text: tc.text, value: tc.value }))
                                        }
                                            onChange={(event, data) => this.setState({
                                                ...this.state,
                                                offerTaskDuration: data.value as string,
                                                offerTaskDurationEmpty: false
                                                })
                                        }
                                        />

                                            <div onClick={this.onButtonClick}>
                                                <div style={{ left: '0%', marginTop: '20px' }} id="feedback-add-photo-button">
                                                    <div id="feedback-add-photo-button-text">
                                                        Добавить фото
                                                    </div>
                                                    <div id="add-photo-button-plus-border"></div>
                                                    <div id="add-photo-button-plus-horisontal-line"></div>
                                                    <div id="add-photo-button-plus-vertical-line"></div>
                                                </div>
                                                <input type="file" ref={((ref) => this.toUpload = ref)} onChange={this.fileChangedHandler}
                                                    style={{ display: "none" }} />
                                            </div>
                                           
                                            <br />
                                            {this.state.errorUpload !== '' 
                                                ?   <div className={"modal-error-text"}>
                                                        {this.state.errorUpload}
                                                    </div>
                                                : ""
                                                }
                                            </div>
                                            {this.state.offerTaskImgEmpty ?
                                                <div className={"modal-error-text"}>
                                                    Обязательное поле
                                                </div>
                                                : ""
                                            }
                                            {this.state.offerBadImgExt ?
                                                <div className={"modal-error-text"}>
                                                    Допускающиеся форматы: {AcceptedImageExtensions.join(", ")}
                                                </div>
                                                : ""}
                                         
                                            <br/>
                                            {
                                                this.state.selectedFile
                                                ? <FileViewer url = {URL.createObjectURL(this.state.selectedFile)}/>
                                                : ""
                                            }
                                        
                                </Modal.Description>
                            </Modal.Content>
                            <ModalActions className="modal-backgroud">
                                <div className="action-btn-part">
                                    <Button disabled={this.state.sendButtonDisabled} className="modal-action-btn"
                                        color="violet" onClick={this.checkAndSendOffer} > Отправить предложение</Button>
                                </div>
                            </ModalActions>
                        </Modal>
                    </div>
                    :
                    ""
            }
        </div>);
    }

    checkAndSendOffer = (event: any) => {

        let newEmptyState = {
            offerTaskTitleEmpty: false,
            offerTaskCategoryEmpty: false,
            offerTaskCountOnBoutEmpty: false,
            offerTaskDescriptionEmpty: false,
            offerTaskPriceEmpty: false,
            offerTaskImgEmpty: false,
            offerTaskDurationEmpty: false,
        };

        if (!this.state.offerTaskTitle) {
            newEmptyState.offerTaskTitleEmpty = true;
        }

        if (!this.state.offerTaskCategory) {
            newEmptyState.offerTaskCategoryEmpty = true;
        }

        if (!this.state.offerTaskCountOnBout) {
            newEmptyState.offerTaskCountOnBoutEmpty = true;
        }

        if (!this.state.offerTaskDescription) {
            newEmptyState.offerTaskDescriptionEmpty = true;
        }

        if (!this.state.offerTaskPrice) {
            newEmptyState.offerTaskPriceEmpty = true;
        }

        if (!this.state.selectedFile) {
            newEmptyState.offerTaskImgEmpty = true;
        }

        if (!this.state.offerTaskDuration) {
            newEmptyState.offerTaskDurationEmpty = true;
        }

        this.setState({
            offerTaskTitleEmpty: newEmptyState.offerTaskTitleEmpty,
            offerTaskCategoryEmpty: newEmptyState.offerTaskCategoryEmpty,
            offerTaskCountOnBoutEmpty: newEmptyState.offerTaskCountOnBoutEmpty,
            offerTaskDescriptionEmpty: newEmptyState.offerTaskDescriptionEmpty,
            offerTaskPriceEmpty: newEmptyState.offerTaskPriceEmpty,
            offerTaskImgEmpty: newEmptyState.offerTaskImgEmpty,
            offerTaskDurationEmpty: newEmptyState.offerTaskDurationEmpty,
        });

        if (!newEmptyState.offerTaskTitleEmpty
            && !newEmptyState.offerTaskCategoryEmpty
            && !newEmptyState.offerTaskCountOnBoutEmpty
            && !newEmptyState.offerTaskDescriptionEmpty
            && !newEmptyState.offerTaskPriceEmpty
            && !newEmptyState.offerTaskImgEmpty
            && !newEmptyState.offerTaskDurationEmpty) {

            const data = new FormData();
            if (this.state.errorUpload === '')   
            data.append('file', this.state.selectedFile);
            data.append('name', this.state.offerTaskTitle ?? "");
            data.append('taskCategoryId', (this.state.offerTaskCategory ?? "").toString());
            data.append('countOnBout', (Number(this.state.offerTaskCountOnBout) ?? "").toString());
            data.append('description', (this.state.offerTaskDescription ?? "").toString());
            data.append('price', (Number(this.state.offerTaskPrice) ?? "").toString());
            data.append('duration', (Number(this.state.offerTaskDuration) ?? "").toString());
            this.props.newOffer(data);
            this.setState({
                offerModalOpened: false,
                offerTaskTitleEmpty: false,
                offerTaskCategoryEmpty: false,
                offerTaskDescriptionEmpty: false,
                offerTaskPriceEmpty: false,
                offerTaskCountOnBoutEmpty: false,
                offerTaskImgEmpty: false,
                offerTaskDurationEmpty: false,
                offerTaskTitle: undefined,
                offerTaskCategory: undefined,
                offerTaskDescription: undefined,
                offerTaskPrice: undefined,
                offerTaskCountOnBout: undefined,
                offerTaskDuration: undefined,
                errorUpload: '',
                selectedFile: null


            });
        }
    }

    render() {
        return (<div style={{ display: "flex", flexDirection: "column", marginTop: "60px" }} className="offerListBlock">
            <div className={this.props.inProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                <div className="ui text loader">Загрузка...</div>
            </div>

            <div className="offer-list-header">
                {this.renderSelector()}
            </div>
            <div className="offer-task-btn">
                {this.renderAddOffer()}
            </div>
            {this.renderOfferList()}

        </div>);
    }
}

function formatPriceAmount(number: string) {
    var validNumber = "";
    if (/^\d+$/.test(number) && !number.startsWith('0') || number === '') {
        validNumber = number;
    }
    return validNumber;
}
function formatPrice(number: string) {
    var validNumber = "";
    if (/^\d+$/.test(number) && !number.startsWith('0') || number === ''){        
        validNumber = number;
    }
    if (parseInt(validNumber) > 99)
        validNumber = "99";
    return validNumber;
}

function mapStateToProps(state: any) {
    let authStore: AuthStore = state.authentication;
    let offerStore: OfferStore = state.offer;
    return {
        user: authStore.user,
        list: offerStore.offers,
        inProcess: offerStore.inProcess,
        notificationOfferTaskCount: state.notification.notificationOfferTaskCount,
        notificationOfferChangeTaskCount: state.notification.notificationOfferChangeTaskCount
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(offerActions, dispatch),
    ...bindActionCreators({ refreshUserInfo }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferList);
