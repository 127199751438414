import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Header, Icon, TextArea, Modal } from 'semantic-ui-react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ChatStory,ConversationMobileService,FilterMessages,MessageChatService,SendMessageFile} from "../../store/chatType";
import * as chatActions from "../../store/chat/actions"
import {CurrentConfig} from '../../api/config';
import _, { isEmpty } from "lodash";
import avatarError from './../../images/avatarError.png';
import cartimg from './../../images/cart.png';
import { format } from "date-fns";
import {AuthStore, User} from "../../store/baseType";
import fon_messager from './../../images/fon-messager.png';
import { FileItem } from '../../store/taskType';
import ImageModal  from '../../components/UI/FileViwer/ImageWrapper';
import FileViewer from '../../components/UI/FileViwer/FileViewer';
import Popup from '../../components/UI/Errors/ErrorNotification';

type MessageState = {
    isMessage: boolean
    text: string,
    files: FileItem[],
    chatDiv: any,
    isScrolls: boolean,
    inputKey: number,

    errorUpload: string,
    closeChatModal: boolean
}

type MessageCardProp = {
    //Пользователь
    user: User;

    selectConversation: ConversationMobileService,
    selectCurrentNessages: MessageChatService[],
    
    backToList: Function,
    closeConversation: Function,

    conversationClose: Function,
    getConversationMessage: Function,
    getConversationMessageMove: Function,
    connection: Function,
    disconnect: Function,
    sendMessage: Function,
    sendMessageReaded: Function,
    uploadFile: Function,
    sendMessageFile: Function,
    clearConversationMobileService: Function,
    currentCreateLinkMessage: string,
    messageInProcess: boolean,
    filterMessages: FilterMessages,

    messageError: string

    
}
class MessageCard extends React.Component<MessageCardProp, MessageState> {
    basePath: string = CurrentConfig.get().Api;
    upload: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            inputKey: 1,
            files:[],
            isMessage: false,  
            text:'',
            chatDiv: null,
            isScrolls: true,
            errorUpload: '',
            closeChatModal: false
        }
    }
    componentDidMount() {
        
        this.props.getConversationMessage({
            conversationId: this.props.selectConversation.id, filter: { skip: 0, take: this.props.selectConversation.unread > 20 ? this.props.selectConversation.unread : 20 }
        });
        this.props.connection();
        this.setState({
            ...this.state,
        })
        const chatDiv = document.querySelector('.chat'); 
        if (chatDiv) {
            chatDiv.addEventListener('scroll', this.handleChatScroll); 
            this.setState({ chatDiv });
        }
    }
    componentWillUnmount() {
        this.props.clearConversationMobileService({} as ConversationMobileService);
        const { chatDiv } = this.state;
        if (chatDiv) {
          chatDiv.removeEventListener('scroll', this.handleChatScroll);
        }    
    }
    handleChatScroll = () => {
        const { chatDiv } = this.state;
        if (!chatDiv) {
          return;
        }
        const scrollTop = chatDiv.scrollTop;
        const chatDivHeight = chatDiv.clientHeight;
        const chatDivScrollHeight = chatDiv.scrollHeight;
        const scrollThreshold = -(chatDivScrollHeight - chatDivHeight) + 300;
        
        if (scrollTop >= scrollThreshold && !this.state.isScrolls){
            this.setState({
                isScrolls: true
            })
            console.log("отошло");
        }
        if (scrollTop <= scrollThreshold && this.state.isScrolls) {
            this.setState({
                isScrolls: false,
            })
           
            this.props.getConversationMessageMove({conversationId: this.props.filterMessages.conversationId,filter:this.props.filterMessages.filter});
            
           
            console.log("Зашло");
        }
    };

    onLoadMsg = (item: MessageChatService) => {
        item.read = true;
        this.props.sendMessageReaded(item.id, (res: boolean) => {
            item.read = res;
        });
    }

    onButtonClick = () => {
        if (this.upload && this.upload.click) {
            this.upload.click();
        }
    };
    fileChangedHandler = (file: File ) => {
        const data = new FormData()
        const maxSizeImage = 10 * 1024 * 1024; 
        const maxSizeVideo = 100 * 1024 * 1024;
        let error = '';
        if (file) {
            if (file.type.startsWith('image/') && file.size >= maxSizeImage) {
                error = "Размер файла превысил ограничение (10 МБ) для файлов изображений.";
                this.setState({
                    ...this.state,
                    errorUpload: error,
                    inputKey: this.state.inputKey + 1
                 });
            } else if (file.type.startsWith('video/') && file.size >= maxSizeVideo) {
               error = "Размер файла превысил ограничение (100 МБ) для файлов видео."; 
                this.setState({
                    ...this.state,
                    errorUpload: error,
                    inputKey: this.state.inputKey + 1 });
            } else if(file.type.startsWith('image/') || file.type.startsWith('video/')){
                data.append('file', file);
                this.props.uploadFile(data, this.props.selectConversation.id) 
                this.setState({ 
                    ...this.state, 
                    errorUpload: '',
                    inputKey: this.state.inputKey + 1
                });   
            } else{
                error = "Файл не соответствует ожидаемому типу или размеру";   
                this.setState({ 
                    ...this.state,
                    errorUpload: error,
                    inputKey: this.state.inputKey + 1 });
            }
        }
    }
    sendMessage(){
        if(this.state.text!==''){
            this.props.sendMessage({'conversationId': this.props.selectConversation.id,'body': this.state.text})
            this.setState({
                ...this.state,
                text:''
            })
        }
    }

    
    render() {
        return (
            <div className="messages" style={{ background: document.body.style.backgroundColor}}>

                
                <div className={this.props.messageInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Загрузка...</div>
                </div>
                
                {this.props.messageError && <Popup key={this.state.inputKey} message={this.props.messageError}  />}  
                {this.state.errorUpload  && <Popup key={this.state.inputKey} message={this.state.errorUpload}  />}  
                <div id="message-container">    
                    <div className={"message-back-button"} onClick={() => {
                        this.setState({
                            ...this.state,
                        });
                        this.props.backToList();
                    }}>
                        <Icon name='arrow alternate circle left'/>
                    </div>
                    <img src={(this.props.selectConversation?.isGroupChat) ? avatarError : this.basePath + '/downloadFileByLink?id=' + this.props.selectConversation?.members[0].linkId} 
                        className="message-photo" 
                        alt=""
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src=avatarError;
                        }}/>
                    <div id={"message-body"}>
                        <div className={"RoomRow-name"}>
                            {(this.props.selectConversation?.isGroupChat) ? this.props.selectConversation.title : this.props.selectConversation?.members.find((value)=>{
                                return value.uid !== this.props.selectConversation?.myMember?.uid
                            })?.name}
                        </div>
                        <span className={"RoomRow-consumer"}>{this.props.selectConversation?.сonsumer}</span>                   
                    </div>
                    {(this.props.user.admin || this.props.user.counselor) ?
                        <div>
                            <Modal
                                basic
                                onClose={() => this.setState({ closeChatModal: false })}
                                onOpen={() => this.setState({ closeChatModal: true })}
                                open={this.state.closeChatModal}
                                size='small'
                                trigger={
                                    <img src={cartimg} className='closeConv' onClick={() => {
                                        console.log("cartimg click");
                                    }} />
                                }
                            >
                                <Header icon>
                                    <Icon name='archive' />
                                    Чат будет удален
                                </Header>
                                <Modal.Content>
                                    <p>
                                        Вы уверены в том, что хотите закрыть диалог?
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='red' inverted onClick={() => this.setState({ closeChatModal: false })}>
                                        <Icon name='remove' /> Нет
                                    </Button>
                                    <Button color='green' inverted onClick={() => {
                                        this.props.conversationClose(this.props.selectConversation.id);
                                        this.props.closeConversation();
                                        this.setState({ closeChatModal: false });
                                    }}>
                                        <Icon name='checkmark' /> Да
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </div>
                        : ''
                    }
                    
                </div>
                <div className='chat'>
                    {_.map(this.props.selectCurrentNessages, (item: MessageChatService) => 
                    <div>
                        {(item.createdByUID === this.props.selectConversation?.myMember?.uid)
                        ?<div>
                          
                             <div className="message message--user-2">  
                                <figure className="message__author-pic">
                                <img src={(this.props.selectConversation?.myMember?.linkId == null) ? avatarError : CurrentConfig.get().Api + '/downloadFileByLink?id=' + this.props.selectConversation?.myMember?.linkId} 
                                            className="message__photo" 
                                            onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src=avatarError;
                                        }}/>
                                </figure>
                                
                                <div className="message__text">
                                    <div className='message__name_aponent'> {this.props.selectConversation?.myMember?.name}</div>
                                    <div style={{color: '#f2f2f7'}}>
                                        {item?.body}
                                        {item?.mediaUrl != undefined &&
                                            <FileViewer url = {this.basePath + '/downloadChatFileById?id=' + JSON.parse(item?.mediaUrl).mediaUrl}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <time className="message__time_aponent">{format(new Date(item.createdAt),"yyyy-MM-dd H:mm")}</time>
                        </div>
                        : <div className="message message--user-1">
                            <figure className="message__author-pic">
                            <img src={(this.props.selectConversation.members
                                .find((value)=>{ 
                                    if(value.uid == item.createdByUID){
                                        return value;
                                    }
                                })?.linkId == null) ? avatarError : CurrentConfig.get().Api + '/downloadFileByLink?id=' + this.props.selectConversation.members
                                .find((value)=>{ 
                                    if(value.uid == item.createdByUID){
                                        return value;
                                    }
                                })?.linkId}
                                    className="message__photo" 
                                    onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src=avatarError;
                                }}/>
                            </figure>
                                    <div className="message__text">
                                <div className='message__name_my'>
                                    {this.props.selectConversation?.members.find((value)=> {
                                            return value.uid === item.createdByUID
                                        })?.name == undefined
                                        ? "Сотрудник МГТ"
                                        :this.props.selectConversation?.members.find((value)=> {
                                            return value.uid === item.createdByUID
                                        })?.name
                                        }
                                </div>
                                <div style={{ color: '#f2f2f7' }}>
                                    {!item.read && this.onLoadMsg(item)}
                                    {item?.body}
                                    {item?.mediaUrl != undefined &&
                                       <FileViewer url = {this.basePath + '/downloadChatFileById?id=' + JSON.parse(item?.mediaUrl).mediaUrl}/>
                                    }
                                </div>                   
                            </div>
                            <time className="message__time">{ format(new Date(item.createdAt),"yyyy-MM-dd H:mm")}</time>
                        </div>
                        }

                    </div> )}
                </div>
                {
                    this.props.selectConversation.state === "Opened"
                    ?   <div className="chat-msg-panel">
                            <TextArea className="msg-area" onChange={(event, data) => this.setState({
                                ...this.state,
                                text: (data.value || '').toString(),  
                            })} value={this.state.text}/>
                            <div className="send-image" onClick={this.onButtonClick}>
                                <Icon name='paperclip' size='big' style={{backgroundColor: 'rgba(0, 0, 0, 0)'}}/>
                                <input  
                                    key={this.state.inputKey}
                                    type="file" ref={((ref) => this.upload = ref)} 
                                    onChange={(event) => {
                                    const file = event.target.files && event.target.files[0];
                                    if (file) {
                                      this.fileChangedHandler(file);
                                    }
                                }}
                                style={{ display: "none" }} />
                            </div>
                            <Button className="msg-send" color='violet'><Icon name='send' onClick={() => {
                                    this.sendMessage();  
                            }}/></Button>
                    </div>
                    : <div/>
                }
            </div>           
        );
    }
}

function mapStateToProps(state: any) {
    let chatStore: ChatStory = state.messeger;
    let auth: AuthStore = state.authentication;
    return {
        selectConversation: chatStore.selectConversationMobileService,
        selectCurrentNessages: chatStore.selectMessages,
        messageInProcess: chatStore.messageInProcess,
        user: auth.user ?? {}, 
        currentCreateLinkMessage: chatStore.currentCreateLinkMessage,
        filterMessages: chatStore.filterMessages,
        messageError: chatStore.messageError
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(chatActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageCard);