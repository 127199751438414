import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Modal, Dropdown, TextArea, Input, Label, ModalActions, Form, Icon, Segment, Dimmer, Loader, Image } from 'semantic-ui-react'
import * as offerActions from "../../store/offer/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CamperTask, TaskStore} from "../../store/taskType";
import {CurrentConfig} from "../../api/config";
import {AuthStore} from "../../store/baseType";
import { Offer, OfferSetState } from "../../store/offerType";
import './Offer.css'


let moment = require('moment');

type OfferRowState = {
    modalOpened: boolean,
    imageLoaded:boolean,
}

type OfferkRowProp = {
    offer: Offer,
    adminMode: boolean,
    setOfferState: Function,
    setReadOffer: Function,
    refreshUserInfo: Function
    
}


export class OfferRow extends React.Component<OfferkRowProp, OfferRowState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            modalOpened: false,
            imageLoaded: false,
        }

    }

    acceptOffer = () => {
        this.props.setOfferState({ id: this.props.offer.id, statusId: 30002 } as OfferSetState);
        
        if( this.props.offer.statusId === 30001 && this.props.adminMode && this.props.offer.offerTypeId === 1){
            this.props.setReadOffer(this.props.offer.notificationOfferTypeId, this.props.offer.id);
            this.props.refreshUserInfo();
        }
       
        this.setState({ modalOpened: false });
    }

    declineOffer = () => {
        this.props.setOfferState({ id: this.props.offer.id, statusId: 30003 } as OfferSetState);
        
        if( this.props.offer.statusId === 30001 && this.props.adminMode && this.props.offer.offerTypeId === 1){
            this.props.setReadOffer(this.props.offer.notificationOfferTypeId, this.props.offer.id);
            this.props.refreshUserInfo();
        }

        this.setState({ modalOpened: false });
    }
    updateRead = () => {
        if(this.props.offer.notRead){
            if( this.props.offer.statusId === 30001 && this.props.adminMode && this.props.offer.offerTypeId === 1){
                return;
            }
            this.props.setReadOffer(this.props.offer.notificationOfferTypeId, this.props.offer.id)
            this.props.refreshUserInfo();
        }
    }

    render() {
        return (
            <Modal
                size="large"
                open={this.state.modalOpened}
                onClose={() => { this.setState({ ...this.state, modalOpened: false }) }}
                onOpen={() => {this.setState({ ...this.state, modalOpened: true }) }}
                trigger={ 
                <div onClick={this.updateRead} className="ui segment offer-row">
                    {this.props.offer.notRead
                    ?  <Label circular color={"blue"} empty key={"blue"} className={"counterRow"} />
                    :''}
                    {/*<span className={"ui circular label blue counterRow"}> </span>*/}
                    <div style={{position:"relative"} }>
                       
                        <img src={this.basePath + '/downloadFileByLink?id=' + this.props.offer.linkId}
                            className="task-photo" alt=""
                            onLoad={() => { this.setState({imageLoaded:true}) } }>
                            </img>
                        <Dimmer className={"offer-dimmer" } active={!this.state.imageLoaded}>
                                <Loader />
                            </Dimmer>
                        </div>
                    <div className={"task-body"}>
                        <div className={"task-name"}>
                            {this.props.offer.taskTitle}
                        </div>
                        <div>Тема: <b>{this.props.offer.taskTheme ?? "Без темы"}</b></div>
                        <div>Статус: <b>{this.props.offer.statusName}</b></div>
                    </div>
                </div>}>

                <Modal.Header id="contact-header" className="modal-backgroud">
                    <div onClick={() => { this.setState({ ...this.state, modalOpened: false }) }} className="back-btn"><Icon name="arrow left" />назад</div>
                </Modal.Header>
                <Modal.Content image scrolling className="modal-backgroud">
                    <Modal.Description>
                        <Form>
                            <div className="new-offer">
                                <img src={this.basePath + '/downloadFileByLink?id=' + this.props.offer.linkId} />
                                <div className="offer-row-title">
                                    <label>Название задания</label>
                                </div>
                                <label className="offer-row-content-name">{this.props.offer.offerTaskTitle}</label>

                                <div className="offer-row-title">
                                    <label>Тема задания</label>
                                </div>
                                <label className="offer-row-content">{this.props.offer.taskTheme}</label>

                                <div className="offer-row-title">
                                    <label>Описание задания</label>
                                </div>
                                <label className="offer-row-content">{this.props.offer.description}</label>

                                <div className="offer-row-title">
                                    <label>Стоимость</label>
                                </div>
                                <label className="offer-row-content">{this.props.offer.price}</label>

                                <div className="offer-row-title">
                                    <label>Одновременное кол-во</label>
                                </div>
                                <label className="offer-row-content">{this.props.offer.countOnBout}</label>
                            </div>
                        </Form>
                    </Modal.Description>
                </Modal.Content>                
                    {this.props.adminMode && this.props.offer.statusId == 30001 ?
                        <ModalActions className="modal-backgroud">
                            <div className="action-btn-part">
                                    <Button onClick={ this.acceptOffer }className="modal-action-btn" color="violet"> Отправить на модерацию</Button>
                                    <Button basic onClick={this.declineOffer}  className="modal-action-btn" color="violet"> Отказать</Button>
                                </div>
                        </ModalActions>:
                        ""}
                
            </Modal>
        );
    }
}