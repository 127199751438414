import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Button, Icon, TextArea} from 'semantic-ui-react'
import * as notificationActions from "./../../store/notification/actions"
import {refreshUserInfo} from "../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import './Notification.css';
import {CurrentConfig} from '../../api/config';
import {AuthStore} from "../../store/baseType";
import {Notification, NotificationStore} from "../../store/notificationType";

type NotificationState = {
    /* комментарий */
    commentary?: string,
}

type NotificationCardProp = {
    answerOnNotification: Function,
    backToList: Function,
    counselor: boolean,
    selectedItem: Notification | undefined,
    errorInTask: string,
    operationInProcess: boolean
}

let moment = require('moment');

class NotificationCard extends React.Component<NotificationCardProp, NotificationState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;
    upload: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {}
    }
    

    /* отправка запроса на выполнение */
    answerOnNotification() {
        this.props.answerOnNotification({
            id: this.props.selectedItem?.id,
            commentary: this.state.commentary,
        });
        this.setState({
            ...this.state,
            commentary: ''
        });
    }

    componentDidMount() {
    }

    render() {
        let moment = require('moment');
        return (
            <div className={"segment ui notification-card"}>
                <div
                    className={this.props.operationInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Загрузка...</div>
                </div>
                <div className={"notification-card-description"}>

                    <div className={"notification-card-title"}>{this.props.selectedItem?.camperTask?.boutTask?.name}</div>
                    <div className={"notification-back-button"} onClick={() => {
                        this.props.backToList();
                    }}>
                        <Icon name='arrow alternate circle left outline'/>
                    </div>
                    <div className={"notification-label"}>
                        <div>
                            Начало выполнения задания: {moment(this.props.selectedItem?.camperTask?.taskDate).format('HH:mm DD.MM.YYYY')} 
                        </div>
                        <div>
                            Продолжительность: {this.props.selectedItem?.camperTask?.taskDuration}
                        </div>
                    </div>

                    <div className={"notification-name"}>
                        {this.props.selectedItem?.camperTask?.boutTask?.name}
                    </div>
                    <div>
                        {this.props.selectedItem?.whoAsk?.name}
                    </div>

                    {this.props.selectedItem?.camperTask?.camper?.bout?.name ? <div>
                        <div className={"notification-label"}>Лагерь/смена:</div>
                        {this.props.selectedItem?.camperTask?.camper?.bout?.name}
                    </div> : ""}
                    
                    {this.props.selectedItem?.camperTask?.camper?.party?.sortOrder ? <div>
                        Отряд {this.props.selectedItem?.camperTask?.camper?.party?.sortOrder}
                    </div> : ""}

                    <div className={"notification-label"}>Описание</div>
                    {this.props.selectedItem?.camperTask?.boutTask.description}
                    
                    {this.props.selectedItem?.state?.name ? <div>
                        <div className={"notification-label"}>Статус:</div>
                        {this.props.selectedItem?.state?.name}
                    </div> : ''}

                    {this.props.selectedItem?.dateWhenQuestionAsked ? <div>
                        <div className={"notification-label"}>Дата и время  уведомления:</div>
                        {moment(this.props.selectedItem?.dateWhenQuestionAsked).format('DD.MM.YYYYг. h час. m мин.')}
                    </div> : ''}
                    {this.props.selectedItem?.dateWhenAnswerReceived ? <div>
                        <div className={"notification-label"}>Дата и время ответа:</div>
                        {moment(this.props.selectedItem?.dateWhenAnswerReceived).format('DD.MM.YYYYг. h час. m мин.')}
                    </div> : ''}

                    {this.props.selectedItem?.description ? <div>
                        <div className={"notification-label"}>Вопрос:</div>
                        {this.props.selectedItem?.description}
                    </div> : ''}
                    
                    {this.props.selectedItem?.whoAnswered?.name ? <div>
                        <div className={"notification-label"}>Вожатый:</div>
                        {this.props.selectedItem?.whoAnswered?.name}
                    </div> : ''}

                    {this.props.selectedItem?.answer || (this.props.counselor && !(this.props.selectedItem?.answer))
                        ? <div className={"notification-label"}>Ответ:</div> : ""}
                    {this.props.selectedItem?.answer ? <div>
                        {this.props.selectedItem?.answer}
                    </div> : ''}
                    {this.props.counselor && !(this.props.selectedItem?.answer) ?
                        (
                            <TextArea rows={7} className={"notification-commentary"} onChange={(event, data) => this.setState({
                                ...this.state,
                                commentary: (data.value || '').toString()
                            })} value={this.state.commentary}/>) : ""}
                            
                    {this.props.counselor && !(this.props.selectedItem?.answer) ?
                        <Button color={"orange"} fluid onClick={() => {
                            this.answerOnNotification();
                        }}>Ответить</Button> : ""}
                    {this.props.errorInTask ? <div className={"notification-error-message"}>Ошибка выполнения
                        операции: {this.props.errorInTask}</div> : ""}

                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let notificationStore: NotificationStore = state.notification;
    let authStore: AuthStore = state.authentication
    return {
        counselor: authStore.counselor,
        selectedItem: notificationStore.selectedItem,
        errorInTask: notificationStore.errorInTask,
        operationInProcess: notificationStore.operationInProcess
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(notificationActions, dispatch),
    ...bindActionCreators({refreshUserInfo}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCard);