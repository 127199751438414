import React from 'react'
import { Button, Form, Icon, Image, Modal, ModalActions, Transition } from 'semantic-ui-react'
import winner2 from './../../../images/winner2min.png';
import Confetti from 'react-confetti'
import './Winner.css';
import { AuthStore, BoutWinner } from '../../../store/baseType';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Timer from 'react-compound-timerv2/build';

const transitions = [
    'jiggle',
    'flash',
    'shake',
    'pulse',
    'tada',
    'bounce',
    'glow',
]

type WinnerProp = {
    pressed?: boolean,
    onClickWinner?: Function,
    description?: string,
    winner: BoutWinner
}

type WinnerState = {
    animation: string,
    duration: number,
    visible: boolean,
    show: boolean,
    showDescription: boolean
}

//const useNavigate = ReactRouterDOM.useNavigate;

class Winner extends React.Component<WinnerProp, WinnerState> {
    constructor(props: any) {
        super(props);
        this.state = {
            animation: transitions[0],
            duration: 1000,
            visible: true,
            show: false,
            showDescription: false,
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({
            ...this.state,
            show: true
        }), 3500);
        setTimeout(() => this.toggleVisibility("tada"), 4000);
    }

    toggleVisibility = (transitions: string) => {
        this.setState({
            ...this.state,
            animation: transitions,
            visible: !this.state.visible
        });
    }

    onDescriptionClick = () => {
        this.setState({ ...this.state, showDescription: false });
    }
    disableWinner = () => {this.setState({...this.state, show: false});}
    render() {
        const countdownTime = Date.parse(this.props.winner?.finishDate.toString()) - Date.now().valueOf();
        const isDayWinner = Date.now().valueOf() > Date.parse(this.props.winner?.startDate.toString());

        return (
            (this.state.show) ?
                <div className='winner-root' >
                    <div>
                        <Confetti
                            width={100}
                            height={100}
                            gravity={0.09}
                            friction={0.9}
                            numberOfPieces={10}
                            tweenDuration={1}
                            colors={['#FCD373', '#FFF5A3']}
                            className={'winner-image-background'}
                        />
                    </div>
                    <div className='winner-image'>
                        <Transition
                            animation={this.state.animation}
                            duration={this.state.duration}
                            visible={this.state.visible}>
                            <Image src={winner2} onClick={
                                () => {
                                    if (isDayWinner) {
                                        window.location.href = '/gift'
                                        //navigate(`/new/${id}`);
                                        //this.props.history.push("/gift");
                                        // <Redirect to={"/gift"} />
                                    } else {
                                        this.setState({ ...this.state, showDescription: true });
                                    }
                                }} />
                        </Transition>
                    </div>
                    {(isDayWinner) && countdownTime > 0 ?
                    <div className='winner-timer'>
                        <Timer initialTime={countdownTime} direction="backward" checkpoints={[{ time: 1, callback: this.disableWinner}]}
                               formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}>
                            {() => (
                                <React.Fragment>
                                    <Timer.Hours />:
                                    <Timer.Minutes />:
                                    <Timer.Seconds />
                                </React.Fragment>
                            )}
                        </Timer>
                    </div>
                    : <div className={"winner-text"}> Нажми меня! </div> }
                    {/* <div className='winner-timer'>
                        <Timer initialTime={countdownTime} direction="backward">
                            {() => (
                                <React.Fragment>
                                    <Timer.Days /> d
                                    <Timer.Hours /> :
                                    <Timer.Minutes /> :
                                    <Timer.Seconds />
                                </React.Fragment>
                            )}
                        </Timer>
                    </div>} */}
                    <div>
                        <Modal
                            size="large"
                            open={this.state.showDescription}
                            onClose={() => { this.setState({ ...this.state, showDescription: false }) }}
                            onOpen={() => { this.setState({ ...this.state, showDescription: true }) }}
                        >
                            <Modal.Header id="contact-header" className="modal-backgroud">
                                <div onClick={() => { this.setState({ ...this.state, showDescription: false }) }} className="back-btn"><Icon name="arrow left" />назад</div>
                            </Modal.Header>
                            <Modal.Content image scrolling className="modal-backgroud">
                                <Modal.Description>
                                    <Form>
                                        <div>
                                            {this.props.description}
                                        </div>
                                    </Form>
                                </Modal.Description>
                            </Modal.Content>
                            <ModalActions className="modal-backgroud">
                                <div className="action-btn-part">
                                    <Link to="/gift" className="NavBarItem NavBar">
                                        <Button className="modal-action-btn" color="violet" onClick={this.onDescriptionClick}> Перейти в магазин подарков</Button>
                                    </Link>
                                </div>
                            </ModalActions>
                        </Modal>
                    </div>
                </div>
                : null
        )
    }
}

function mapStateToProps(state: any) {
    let auth: AuthStore = state.authentication;
    return {
        description: auth.user?.winner?.drawingDescription ?? "Условия и дату проведения сообщим позже",
        winner: auth.user?.winner
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    // ...bindActionCreators(authActions, dispatch),
    // ...bindActionCreators(homeActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Winner)