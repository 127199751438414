import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Icon, TextArea, Modal } from 'semantic-ui-react'
import * as taskActions from "./../../store/task/actions"
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CamperTask, FileItem, TaskStore } from "../../store/taskType";
import { CurrentConfig } from '../../api/config';
import { AuthStore, CamperState, User } from "../../store/baseType";
import _ from "lodash";
import Rating from "../../components/UI/Rating";
import { Survay, FeedbackStore } from "../../store/feedbackType";
import * as feedbackActions from "./../../store/feedback/actions"
import QrScanB from 'react-qr-reader'
import { ImageGroup, Image } from 'react-fullscreen-image'
import './Survey.css';
import { MarkableTextArea } from '../../components/UI/MarkableTextArea/MarkableTextArea';
import FileViewer from '../../components/UI/FileViwer/FileViewer';
import { Redirect } from 'react-router';

type SurveyCardState = {

    /* видно карточку */
    cardMode: boolean,
    /* рейтинг */
    rating?: number[] | undefined,
    /* комментарий */
    commentary: string,
    /* файлы */
    files: any | undefined,
    /*Ошибки*/
    errors?: any,
    /*Состояние модального окна*/
    openHelpModal: boolean

    sendButtonDisabled: boolean,
    commentDeniedCount?: number,

    typeFile: string,

    selectedFile: any | null,
    errorUpload: string,
    redirectMain: boolean
}

type SurveyCardProp = {
    user: User,
    surveyQRCode: Function,
    getSurvey: Function,
    backToList: Function,
    qrReaderMode: boolean,
    errorSendGetSurvay?: string,
    sendSurvayTitlesInProcess: boolean,
    selectedSurvayItem: Survay,
    denieRedirectFromMain: Function,
    errorInSendSurvay: String

}


class SurveyQRCard extends React.Component<SurveyCardProp, SurveyCardState> {
    upload: HTMLInputElement | null = null;
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            openHelpModal: false,
            cardMode: false,
            files: '',
            commentary: '',
            sendButtonDisabled: true,
            typeFile: '',
            errorUpload: '',
            selectedFile: null,
            redirectMain: false
        }
    }

    updateDisableButton() {
        this.setState({ sendButtonDisabled: (this.state.commentDeniedCount ?? 1) > 0 })
    }

    //Проверка формы
    validationSave = () => {
        let errors = [];

        if (!(this.props.selectedSurvayItem.surveyQuestions.every((item) => item.quality > 0))) {
            errors.push('Необходимо сделать выбор!');
        }
        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }
    async componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.denieRedirectFromMain();
    }

    onButtonClick = () => {
        if (this.upload && this.upload.click) {
            this.upload.click();
        }
    };
    async componentDidUpdate() {
       
    }
    fileChangedHandler = (event: any) => {
        const file = event.target.files[0];
        const maxSizeImage = 10 * 1024 * 1024; // Максимальный размер изображения в байтах (здесь 10 МБ)
        const maxSizeVideo = 100 * 1024 * 1024; // Максимальный размер видео в байтах (здесь 100 МБ)
        let error = '';
        if (file) {
            if (file.type.startsWith('image/') && file.size >= maxSizeImage) {
                error = "Размер файла превысил ограничение (10 МБ) для файлов изображений.";
                this.upload = null;
                this.setState({selectedFile: null, errorUpload: error });
            } else if (file.type.startsWith('video/') && file.size >= maxSizeVideo) {
               error = "Размер файла превысил ограничение (100 МБ) для файлов видео."; 
                this.upload = null;
               this.setState({selectedFile: null, errorUpload: error });
            } else if(file.type.startsWith('image/') || file.type.startsWith('video/')){
                this.setState({ ...this.state, selectedFile: file, errorUpload: error });   
            } else{
                // Файл не соответствует ожидаемому типу или размеру
                error = "Файл не соответствует ожидаемому типу или размеру";   
                this.setState({ selectedFile: null, errorUpload: error });
              
            }
        }
    }
    handleQrScan = (data: any) => {
        if (data) {
            // this.qrReaderData = data;
            this.props.getSurvey(data);
        }
    }

    componentWillMount() {
        try {
            navigator.mediaDevices.getUserMedia({ video: true });
        }
        catch {
            alert("Требуется безопасное подключение (https)");

        }
    }

    handleQrError = (err: any) => {
        console.error(err)
    }

    sendRequest  = async ()  => {
        const data = new FormData();
        if (!this.validationSave()) {
            return;
        }
       
        if (this.state.errorUpload === ''){
            data.append('file', this.state.selectedFile);
            data.append('id', this.props.selectedSurvayItem.id.toString());
            data.append('state', this.props.selectedSurvayItem.state);
            data.append('comment', this.state.commentary);
            data.append('SurveyQuestions', JSON.stringify(this.props.selectedSurvayItem.surveyQuestions));
            this.props.surveyQRCode(data);
            this.setState({});
        }
    }

    render() {
        if (this.state.redirectMain) {
            return <Redirect to="/feedback" />
        }
        return (   
            <div>
                <div
                    className={this.props.sendSurvayTitlesInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                 <div className="ui text loader">Загрузка...</div>
            </div>
            {(this.props.qrReaderMode === false)
            ?   <div>
                    <Icon size='large' className='help-wizer' name='question circle outline' onClick={() => {
                        this.setState({
                            ...this.state,
                            openHelpModal: true
                        })
                    }} />
                    </div>
                    :
                    <div className={"task-card-footer"}>
                        {this.props.selectedSurvayItem.state === "Выполнено" && (
                            <div className="task-card-back-btn" onClick={() => {
                                this.setState({
                                    ...this.state,
                                    redirectMain: true
                                });
                        }}><Icon name="arrow left" />назад</div>) }
                       
                        <div className='survey-heder' style={{marginBottom: '50px', marginTop: '50px'}}>
                            Опрос по QR-коду
                        </div>
                        <div className={"survey-card-description2"}> 

                            {this.props.selectedSurvayItem.state === "Доступно" && (
                                <div>
                                    <div className={"survey-card-label2"}>Тема опроса:</div>
                                    <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.surveyType}</div>
                                    <div className={"survey-card-label2"}>Название лагеря и смены:</div>
                                    <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.boutName}</div>
                                </div>
                            )}
                            {this.props.selectedSurvayItem.state === "Выполнено" && (
                                <div>
                                    <div className={"survey-card-label2"}>Статус:</div>
                                    <div className={"task-card-value2"}>{this.props.selectedSurvayItem.state}</div>
                                    <div className={"survey-card-label2"}>Дата и время выполнения:</div>
                                    <div className={"task-card-value2"}>{this.props.selectedSurvayItem.createTime}</div>
                                    <div className={"survey-card-label2"}>Наименование лагеря и смена:</div>
                                    <div className={"task-card-value2"}>{this.props.selectedSurvayItem?.boutName}</div>
                                </div>
                            )}
                            
                            <div style={(this.props.selectedSurvayItem.state === "Выполнено") ? { pointerEvents: 'none'} :{ pointerEvents: 'visible' }}>
                                <div style={{ marginTop: '30px', marginBottom: '20px' }}>
                                    {this.props.selectedSurvayItem?.surveyQuestions.map((quest, index) => {
                                        return (
                                            <div>
                                                <tr>
                                                    <td style={{ marginBottom: '5px', marginTop: '10px', fontSize: '13px' }} className={"task-value"}>{quest.name}</td>
                                                </tr>
                                                <tr style={{ fontSize: '24px' }}>
                                                    <Rating rating={quest.quality} OnChange={(r: number) => {
                                                        this.props.selectedSurvayItem.surveyQuestions[index].quality = r
                                                        this.setState({
                                                            ...this.state,
                                                        })
                                                    }} />
                                                </tr>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            
                            <div className={"survey-card-label2"}>Комментарий:</div>
                            {
                                this.props.selectedSurvayItem.state === "Выполнено"
                                ? <div className='task-card-value2'>{this.props.selectedSurvayItem.comment ?? "Комментарии отсутствуют"}</div> 
                                :<div className='qr-texteria'>
                                    <MarkableTextArea
                                        className="task-commentary"
                                        text={this.state.commentary}
                                        type="area"
                                        verifyDelay={500}
                                        onChange={(value: any) => {
                                            this.setState({
                                                sendButtonDisabled: true,
                                                commentary: (value || '').toString()
                                            })
                                        }}
                                        onRangesUpdated={(ranges: []) => {
                                            this.setState({ commentDeniedCount: ranges.length });
                                            this.updateDisableButton();
                                        }}
                                    />
                                </div>
                            }
                            <div className={"task-photo-blocks"}>
                                {(this.props.selectedSurvayItem.state === "Доступно") ?
                                    <div onClick={this.onButtonClick}>
                                        <div style={{ left: '0%', marginTop: '20px' }} id="feedback-add-photo-button">
                                            <div id="feedback-add-photo-button-text">
                                                Добавить фото
                                            </div>
                                            <div id="add-photo-button-plus-border"></div>
                                            <div id="add-photo-button-plus-horisontal-line"></div>
                                            <div id="add-photo-button-plus-vertical-line"></div>
                                        </div>
                                        <input type="file" ref={((ref) => this.upload = ref)} onChange={this.fileChangedHandler}
                                            style={{ display: "none" }} />
                                    </div>
                                    : ""
                                }
                                 <br />
                                {this.state.errorUpload !== '' 
                                    ?   <div className={"modal-error-text"}>
                                            {this.state.errorUpload}
                                        </div>
                                    : ""
                                }
                                <div>
                                    {(this.props.selectedSurvayItem.fileInterviewTaskId != null) ?
                                       <div>
                                            <br/>
                                            <FileViewer url = {`${this.basePath}/downloadFeedbackFileById?id=${this.props.selectedSurvayItem.fileInterviewTaskId}`}/>
                                        </div>
                                        :
                                        <div>
                                            <br/>
                                            {
                                               this.state.selectedFile? 
                                               <FileViewer url = {URL.createObjectURL(this.state.selectedFile)}/>
                                               : ""
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            {this.props.selectedSurvayItem.state === "Доступно" && (
                                <Button disabled={ this.state.sendButtonDisabled} className="submitTaskBtn survey-btn" fluid onClick={() => {
                                    this.sendRequest();
                                }}>Подтвердить</Button>   
                            )}
                            {(this.state.errors?.length > 0 || this.props.errorSendGetSurvay) ?
                                <ul>
                                    {this.state.errors?.length > 0 ? _.map(this.state.errors, (item: any) => <li
                                        className={"error-class"}>{item}</li>) :
                                        this.props.errorSendGetSurvay ?
                                            <li className={"error-class"}>{this.props.errorSendGetSurvay}</li> : ""}
                                </ul>
                                : ""
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state: any) {

    let authStore: AuthStore = state.authentication
    let feedbackStore: FeedbackStore = state.feedback;
    return {
        counselor: authStore.counselor,
        selectedSurvayItem: feedbackStore.selectedSurvayItem,
        sendSurvayTitlesInProcess: feedbackStore.sendSurvayTitlesInProcess,
        errorSendGetSurvay: feedbackStore.errorSendGetSurvay,
        qrReaderMode: feedbackStore.qrReaderMode,
        user: authStore.user
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(feedbackActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQRCard);