import {RatingUser} from "../ratingType";
import ratingApi from "../../api/rating";
import {Gift} from "../giftType";
import {
    RATING_PARTY_LIST_SUCCESS,
    RATING_PARTY_LIST_FAILED,
    RATING_PARTY_LIST_PROCESS,
    RATING_LIST_SUCCESS,
    RATING_LIST_FAILED,
    RATING_LIST_PROCESS,
    RATING_USER_SELECT,
    RATING_FAILED,
    RATING_SUCCESS,
    RATING_PROCESS
} from "./types";
import {clearReduxStateInternal} from "../authentication/actions";
import {User} from "../baseType";

// получение рейтинга ребёнка
export const listCamper = () => (dispatch: any) => {
    ratingApi.listCamper((status: number, data: RatingUser | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: RATING_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: RATING_FAILED,
                errorMessage: data || 'Ошибка получения рейтинга'
            });
        }
    });

    return dispatch({
        type: RATING_PROCESS
    });
}
// получение рейтинга ребёнка
export const listConsaler = () => (dispatch: any) => {
    ratingApi.listConsaler(1,(status: number, data: User[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: RATING_PARTY_LIST_SUCCESS,
                list: data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: RATING_PARTY_LIST_FAILED,
                errorMessage: data || 'Ошибка получения рейтинга'
            });
        }
    });

    return dispatch({
        type: RATING_PARTY_LIST_PROCESS
    });
}

// получение рейтинга отряда
export const listPartyRating = () => (dispatch: any) => {
    ratingApi.listConsaler(0, (status: number, data: User[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: RATING_LIST_SUCCESS,
                list: data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: RATING_LIST_FAILED,
                errorMessage: data || 'Ошибка получения рейтинга отряда'
            });
        }
    });

    return dispatch({
        type: RATING_LIST_PROCESS
    });
}

/// выбрать человека
export const selectUser = (model: User) => (dispatch: any) => {
    return dispatch({
        type: RATING_USER_SELECT,
        data: model
    });
}