import {
    WEB_PUSH_REG_DEV_SUCCESS,
    WEB_PUSH_REG_DEV_FAILED,
    WEB_PUSH_REG_DEV_PROCESS,
    WEB_PUSH_REG_SW_SUCCESS,
    WEB_PUSH_GET_KEY_SUCCESS,
    WEB_PUSH_REG_SW_FAILED,
    WEB_PUSH_DEL_DEV_FAILED,
    WEB_PUSH_DEL_DEV_SUCCESS,
} from "./types";

import {Reducer} from "redux";
import {WebPushStore} from "../webPushType";

const initialState = () => {
    return {
        publicKey:"",
        serviceRegister:false,
        receivePush:false,
        push_endpoint: localStorage.getItem("push_endpoint"),
        push_p256dh: localStorage.getItem("push_p256dh"),
        push_auth: localStorage.getItem("push_auth"),
    } as WebPushStore;
};

const webPushStore: Reducer = (state: WebPushStore = initialState(), action: any) => {
    switch (action.type) {
        case WEB_PUSH_REG_DEV_SUCCESS: {
            return {
                ...state,

            };
        }
        case WEB_PUSH_REG_DEV_FAILED: {
            return {
                ...state,

            };
        }
        case WEB_PUSH_REG_DEV_PROCESS: {
            return {
                ...state,

            };
        }

        case WEB_PUSH_DEL_DEV_SUCCESS: {
            return {
                ...state,

            };
        }
        case WEB_PUSH_DEL_DEV_FAILED: {
            return {
                ...state,

            };
        }

        case WEB_PUSH_REG_SW_SUCCESS: {
            return {
                ...state,
                serviceRegister: true
            };
        }
        case WEB_PUSH_REG_SW_FAILED: {
            return {
                ...state,
                serviceRegister: false,
                errormsg: action.errorMsg
            };
        }
        case WEB_PUSH_GET_KEY_SUCCESS: {
            return {
                ...state,
                publicKey: action.publicKey
            }
        }

        default:
            return state;
    }
};

export default webPushStore;
