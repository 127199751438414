import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Header, Input } from "semantic-ui-react";
import './login.css';
import './Registration.css';
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { Link } from "react-router-dom";
import { RegisterInfo } from '../../store/baseType';
import _ from "lodash";
import { bindActionCreators } from "redux";
import * as authActions from "../../store/authentication/actions";
import { connect } from "react-redux";
import '../../components/main.css';
import PasswordInput from "../../components/UI/PasswordInput";
import logo from '../../images/playhelp-logo.png';

type RegistrationProps = {
    registerUser: Function
    clearReduxState: Function
    errorMessage?: string
    registrationRequested?: boolean,
    authIoProcess: boolean
}

class Registration extends React.Component<RegistrationProps, RegisterInfo> {
    constructor(props: any) {
        super(props);
        this.state = {
            nickName: '',
            email: '',
            phone: '',
            pass: '',
            passConfirm: '',
            errors: [],
            approveRule: false,
            approveMailing: false,
            readRule: false
        };
    }

    // проверка формы
    validation = () => {
        let errors = [];

        if (!(this.state.nickName)) {
            errors.push('Не указано как к вам обращаться');
        }

        if (!(this.state.email)) {
            errors.push('Не указана электронная почта');
        }

        if (!(this.state.phone.match(/^[+]{1}[0-9]{11}$/))) {
            errors.push('Не указан корректный номер телефона');
        }

        if (!(this.state.pass)) {
            errors.push('Не указан пароль');
        } else if (this.state.pass !== this.state.passConfirm) {
            errors.push('Пароль не совпадает с его подтверждением');
        }
        if (!(this.state.pass.length >= Math.min(6, 9) && this.state.pass.length <= Math.max(6, 9))) 
        {
            errors.push('Длина пароля должна быть от 6 до 9 символов');
        }
        if (!(this.state.approveRule)) {
            errors.push('Для регистрации необходимо согласится с пользовательским соглашением');
        }

        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }

    // регистрация
    handleRegistration = () => {
        if (!this.validation()) {
            return;
        }

        this.props.registerUser(this.state);
    };

    render() {
        return (
            <div className="App" style={{'display': 'content'}}>
                <div className={this.props.authIoProcess ? "ui active inverted dimmer" : "ui inverted dimmer"} >
                    <div className="ui text loader">Загрузка...</div>
                </div>
                <div className="login-screen-background">
                    <div className="login-screen-header">
                        <br/>
                        <img className="login-screen-header-logo" alt='ГУАК "Мосгортур"' src={logo} height="85px"/>
                        <br/>
                        <div className="login-screen-header-text1">Добро</div>
                        <div className="login-screen-header-text2">пожаловать</div>
                    </div>
                    <div className="login-screen-body">
                        <div className="login-screen-body-text1">Регистрация</div>
                        <br/>

                        {(this.state.readRule) ?
                            <div>
                                <Header color={"grey"}>ПРАВИЛА<br /> участия в проекте<br /> "Играй-Помогай"</Header>
                                <div className="div-space">&nbsp;</div>


                                <div className={"rule-block"}>
                                <h3>I. Общие положения</h3>
                                <p>
                                        1.1. Настоящие Правила участия в проекте "Играй-Помогай" (далее – Правила) определяют основные правила реализации и участия в проекте "Играй-Помогай" (далее – Проект), предназначенного для интеллектуального и социального развития, а также организации досуга детей-сирот и детей, оставшихся без попечения родителей, помещенных уполномоченными органами в сфере организации и деятельности по опеке, попечительству и патронажу в городе Москве под надзор в организации для детей-сирот и детей, оставшихся без попечения родителей, а также детей, являющихся воспитанниками подведомственных Департаменту труда и социальной защиты населения города Москвы специализированных организаций для несовершеннолетних и нуждающихся в социальной реабилитации (далее – дети-сироты и воспитанники), отдых и оздоровление которых осуществляется Государственным автономным учреждением культуры города Москвы "Московское агентство организации отдыха и туризма" (далее – ГАУК "МОСГОРТУР").
                                        <br />1.2. В Проекте могут принять участие дети-сироты и воспитанники в возрасте от 12 до 17 лет (включительно), отдых и оздоровление которых осуществляется в обособленных отрядах. Реализация Проекта должна быть предусмотрена программой отдыха и оздоровления (смены) в данных отрядах.
                                        <br />1.3. Проект реализуется с использованием веб-приложения Проекта (далее – веб-приложение Проекта) расположенного по адресу: https://m.aiso.mos.ru
                                        <br />1.4. Правила являются открытыми и общедоступными, вступают в силу с даты размещения в информационно-телекоммуникационной сети Интернет по адресу: https://mosgortur.ru/igrai-pomogai.
                                        <br />1.5. Лицо, желающее стать участником Проекта, должно пройти регистрацию в веб-приложении Проекта.
                                        <br />1.6. Лица, прошедшие регистрацию в веб-приложении Проекта, становятся участниками Проекта (далее – участник Проекта) с даты регистрации.
                                        <br />1.7. Регистрируясь в веб-приложении Проекта, участник Проекта путем проставления соответствующей отметки в специальном разделе веб-приложения Проекта выражает согласие с настоящими Правилами и заявляет, что: все действия в рамках Проекта совершаются участником Проекта добровольно, лично и в соответствии с законодательством Российской Федерации; дает свое согласие ГАУК "МОСГОРТУР" (далее – Оператор) на обработку и распространение своих персональных данных в соответствии с законодательством Российской Федерации.
                                        <br />1.8. В случае внесения изменений и/или дополнений в настоящие Правила, указанные изменения и/или дополнения вступают в силу с даты размещения новой редакции Правил в информационно-телекоммуникационной сети Интернет по адресу https://mosgortur.ru/igrai-pomogai, если иной срок не указан в новой редакции Правил. Персонально каждому участнику Проекта указанные изменения и/или дополнения не направляются. Продолжение участия в Проекте после изменений и/или дополнений Правил означает согласие участника Проекта с такими изменениями и/или дополнениями.
                                        <br />1.9. В случае если участник Проекта не согласен с внесенными в Правила изменениями и/или дополнениями, или желает прекратить участие в Проекте по любому другому основанию, он вправе удалить свою учетную запись в веб-приложении Проекта.
                                        </p>
                                        
                                        <h3>II. Порядок регистрации для участия в Проекте</h3>
                                        <p>
                                        2.1. Регистрация в веб-приложении Проекта является бесплатной и добровольной.
                                        <br />2.2. При регистрации в веб-приложении Проекта участник Проекта указывает свои фамилию, имя, отчество (при наличии), номер телефона и адрес электронной почты, а также придуманный им пароль, соответствующий установленным в веб-приложении требованиям.
                                        <br />2.3. При регистрации участника Проекта в веб-приложении Проекта создается "личный кабинет" участника Проекта. Вход в "личный кабинет" участника Проекта осуществляется путем введения адреса электронной почты (логина) и пароля, указанных при регистрации участника Проекта.
                                        <br />2.4. Участник Проекта несет ответственность за достоверность, актуальность и полноту предоставленной при регистрации и использовании веб-приложения Проекта информации, а также обеспечивают сохранность и неразглашение сведений, полученных им при регистрации в веб-приложении Проекта в целях доступа к Проекту (логин, пароль). При этом не допускается указывать: заведомо ложную информацию; слова эротического или порнографического характера; рекламу и/или ссылки на другие сайты; нецензурные и оскорбительные слова и выражения; призывы к осуществлению террористической деятельности или публично оправдывающие терроризм, а также любую другую информацию экстремистского характера; пропаганду порнографии, культа насилия и жестокости; призывы к расовой, религиозной или национальной розни; другую информацию, противоречащую законодательству Российской Федерации.
                                        <br />2.5. Не допускается использовать в качестве фото (аватара) участника Проекта в "личном кабинете" фотографии: которые являются объектом авторских прав или личными изображениями (фотографиями) третьих лиц; содержащие элементы эротического или порнографического характера; на которых присутствует явная реклама и/или ссылки на другие сайты; содержащие текстовые сообщения; на которых присутствуют изображения оскорбительного характера; на которых присутствуют изображения с пропагандой насилия или сцены насилия; на которых присутствуют призывы к расовой, религиозной или национальной розни; другая информация, противоречащая законодательству Российской Федерации.
                                        <br />2.6. В рамках Проекта участниками Проекта осуществляется бронирование и выполнение следующих видов заданий:
                                        <br /> 2.6.1. хозяйственные;
                                        <br />2.6.2. творческие;
                                        <br />2.6.2. режимные;
                                        <br />2.6.3. организаторские.
                                        <br />2.7. Задания, выполнение которых организуется в рамках реализации Проекта, открываются для участников Проекта, находящихся в пределах одной организации отдыха детей и их оздоровления.
                                        <br />2.8. В период отдыха и оздоровления в пределах одной организации отдыха детей и их оздоровления, предусмотренной путевкой (далее – Смена) на каждого потенциального участника Проекта создается не менее 23 (двадцати трех) заданий. При этом количество заданий может увеличиваться для одного участника Проекта за счет заданий, от которых отказались или не были выбраны другими участниками. Участник Проекта может одновременно выполнять не более 3 (трех) заданий разной продолжительности выполнения.
                                        <br />2.9. Участник Проекта может отказаться от выполнения задания после того, как получил его для выполнения, но не позднее срока, указанного в описании задания.
                                        <br />2.10. Участник Проекта, в случае возникновения вопроса(ов) по выполняемому заданию, может направить их посредством веб-приложения Проекта в течение 4 (четырех) часов с момента начала выполнения задания.
                                        <br />2.11. Ответ на вопрос по выполняемому заданию, заданный посредством веб-приложения Проекта, поступает участнику Проекта не позднее следующего дня направления вопроса в период с 14 до 16 часов и с 22 до 24 часов.
                                        <br />2.12. В ходе реализации Проекта участникам Проекта предоставляется возможность знакомиться с личным рейтингом участника Проекта в веб-приложении Проекта.
                                        <br />2.13. Все действия, совершенные с использованием адреса электронной почты и пароля участника Проекта считаются совершенными данным участником Проекта.
                                        <br />2.14. Участник Проекта для использования функциональных возможностей веб-приложения Проекта самостоятельно обеспечивает доступ к информационно-телекоммуникационной сети Интернет.
                                        <br />2.15. Руководство пользователя веб-приложения Проекта размещено в веб-приложении Проекта.
                                        </p>

                                        <h3>III. Обработка персональных данных участника Проекта</h3>
                                        <p>
                                        3.1. Оператор обеспечивает защиту информации ограниченного доступа, в том числе персональных данных, предоставленной участником Проекта при использовании веб-приложения Проекта.
                                        <br />3.2. Оператор осуществляет обработку персональных данных участника Проекта в целях обеспечения реализации Проекта. Обработка и передача пользовательских данных выполняется с применением методов шифрования.
                                        <br />3.3. Соглашаясь с Правилами, участник Проекта выражает согласие и разрешает Оператору:
                                        <br />3.3.1. Осуществлять автоматизированную обработку, а также обработку без использования средств автоматизации своих персональных данных (фамилии, имени, отчества, даты рождения, половой принадлежности, номера мобильного телефона, адреса электронной почты), включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных, а также их передачу (предоставление доступа) партнерам Оператора.
                                        <br />3.3.2. Осуществлять обработку иной информации об участнике Проекта, которая может включать в себя информацию, автоматически получаемую при доступе к веб-приложению Проекта с использованием cookie.
                                        <br />3.4. Согласие на обработку персональных данных в соответствии с Правилами действует до момента его отзыва.
                                        </p>

                                        <h3>IV. Права и обязанности участника Проекта</h3>                                        
                                        <p>
                                        4.1. Участник Проекта имеет право:
                                        <br />4.1.1. Предоставлять о себе информацию в объеме, необходимом для участия в Проекте.
                                        <br />4.1.2. Использовать все функциональные возможности веб-приложения Проекта, доступные в "личном кабинете".
                                        <br />4.1.3. Обращаться в службу технической поддержки Проекта, а также к Оператору для решения вопросов, возникших в ходе использования веб-приложения Проекта.
                                        <br />4.1.4. Указывать в "личном кабинете" следующие данные о себе: фамилию, имя, отчество (при наличии), номер телефона и адрес электронной почты, иные сведения. Перечисленные данные используются, в том числе, для добавления новых заданий с целью повышения мотивации участников Проекта.
                                        <br />4.2. Участнику Проекта предоставляются иные права и возлагаются обязанности в соответствии с Правилами.
                                        </p>

                                        <h3>V. Права и обязанности Оператора</h3>
                                        <p>
                                        5.1. Оператор имеет право:
                                        <br />5.1.1. Прекращать или приостанавливать функционирование сервисов Проекта, изменять веб-приложение Проекта полностью или частично без предварительного уведомления участника Проекта.
                                        <br />5.1.2. Приостановить доступ участника Проекта к веб-приложению Проекта в случае возникновения технических неполадок в целях их устранения или для проведения профилактических работ. Оператор принимает меры для устранения неполадок в кратчайшие сроки, но не гарантирует отсутствия сбоев и ошибок.
                                        <br />5.1.3. Направлять участнику Проекта сообщения, содержащие организационно-техническую информацию о веб-приложении Проекта, информацию о Проекте и/или иную информацию в рамках Проекта.
                                        <br />5.1.4. Устанавливать ограничения по сроку хранения и максимальному количеству материалов, размещаемых в веб-приложении Проекта.
                                        </p>

                                        <h3>VI. Порядок начисления баллов</h3>
                                        <p>
                                        6.1. В рамках Проекта предусматривается стимулирование участников Проекта к выполнению максимального количества заданий Проекта путем начисления баллов за выполнение отдельных заданий в целях получения подарка.
                                        <br />6.1.1. За выполнение творческого задания, срок выполнения которого рассчитан на 1 (один) календарный день, предусмотрено начисление 500 (пятисот) баллов.
                                        <br />6.1.2. За выполнение организационного задания, срок выполнения которого рассчитан на 1 (один) календарный день, предусмотрено начисление 500 (пятисот) баллов.
                                        <br />6.1.3. За выполнение художественного задания, срок выполнения которого рассчитан на 1 (один) календарный день, предусмотрено начисление 1000 (тысячи) баллов.
                                        <br />6.1.4. За выполнение задания, не зависимо от категории, срок выполнения которого рассчитан на 3 (три) календарных дня, предусмотрено начисление 2000 (двух тысяч) баллов.
                                        <br />6.1.5. За выполнение задания, не зависимо от категории, срок выполнения которого рассчитан на 7 (семь) календарных дней, предусмотрено начисление 4000 (четырех тысяч) баллов.
                                        <br />6.1.6. Стоимость заданий, созданных для одного потенциального участника Проекта в рамках одной Смены, не превышает 29000 (двадцать девять тысяч) баллов. При желании участник Проекта может забронировать и выполнить задания, которые не были забронированы или выполнены другими участниками Проекта. При этом количество баллов, заработанных участником Проекта, превысит 29000 (двадцать девять тысяч) баллов.
                                        <br />6.1.7. Баллы, полученные участником Проекта за выполнение заданий в течение нескольких смен – суммируются.
                                        <br />6.2. Количество баллов, которые можно получить за выполнение конкретного задания, указано в описании соответствующего задания.
                                        <br />6.3. Баллы, начисленные участнику Проекта в ходе реализации Проекта, могут использоваться только в веб-приложении Проекта. Денежная компенсация, в том числе за не использованные баллы, не осуществляется.
                                        <br />6.4. Участник Проекта, которому начислены баллы, может забронировать подарок в специальном разделе "Магазин Подарков" веб-приложения Проекта с учетом набранного участником Проекта количества баллов и наличия возможности предоставления подарка в данный период времени.
                                        <br />6.5. При прекращении участия в Проекте по любому основанию баллы, начисленные участнику Проекта на дату прекращения участия в Проекте, аннулируются и в денежной форме не компенсируются.
                                        <br />6.6. По достижению участником Проекта восемнадцатилетнего возраста баллы, полученные им за успешное выполнение заданий, должны быть использованы в срок, не превышающий 6 (шести) месяцев с даты наступления 18 (восемнадцати) лет. Неизрасходованные в указанный срок баллы аннулируются и в денежной форме не компенсируются.
                                        </p>

                                        <h3>VII. Порядок предоставления подарков</h3>
                                        <p>
                                        7.1. Участник Проекта вправе по своему усмотрению обменять накопленные им баллы на подарки, представленные в разделе "Магазин Подарков" в веб-приложении Проекта (далее – раздел "Магазин Подарков").
                                        <br />7.2. Количество подарков лимитировано и может быть доступно для заказа в течение ограниченного периода времени.
                                        <br />7.3. Для получения подарков участник Проекта должен забронировать подарок не позднее 16 (шестнадцатого) дня смены в соответствующей организации отдыха. В момент бронирования подарка автоматически списываются баллы за подарок.
                                        <br />7.3.1. Сумма баллов, соответствующая выбранному участником Проекта подарку, списывается при выборе участником Проекта подарка.
                                        <br />7.4. Выдача забронированных подарков осуществляется работниками Оператора в месте осуществления отдыха и оздоровления участника Проекта не позднее 20 (двадцатого) дня смены в соответствующей организации отдыха. В случае, если участник Проекта забронировал подарок после наступления даты, указанной в пункте 7.3 Правил, получение подарка осуществляется в порядке, указанном в пункте 7.4.1 Правил.
                                        <br />7.4.1. В случае, если участник проекта не смог получить забронированный подарок в срок, указанный в пункте 7.4 Правил, выдача подарка осуществляется в офисе Оператора, не позднее 20 декабря текущего года. Дата и место выдачи подарка согласовывается с Оператором по номеру телефона: +7 (925) 500-01-36, или адресу электронной почты: krasichkovaav@culture.mos.ru.
                                        <br />7.5. Для получения подарка у участника Проекта должен быть доступ к мобильному телефону, номер которого был указан при регистрации в личном кабинете, для получения СМС с кодом подтверждения получения подарка.
                                        <br />7.6. Передавая код, указанный в пункте 7.5 Правил, партнеру Оператора, участник Проекта подтверждает намерение получить подарок в указанной форме и факт получения подарка.
                                        <br />7.7. Для отказа от подарка и возврата баллов в "личном кабинете" необходимо нажать кнопку "Отменить заказ".
                                        <br />7.8. Если участник Проекта не забрал подарок и не отменил свой заказ до 20 декабря текущего года, после наступления указанной даты заказ аннулируется.
                                        </p>

                                        <h3>VIII. Условия и порядок получения суперприза</h3>
                                        <p>
                                        8.1. На 17 день смены в организации отдыха детей и их оздоровления веб-приложением Проекта автоматически определяется участник Проекта, заработавший наибольшее количество баллов. На 17 день смены в 17 часов (МСК) участнику Проекта, заработавшему наибольшее количество баллов, на электронную почту приходит уведомление и у него на главном экране веб-приложения Проекта начинает отображаться иконка "Кубок", при нажатии на которую выводится текстовое сообщение о том, что участник Проекта стал победителем и указывается дата, когда суперпризы, размещенные в "Магазине подарков" веб-приложения Проекта, станут доступными ему для выбора.
                                        <br />8.2. Участник Проекта, указанный в пункте 8.1 настоящих Правил, в указанный в информационном сообщении день, бронирует один из доступных в "Магазине подарков" веб-приложения Проекта суперприз. Функционал бронирования доступен в течение 12 часов, в указанный в информационном сообщении день.
                                        <br />8.3. Участник Проекта, забронировавший суперприз, может воспользоваться этим суперпризом в срок, указанный на карточке суперприза в день выбора данного суперприза.
                                        <br />8.4. После получения от Оператора, участником Проекта, сертификата на забронированный суперприз, отношения по использованию суперприза возникают исключительно между участником Проекта и партнером Оператора. Оператор не несет ответственности за сроки и качество предоставления партнером Оператора участнику Проекта выбранного им суперприза.
                                        </p>

                                        <h3>IX. Ответственность</h3>
                                        <p>
                                        9.1. Участник Проекта несет ответственность за размещаемую в личном кабинете веб-приложения Проекта информацию в соответствии с законодательством Российской Федерации.
                                        <br />9.2. Оператор не несет ответственности:
                                        <br />9.2.1. За аварии, сбои или перебои в функционировании веб-приложения Проекта, взаимодействующих с ним информационных систем, связанные с нарушениями в работе оборудования, сетей связи, которые обеспечиваются, эксплуатируются и/или обслуживаются третьими лицами.
                                        <br />9.2.2. За неправомерные действия третьих лиц, в том числе связанные с использованием учетной записи участника Проекта в веб-приложении Проекта, а также с использованием сведений о нем, предоставленных им в ходе участия в Проекте, если такие сведения стали доступны третьим лицами не по вине Оператора.
                                        <br />9.2.3. За информацию, публикуемую участником Проекта в своем личном кабинете, и, в случае выявления нарушений или получения информации от других участников Проекта либо третьих лиц о нарушении участником Проекта настоящих Правил или прав субъектов персональных данных, Оператор оставляет за собой право редактировать (модерировать), блокировать или удалять публикуемую участником Проекта информацию, нарушающую права других участников Проекта или настоящие Правила.
                                        </p>

                                        <h3>X. Разрешение споров</h3>
                                        <p>
                                        10.1. В случае возникновения споров участник Проекта (законный представитель участника Проекта) может направить претензию по адресу электронной почты: mosgortur@culture.mos.ru. Срок рассмотрения претензии и подготовки ответа на нее составляет 30 календарных дней с даты получения претензии.
                                        <br />10.2. В случае недостижения согласия спорные вопросы разрешаются в соответствии с действующим законодательством Российской Федерации.
                                        </p>

                                        <h3>XI. Адрес и реквизиты Оператора</h3>
                                        <p>
                                        Полное наименование: Государственное автономное учреждение культуры города Москвы "Московское агентство организации отдыха и туризма".
                                        <br />Место нахождения: 121099, город Москва, 2-й Смоленский переулок, дом 1/4.
                                        <br />ОГРН: 1107746117940.
                                        <br />ИНН: 7710878000.
                                        <br />КПП: 770401001.
                                        </p>

                                <div className="div-space">&nbsp;</div>
                                <Button fluid={true} as={Button} color={"green"} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        readRule: false,
                                        approveRule: true
                                    })
                                }}>Принять</Button>
                                <div className="div-space">&nbsp;</div>
                                <Button fluid={true} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        readRule: false,
                                        approveRule: false
                                    })
                                }} type="button">Отказаться</Button>
                                <div className="div-space">&nbsp;</div>
                                </div>
                            </div>
                            :
                            !(this.props.registrationRequested) ?
                                <div className="app-registration-body">
                                    <Input icon='user' iconPosition='left' placeholder='Псевдоним (Никнейм)'
                                        className="login-input"
                                        value={this.state.nickName}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                            ...this.state,
                                            nickName: data.value
                                        })} />
                                    <Input icon='mail' iconPosition='left' placeholder='Электронная почта'
                                        className="login-input"
                                        value={this.state.email}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                            ...this.state,
                                            email: data.value
                                        })} />
                                    <Input icon='phone' iconPosition='left' placeholder='Телефон' className="login-input"
                                        value={this.state.phone}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                            ...this.state,
                                            phone: formatPhone(data.value)
                                        })} />
                                    <div className="div-space">&nbsp;</div>

                                    <PasswordInput password={this.state.pass} confirm={this.state.passConfirm} changePassword={(v: string) => {
                                        this.setState({
                                            ...this.state,
                                            pass: v
                                        });
                                    }} changeConfirm={(v: string) => {
                                        this.setState({
                                            ...this.state,
                                            passConfirm: v
                                        });
                                    }} />

                                    {(this.state.errors.length > 0 || this.props.errorMessage) ?
                                        <ul>
                                            {this.state.errors.length > 0 ? _.map(this.state.errors, (item: any) => <li
                                                className={"error-class"}>{item}</li>) :
                                                this.props.errorMessage ?
                                                    <li className={"error-class"}>{this.props.errorMessage}</li> : ""}
                                        </ul>
                                        : ""
                                    }
                                    <div>
                                        <div className="ui checkbox">
                                            <input id="rulesApprove" checked={this.state.approveRule} onClick={(() => {
                                                if (this.state.approveRule) {
                                                    this.setState({
                                                        ...this.state,
                                                        approveRule: false
                                                    });
                                                } else {
                                                    this.setState({
                                                        ...this.state,
                                                        readRule: true
                                                    });
                                                }
                                            })} type="checkbox" />
                                            <label htmlFor="rulesApprove">Принять правила участия в проекте "Играй-Помогай"</label>
                                        </div>
                                        {/*
                                        <div className='ui checkbox'>
                                            <input id="mailingApprove" checked={this.state.approveMailing} onClick={(() => {
                                                this.setState({
                                                    ...this.state,
                                                    approveMailing: !this.state.approveMailing
                                                });
                                            })} type="checkbox" />
                                            <label htmlFor="mailingApprove" >Подписаться на информационную и рекламную рассылку</label>
                                        </div>
                                        */}
                                    </div>

                                    <Button id="registration-page-register-button" fluid={true} color={"violet"} onClick={this.handleRegistration}
                                        type="button">Зарегистрироваться</Button>
                                    <Button  id="registration-page-back-to-main-button" fluid={true} onClick={() => {
                                        this.props.clearReduxState();
                                    }} as={Link} to="/">На главный экран</Button>
                                    <div className="div-space">&nbsp;</div>
                                </div>
                                :
                                    <div className="app-registration-body">
                                        <Header color={"grey"}>Регистрация</Header>
                                        <div className="div-space">&nbsp;</div>
                                        <div className="grey">
                                            Вы успешно зарегистрировались и можете войти в Приложение.
                                            Вам необходимо показать личный код учетной записи вожатому для подтверждения.
                                        </div>
                                        <div className="div-space">&nbsp;</div>
                                        <div className="ui container grid">
                                            <div className="row">
                                                <Button fluid onClick={() => {
                                                    this.props.clearReduxState();
                                                }} as={Link} to="/">На главный экран</Button>
                                            </div>
                                        </div>
                                        <div className="div-space">&nbsp;</div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        )
    };
}

var validPhone = "+7";
function formatPhone(phone: string) {
    if (phone.match(/^[+]{1}[0-9]+$/) && phone.length <= 12) validPhone = phone;
    return validPhone;
}

function mapStateToProps(state: any) {
    return {
        registrationRequested: state.authentication.registrationRequested,
        errorMessage: state.authentication.registrationErrorMessage,
        authIoProcess: state.authentication.authIoProcess
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
