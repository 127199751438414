import React from 'react';
import 'semantic-ui-css/semantic.min.css'
//import {Button, Icon, TextArea} from 'semantic-ui-react'
import {refreshUserInfo} from "../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as authActions from "../../store/authentication/actions";
import {Redirect} from "react-router-dom";
//import {Feedback, FeedbackTitle, FeedbackStore, Survay} from "../../store/feedbackType";
import * as feedbackActions from "./../../store/feedback/actions"

type SupportState = {
}

type SupportProp = {
    setRedirectFromSupport: Function
}

class Support extends React.Component<SupportProp, SupportState> {

    //constructor(props: any) {
    //    super(props);
    //}

    componentDidMount() {
        //window.location.href = 'https://mosgortur.ru/app_deti';
        this.props.setRedirectFromSupport();
    }

    render() {
        return (
            <Redirect to="/feedback" />
        )
    }
}

function mapStateToProps(state: any) {
    //let feedbackStore: FeedbackStore = state.feedback;
    return {
        //isRedirectFromSupport: feedbackStore.isRedirectFromSupport,
        registrationRequested: state.authentication.registrationRequested,
        errorMessage: state.authentication.registrationErrorMessage,
        authIoProcess: state.authentication.authIoProcess
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(feedbackActions, dispatch),
    ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
