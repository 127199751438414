import {State} from "./baseType";
import {Link} from "./taskType";

export interface ListGiftFilter {
    t: string;
    pf: number | undefined;
    pt: number | undefined;
    giftCategoriesFilter: number[] | undefined;
}

export interface ListGiftReservedFilter {
    t: string;
    pf: number | undefined;
    pt: number | undefined;
    reserved: boolean;
    issued: boolean;
    canceled: boolean;
    refusal: boolean;
}

export interface ProcessGiftReserve {
    id: number;
    count: number;
}

export interface ProcessSuperGiftReserve {
    superGiftId: number;
    superGiftParamId: number;
    winnerId: number;
}

export interface GiftStore {
    listInProcess: boolean,
    list: Gift[],
    wishList: Gift[],
    listReserved: GiftReserved[],
    errorInList: string | undefined,
    operationInProcess: boolean,
    selectedItem: Gift | undefined,
    errorInProcess: string | undefined,
    errorInCancelProcess: string | undefined,
    errorInRatingProcess: string | undefined,
    reservationCompleted: boolean,    
    typeInProcess: boolean,
    giftCategories: GiftCategory[],
    errorInType: string | undefined,
    
    addWishedInProcess: boolean,

    confirmgiftProcess: boolean,
    errorconfirmgift: string,

    confirmedGift: boolean
}

export interface Gift {
    name: string;
    nameForShop: string;
    description: string;
    price: number;
    dateStartUsing: string | null;
    giftParameters: GiftParameter[];
    linkId: number | null;
    link: Link;
    stateId: number | null;
    state: State;
    id: number;
    lastUpdateTick: number;
    eid: number | null;
    eidSendStatus: number | null;
    eidSyncDate: string | null;
    superPrize: boolean;
    inWishList: boolean;
    viewed: boolean;
    pointAvailable: boolean;
    giftCategory: GiftCategory;
    allowOrder: boolean;
    orderDeniedReasons: string[];
    addWishedInProcess: boolean;
}

export interface GiftCategory {
    name: string;
    id:number;
}

export interface GiftParameter {
    name: string;
    count: number;
    reserved: GiftReserved[];
    giftId: number | null;
    gift: Gift;
    id: number;
    lastUpdateTick: number;
    eid: number | null;
    eidSendStatus: number | null;
    eidSyncDate: string | null;
}

export interface GiftReserved {
    count: number;
    price: number;
    aprovalCode: string;
    codeSendDate: string | null;
    phone: string;
    linkId: number | null;
    giftId: number | null;
    gift: GiftParameter;
    ownerId: number | null;
    owner: any; //Account;
    stateId: number | null;
    state: State;
    id: number;
    rating: number | null;
    lastUpdateTick: number;
    eid: number | null;
    eidSendStatus: number | null;
    eidSyncDate: string | null;
}

export interface GiftByConfirm{
    childId: number;
    giftIds: number[];
    commandType: string;
}



export const StateGiftReserved = 22001;
export const StateGiftIssued = 22003;