import axios from "axios";
import {CurrentConfig} from './config';

const fileApi = {
    // загрузка файла
    uploadUserPhoto: (data: any, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/UploadUserPhoto`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    downloadFile : (fileUrl: string, callback: Function) => {
        axios({
            url: fileUrl,
            method: "GET",
            responseType: "blob",
            headers: {
                "Accept": "*/*" }
        }).then(
            response => {
                callback(response.status, new Blob([response.data], {type: response.headers["content-type"]}) );
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
        
    }
}

export default fileApi;