import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as ratingActions from "./../../store/rating/actions"
import { refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RatingStore, RatingUser } from "../../store/ratingType";
import _ from "lodash";
import './Rating.css';
import { CurrentConfig } from '../../api/config';
import { AuthStore, User } from "../../store/baseType";
import RattingRow from "./RatingRow";

import box from './../../images/box-rating.png';
import porf from './../../images/porf-rating.png';
import gold from './../../images/gold-rating.png';
import flag from './../../images/flag-rating.png';
import pountflag from './../../images/pount-flag-rating.png';
import petich from './../../images/petich-rating.png';
import heard from './../../images/heard-rating.png';
import hand from './../../images/hand-rating.png';
import footerrating from './../../images/footer-child-rating.png';
import { SelectBar } from '../../components/UI/SelectBar/SelectBar';

const TabStates = {
    camp: "Лагерь",
    party: "Отряд",
}

//import footerraiting from './../../images/footer-raiting.png';
type RatingState = {
    party: boolean,
    currentTab: string,
}

type RatingProp = {
    user: User,
    listPartyRating: Function,
    list: User[] | undefined,
    listParty: User[] | undefined,
    ratingUser: RatingUser,
    listCamper: Function,
    listConsaler: Function,
    refreshUserInfo: Function,
    listInProcessRating: boolean,
    listInPartyProcess: boolean,
    errorInList: string | undefined,
    errorInListParty: string | undefined,

}

class RatingList extends React.Component<RatingProp, RatingState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            party: false,
            currentTab: TabStates.camp,
        }
    }
    EventConsaler() {
        this.setState({ ...this.state, party: false });
        this.props.listConsaler();
        this.props.listPartyRating();
    }

    EventCamper() {
        this.setState({ ...this.state });
        this.props.listCamper();
    }

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
        if(this.props.user.counselor || this.props.user.admin)
        {
            this.EventConsaler();
        }
        else {
            this.EventCamper();
        }


    }

    render() {
        return (
            (this.props.user.counselor || this.props.user.admin) ?
                <div className="ratingBlock">
                    <div className={this.props.listInPartyProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                        <div className="ui text loader">Загрузка...</div>
                    </div>
                    <div className='rating-bacground'>

                    </div>
                    {
                        this.props.user.admin
                        ? <div className={"rating-selectors-block"}>
                            <SelectBar
                                elems={[TabStates.camp]}
                                selectedElem={this.state.currentTab}
                                elemClick={(elem: string) => this.setState({currentTab:elem}) }
                            />
                        </div>
                        : <div className={"rating-selectors-block"}>
                            <SelectBar
                                elems={[TabStates.camp, TabStates.party]}
                                selectedElem={this.state.currentTab}
                                elemClick={(elem: string) => this.setState({currentTab:elem}) }
                            />
                        </div>
                    }
                    <div hidden={!(this.state.currentTab === TabStates.camp)} className={"ui items segments"}>
                        <div
                            className={this.props.listInProcessRating ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                            <div className="ui text loader">Загрузка...</div>
                        </div>
                        <table className={!this.props.list || this.props.list?.length === 0 ? "hidden" : "rating-table"}>
                            <thead>
                                <th>№</th>
                                <th>ФИО</th>
                                <th>Рейтинг</th>
                            </thead>
                            <tbody>
                                {_.map(this.props.list, (item: User, index) => <RattingRow rating={item}
                                    number={index + 1} />)}
                            </tbody>
                        </table>

                        {!this.props.list || this.props.list?.length === 0 ?
                            <div className={"approve-not-found"}>В рейтинге нет сведений</div> : ''}
                    </div>
                        
                    <div hidden={!(this.state.currentTab === TabStates.party)} className={"ui items segments"}>
                        <div
                            className={this.props.listInPartyProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                            <div className="ui text loader">Загрузка...</div>
                        </div>
                        <table className={!this.props.listParty || this.props.listParty?.length === 0 ? "hidden" : "rating-table"}>
                            <thead>
                                <th>№</th>
                                <th>ФИО</th>
                                <th>Рейтинг</th>
                            </thead>
                            <tbody>
                                {_.map(this.props.listParty, (item: User, index) => <RattingRow rating={item}
                                    number={index + 1} />)}
                            </tbody>
                        </table>

                        {!this.props.listParty || this.props.listParty?.length === 0 ?
                            <div className={"approve-not-found"}>В рейтинге нет сведений</div> : ''}
                    </div>
                    
                    
                </div>
                :
                <div className='card-raiting'>
                    <div className={this.props.listInProcessRating ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                        <div className="ui text loader">Загрузка...</div>
                    </div>
                    <div className="heder">Баллы</div>
                    <div className='row-rating'>
                        <div className='column-rating'>
                            <img className='imgfon' src={box} />
                            <div>Заработано всего</div>
                            <div className='price-rating'>{this.props.ratingUser.pointCountAll == null ? 0 : this.props.ratingUser.pointCountAll}</div>
                        </div>


                        <div className='column-rating'>
                            <img className='imgfon' src={porf} />
                            <div>Заработано за смену</div>
                            <div className='price-rating'>{this.props.ratingUser.pointCountBout == null ? 0 : this.props.ratingUser.pointCountBout}</div>
                        </div>


                        <div className='column-rating'>
                            <img className='imgfon' src={gold} />
                            <div>Можно потратить</div>
                            <div className='price-rating'>{this.props.ratingUser.pointCountAvailable == null ? 0 : this.props.ratingUser.pointCountAvailable}</div>
                        </div>
                    </div>
                    <div className="heder">Задания</div>
                    <div className='row-rating'>
                        <div className='column-rating'>
                            <img className='imgfon' src={flag} />
                            <div>Выполнено за смену</div>
                            <div className='price-rating'>{this.props.ratingUser.taskCountBout == null ? 0 : this.props.ratingUser.taskCountBout}</div>
                        </div>


                        <div className='column-rating'>
                            <img className='imgfon' src={pountflag} />
                            <div>В работе за смену</div>
                            <div className='price-rating'>{this.props.ratingUser.taskCountInWorkBout == null ? 0 : this.props.ratingUser.taskCountInWorkBout}</div>
                        </div>


                        <div className='column-rating'>
                            <img className='imgfon' src={petich} />
                            <div>Предложено заданий</div>
                            <div className='price-rating'>{this.props.ratingUser.taskCountOfferedBout == null ? 0 : this.props.ratingUser.taskCountOfferedBout}
                            </div>
                        </div>
                    </div>
                    <div className='row-rating'>
                        <div className='column3-rating'>
                            <img className='imgfon2' src={heard} />
                            <div className='price2-rating'>{this.props.ratingUser.likeCount == null ? 0 : this.props.ratingUser.likeCount}</div>
                        </div>


                        <div className='column3-rating'>
                            <img className='imgfon2' src={hand} />
                            <div className='price2-rating'>{this.props.ratingUser.superLikeCount == null ? 0 : this.props.ratingUser.superLikeCount}</div>
                        </div>


                        <div className='column4-rating'>
                            <div style={{ position: 'relative', width: '180px' }}>
                                <svg className='imgfon3' width="160" height="91" viewBox="0 0 160 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect style={JSON.parse(this.props.user.theme.jsonTheme).block} width="160" height="69" rx="34.5" />
                                    <path style={JSON.parse(this.props.user.theme.jsonTheme).block} d="M30 91C34.7693 80.243 35.5663 73.8452 30 61H58C51.5622 75.5448 44.9894 81.8351 30 91Z" />
                                </svg>
                                <div className='price3-rating'>{this.props.ratingUser.positionUnit == null ? 0 : this.props.ratingUser.positionUnit} место</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={footerrating} style={{ width: '100%' }} />
                    </div>
                </div>
        );

    }
}

function mapStateToProps(state: any) {
    let store: RatingStore = state.rating;
    let authStore: AuthStore = state.authentication
    return {
        user: authStore.user,
        listInProcessRating: store.listInProcess,
        listInPartyProcess: store.listInPartyProcess,
        list: store.list,
        listParty: store.listParty,
        ratingUser: store.ratingUser,
        errorInList: store.errorInList,
        errorInListParty: store.errorInListParty,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(ratingActions, dispatch),
    ...bindActionCreators({ refreshUserInfo }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingList);