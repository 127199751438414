import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as giftActions from "./../../store/gift/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {GiftReserved, GiftStore, StateGiftIssued, StateGiftReserved} from "../../store/giftType";
import {CurrentConfig} from "../../api/config";
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import Rating from "../../components/UI/Rating";
import QRcode from 'qrcode.react'
import { QRScanner } from '../../components/UI/QRScanner/QRScanner';

import qrCode from './../../images/qrCode.png';

type GiftReservedRowState = {
    rating: number,
    openQrModal: boolean,
    cancelReserveModal:boolean,

    qrErrorMsg?: string,
    qrModalOpened: boolean,
}

type GiftReservedRowProp = {
    gift: GiftReserved,
    errorMessage: string | undefined,
    cancelGift: Function,
    ratingGift: Function,
}


class GiftReservedRow extends React.Component<GiftReservedRowProp, GiftReservedRowState> {
    basePath: string = CurrentConfig.get().Api;
    stringForQrCode: string = "";

    constructor(props: any) {
        super(props);
        this.state = {
            qrModalOpened: false,
            rating: 0,
            openQrModal: false,
            cancelReserveModal:false,
        }
    }

    componentDidMount() {
        this.stringForQrCode = this.props.gift.gift.gift.name;
    }
    
    handleClick() {
    }

    render() {
        return (
            <div className="ui segment gift-reserved"> 
                <div className={"gift-r-body"}>
                    <img src={this.basePath + '/downloadFileByLink?id=' + this.props.gift.gift.gift.linkId}
                        className="gift-r-photo" alt="" />
                    <div>
                        <div className={"gift-r-name"}>
                            {this.props.gift.gift.gift.nameForShop ?? this.props.gift.gift.gift.name}
                        </div>
                        <div>
                            {this.props.gift.gift.name}
                        </div>
                        {this.props.gift.rating !== null && this.props.gift.rating >= 0 ?
                            <div>
                                <div className={"gift-r-title"}>
                                    Рейтинг
                                </div>
                                <div className={"gift-r-rating"}>
                                    <Rating size="big" rating={this.props.gift.rating} OnChange={(r: number) => {
                                    }} />
                                </div>
                            </div> : this.props.gift.stateId === StateGiftIssued ?
                                <div>
                                    <div className={"gift-r-title"}>
                                        Рейтинг
                                    </div>
                                    <div className={"gift-r-rating"}>
                                        <Rating size='big' rating={this.state.rating} OnChange={(r: number) => {
                                            this.setState({
                                                ...this.state,
                                                rating: r
                                            });
                                        }} />
                                        {this.state.rating > 0 ? <Button color={"olive"} size={"mini"} onClick={() => {
                                            this.props.ratingGift(this.props.gift.id, this.state.rating);
                                        }}>Оценить</Button> : ""}
                                    </div>
                                </div> : ""}
                    </div>
                </div>

                <div className="control-panel">
                    {!this.props.gift.gift.gift.superPrize ? 
                    <div className={"gift-r-point2"}>
                            {this.props.gift.stateId === StateGiftReserved ?
                                <div>
                                    <Modal
                                        basic
                                        onClose={() => this.setState({cancelReserveModal:false})}
                                        onOpen={() => this.setState({cancelReserveModal:true})}
                                        open={this.state.cancelReserveModal}
                                        size='small'
                                        trigger={<Button basic color="violet" className="mega-rounded-by-olga">Отменить резерв</Button>}
                                    >
                                        <Header icon>
                                            <Icon name='archive' />
                                            Отмена резерва
                                        </Header>
                                        <Modal.Content>
                                            <p>
                                                Вы уверены в том, что хотите отменить резерв подарка?
                                            </p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button basic color='red' inverted onClick={() => this.setState({cancelReserveModal:false})}>
                                                <Icon name='remove' /> Нет
                                            </Button>
                                            <Button color='green' inverted onClick={() => {
                                                this.props.cancelGift(this.props.gift.id);
                                                this.setState({ cancelReserveModal: false });
                                            }}>
                                                <Icon name='checkmark' /> Да
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </div>
                                : ""}
                    </div>  : ""}
                </div>
            </div>
           
        );
    }
}

function mapStateToProps(state: any) {
    let giftStore: GiftStore = state.gift;
    return {
        errorMessage: giftStore.errorInRatingProcess
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(giftActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftReservedRow);