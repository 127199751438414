import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {connect} from "react-redux";
import {CurrentConfig} from "../../api/config";
import { Redirect } from "react-router-dom";
import {AuthStore, User} from "../../store/baseType";
import * as ratingActions from "./../../store/rating/actions"
import {bindActionCreators} from "redux";

type RattingState = {
    redirect: boolean
}

type RattingProp = {
    rating: User,
    number: number,
    accountId: number,
    counselor: boolean
    selectUser: Function
}

class RattingRow extends React.Component<RattingProp, RattingState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/ratingUser"} />
        }
        
        return (
            <tr className={this.props.rating.id === this.props.accountId ? "rating-current-account" : ""} onClick={()=>{
                this.props.selectUser(this.props.rating);
                this.setState({
                    ...this.state,
                    redirect: true
                })   
            }}>
                <td>
                    <div className='td1-rating'>
                        {this.props.number}
                    </div>
                </td>
                <td className='td2-rating'>
                    <div>
                        {this.props.rating.name}
                    </div>
                    <div>
                        Отряд { this.props.rating.party}
                    </div>  
                </td>
                <td>
                    <div className='td3-rating'>
                        {(this.props.rating.totalPointsPerShift ?? 0)}
                    </div> 
                </td>
            </tr>
        );
    }
}

function mapStateToProps(state: any) {
    let authStore: AuthStore = state.authentication
    return {
        accountId: authStore.user?.id,
        counselor: authStore.counselor
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(ratingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RattingRow);