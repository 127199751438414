import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Dropdown, Input } from 'semantic-ui-react'
import * as taskActions from "./../../store/gift/actions"
import { clearListState, clearListStateClear, refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { Gift, ListGiftFilter, GiftStore, GiftCategory } from "../../store/giftType";
import _ from "lodash";
import GiftRow from "./GiftRow";
import GiftRowSlider from "./GiftRowSlider";
import './Gift.css';
import './Search.css';
import { CurrentConfig } from '../../api/config';
import GiftCard from "./GiftCard";
import { AuthStore, BoutWinner, User } from "../../store/baseType";
import AwesomeSliderGift from 'react-awesome-slider';
import { HandySvg } from 'handy-svg';
import withAutoplayGift from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import searchinput from './../../images/search.svg';
import Timer from 'react-compound-timerv2/build';
import { SearchBar } from '../../components/UI/SearchBar/SearchBar';
import { SelectBar } from '../../components/UI/SelectBar/SelectBar';



const TabStates = {
    All: "Все",
    Viewed: "Просмотрено",
    Wished: "Список желаний"
}

type GiftState = {
    filter: string,

    /* стомость от */
    pf?: number

    /* стоимость до */
    pt?: number,

    /* видно фильтр */
    filterView: boolean,

    /* видно карточку */
    cardMode: boolean,

    /* категории подарков */
    giftCategoriesFilter: number[] | any,

    selectedTab: string,

    settingsGift: any,

    AutoplaySliderGift: any

}
export const IconBuck = () => (
    <HandySvg
        src={searchinput}
        className="icon"
        width="28"
        height="28"
    />
);
type GiftProp = {
    listGifts: Function,
    refreshUserInfo: Function,
    listReservedGifts: Function,
    selectGift: Function,
    listInProcess: boolean,
    clearCardState: boolean,
    clearListStateClear: Function,
    list: Gift[] | undefined,
    errorInList: string | undefined,
    selectedItem: Gift | undefined,
    getGiftCategories: Function,
    typeInProcess: boolean,
    errorInType: string | undefined,
    giftCategories: GiftCategory[] | undefined,
    user: User,

    errorInAddWished: string | undefined,
    addWishedInProcess: boolean,
    winner: BoutWinner
}


class GiftList extends React.Component<GiftProp, GiftState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;
    constructor(props: any) {
        super(props);
        this.state = {
            filter: '',
            filterView: false,
            cardMode: false,
            giftCategoriesFilter: [],
            selectedTab: TabStates.All,
            settingsGift: require('./CaruselGift.css'),
            AutoplaySliderGift: new withAutoplayGift(AwesomeSliderGift)
        }
    }

    changeTabState(sender: GiftList, state: string) {
        sender.setState({ selectedTab: state });
    }

    searchItems() {
        let filter: ListGiftFilter = {
            pf: this.state.pf,
            pt: this.state.pt,
            t: this.state.filter,
            giftCategoriesFilter: this.state.giftCategoriesFilter
        };

        this.setState({ ...this.state, filterView: false });
        this.props.listGifts(filter);
        this.props.getGiftCategories();
    }

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
        this.searchItems();
    }

    componentDidUpdate(prevProps: Readonly<GiftProp>, prevState: Readonly<GiftState>, snapshot?: any) {
        if (!prevProps.clearCardState && this.props.clearCardState) {
            this.setState({
                ...this.state,
                cardMode: false
            });
        }

        if (this.props.clearCardState) {
            this.props.clearListStateClear();
        }
    }

    generateTabButton(sender: GiftList, state: string): any {
        return sender.state.selectedTab === state ? (<Button disabled color={"orange"}>{state}</Button>) : (<Button onClick={() => sender.changeTabState(sender, state)} basic color={"black"}>{state}</Button>)
    }

    generateGiftList(state: string): any {

        const countdownTime = this.props.winner ? Date.parse(this.props.winner?.finishDate.toString()) - Date.now().valueOf() : 0;
        const isDayWinner = this.props.winner ? (Date.now().valueOf() > Date.parse(this.props.winner?.startDate.toString()) &&
            Date.now().valueOf() < Date.parse(this.props.winner?.finishDate.toString())) : false;

        switch (state) {
            case TabStates.All:
                return <div className='giftCarusel giftCarusel-bulets giftCarusel-next giftCarusel-bullets'>
                    <div>
                        {(isDayWinner) ?
                            <div className='winner-timer-gift'>
                                <Timer initialTime={countdownTime} direction="backward"
                                       formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}>
                                    {() => (
                                        <React.Fragment>
                                            <Timer.Hours />:
                                            <Timer.Minutes />:
                                            <Timer.Seconds />
                                        </React.Fragment>
                                    )}
                                </Timer>
                            </div> : ""}
                    </div>
                    {(this.props.list !== undefined && this.props.list.length !== 0)
                        ?
                        <this.state.AutoplaySliderGift
                            // cssModule={this.state.settingsGift}
                            play={false}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={6000}
                        >
                            {this.props.list.filter((sup) => sup.superPrize).map((item: Gift) => {
                                return <div>
                                    <GiftRowSlider gift={item} onClick={(item: Gift) => {
                                        this.props.selectGift(item);
                                        this.setState({
                                            ...this.state,
                                            cardMode: true
                                        });
                                    }}
                                        inGiftLent={false}
                                    />
                                </div>



                            })}
                        </this.state.AutoplaySliderGift>
                        : ''
                    }
                    {(_.map(this.props.list, (item: any) => {
                        if (!item.superPrize) {
                            return <GiftRow gift={item} onClick={(item: Gift) => {
                                this.props.selectGift(item);
                                this.setState({
                                    ...this.state,
                                    cardMode: true
                                });
                            }}
                                inGiftLent={false}
                            />
                        }
                    }))}
                </div>
            case TabStates.Viewed:
                let viewed = new Array();
                this.props.list?.forEach((gift: Gift) => {
                    if (gift.viewed)
                        viewed.push(gift);
                })
                return (_.map(viewed, (item: any) => {
                    return <GiftRow gift={item} onClick={(item: Gift) => {

                        this.props.selectGift(item);
                        this.setState({
                            ...this.state,
                            cardMode: true
                        });

                    }}
                        inGiftLent={false}
                    />
                }));
            case TabStates.Wished:
                let wished = new Array();
                this.props.list?.forEach((gift: Gift) => {
                    if (gift.inWishList)
                        wished.push(gift);
                })
                return (_.map(wished, (item: any) => {
                    return <GiftRow gift={item} onClick={(item: Gift) => {
                        this.props.selectGift(item);
                        this.setState({
                            ...this.state,
                            cardMode: true
                        });
                    }}
                        inGiftLent={false}
                    />
                }));
        }
    }

    render() {
        return (
            <div className="taskBlock" /* style={JSON.parse(this.props.user.theme.jsonTheme).fon}*/>
                {(!this.state.cardMode) ?
                    <div>
                        <div className="shop-selector">
                            <SearchBar
                                action={{

                                    icon: 'filter',
                                    onClick: () => {
                                        this.setState({
                                            ...this.state,
                                            filterView: !this.state.filterView
                                        })
                                    }
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                    let text = data.value;
                                    this.setState({
                                        ...this.state,
                                        filter: text
                                    });

                                    if (this.interval) {
                                        clearInterval(this.interval);
                                        this.interval = undefined;
                                    }

                                    this.interval = setInterval(() => {
                                        clearInterval(this.interval);
                                        this.searchItems();
                                        this.interval = undefined;
                                    }, 600);
                                }}
                            />
                            <div className={this.state.filterView ? "" : "hidden"} style={{ margin: '25px' }}>
                                <div>
                                    <div className={"task-table-header"}>
                                        Стоимость подарка
                                    </div>
                                    <div className='gift-tho gift-input'>
                                        <Input type={'text'} maxLength={5} pattern="[0-9]*" placeholder={"от"}

                                            onChange={(e, data: any) => {
                                                this.setState({
                                                    ...this.state,
                                                    pf: data.value.replace(/\D/, '')
                                                });
                                            }} value={this.state.pf} className="ui-background" />


                                        <Input type={'text'} maxLength={5} pattern="[0-9]*" placeholder={"до"}
                                            onChange={(e, data: any) => {
                                                this.setState({
                                                    ...this.state,
                                                    pt: data.value.replace(/\D/, '')
                                                });
                                            }} value={this.state.pt} className="ui-background" />
                                    </div>
                                    <div className={"task-table-header"}>
                                        Виды подарков
                                    </div>

                                    <div className='gift-dropdown'>
                                        <Dropdown placeholder='Все'
                                            fluid
                                            multiple
                                            selection
                                            options={_.map(this.props.giftCategories, (item: any) => { return { key: item.name, text: item.name, value: item.id } })}
                                            onChange={((event, data) => {
                                                this.setState({
                                                    ...this.state,
                                                    giftCategoriesFilter: data.value
                                                });
                                            })} value={this.state.giftCategoriesFilter} />
                                    </div>
                                    <Button
                                        style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive}
                                        onClick={() => {
                                            this.searchItems();
                                        }} fluid>Поиск</Button>
                                </div>
                            </div>

                            <SelectBar
                                elems={[TabStates.All, TabStates.Viewed, TabStates.Wished]}
                                selectedElem={this.state.selectedTab}
                                elemClick={(elem: string) => this.setState({selectedTab:elem}) }
                            />
                        </div>
                        <div>
                            <div
                                className={this.props.listInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                <div className="ui text loader">Загрузка...</div>
                            </div>
                            {this.generateGiftList(this.state.selectedTab)}
                            {!this.props.list || this.props.list?.length === 0 ?
                                <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> : ''}
                        </div>
                    </div>
                    :
                    <GiftCard
                        backToList={() => {
                            this.setState({
                                ...this.state,
                                cardMode: false,
                            });
                        }}
                        updateGiftList={() => {
                            this.searchItems();
                        }}
                    />
                }
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let giftStore: GiftStore = state.gift;
    let authStore: AuthStore = state.authentication;
    return {
        listInProcess: giftStore.listInProcess,
        list: giftStore.list,
        errorInList: giftStore.errorInList,
        clearCardState: authStore.clearCardState,
        user: authStore.user,
        giftCategories: giftStore.giftCategories,
        addWishedInProcess: false, // giftStore.addWishedInProcess
        winner: authStore?.user?.winner
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
    ...bindActionCreators({ refreshUserInfo, clearListStateClear }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftList);