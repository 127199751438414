import React from 'react';
import logo from './../../images/playhelp-logo.png';
import './login.css';
import 'semantic-ui-css/semantic.min.css'
import {Button, Input} from 'semantic-ui-react'
import * as authActions from "./../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {Link} from "react-router-dom";
//import '../../components/main.css';
//import login_icon from './../../images/login-icon.svg';
import login_icon from './../../images/login-icon.png';
import notification_icon from './../../images/notification.svg';
import arrow_left_icon from './../../images/arrow-left-icon.svg';

type AuthStateType = {
    login: string,
    pass: string,
    error: string
}

type LoginProps = {
    restoreByEmail: Function
    restoreBySms: Function
    clearReduxState: Function
    restoreRequested?: boolean
    errorMessage?: string
    message?: string
}

class RestorePassword extends React.Component<LoginProps, AuthStateType> {
    wrapper: any;
    login: any;
    password: any;

    constructor(props: any) {
        super(props);
        this.wrapper = React.createRef();
        this.login = React.createRef();
        this.password = React.createRef();
        this.state = {
            login: '',
            pass: '',
            error: '',
        };
    }

    handleSms = () => {
        this.props.restoreBySms(this.state);
    };

    handleEmail = () => {
        this.props.restoreByEmail(this.state);
    };

    render() {
        return (
            <div className="App" style={{'display': 'content'}}>
                <div className="login-screen-background">
                    <div className="restore-password-screen-header">
                        <br/>
                        <img className="login-screen-header-logo" alt='ГУАК "Мосгортур"' src={logo} height="85px"/>
                        <br/>
                        <div className="login-screen-header-text1">Добро</div>
                        <div className="login-screen-header-text2">пожаловать</div>
                    </div>
                    <div className="login-screen-body">
                    <div className="login-screen-body-text1">Восстановление пароля</div>
                    <br/>
                    <img id={"login-icon"} alt="" src={login_icon}/>
                    <div className="login-screen-body-text2">Электронная почта</div>
                    <Input placeholder='' fluid 
                        id="login-screen-email" value={this.state.login}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                            ...this.state,
                            login: data.value
                        })}/>

                    {this.props.errorMessage ?
                        <div>
                            <div className={"login-screen-error-text5"}>{this.props.errorMessage}</div>
                            <div className={"login-screen-error-notification-icon"}><img alt="" src={notification_icon}/></div>
                        </div>
                        :
                        <div>&nbsp;</div>
                    }
                    <br/>
                    <br/>
                    <br/>
                    {(this.props.restoreRequested === true) ?
                        <div className="ui container grid">
                            <div>
                                <div className={"restore-password-notification-text"}>Запрошено востановление пароля</div>
                                <div className={"restore-password-notification-icon"}><img alt="" src={notification_icon}/></div>
                            </div>

                            <Button id="restore-password-back-button" fluid={true} to='/login' as={Link} onClick={() => {
                                this.props.clearReduxState();
                            }} >Вернуться&nbsp; <img id="restore-password-back-icon" alt="" src={arrow_left_icon}/></Button>
                        </div>
                        :
                        <div className="ui container grid">
                            <Button id="restore-password-send-email-button" fluid={true} color={"violet"} onClick={this.handleEmail}
                                type="button">Отправить на почту</Button>

                            <Button id="restore-password-back-button" fluid={true} to='/login' as={Link} onClick={() => {
                                this.props.clearReduxState();
                            }} >Вернуться&nbsp; <img id="restore-password-back-icon" alt="" src={arrow_left_icon}/></Button>
                        </div>
                    }
                </div>
            </div>
        </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        restoreRequested: state.authentication.restoreRequested,
        errorMessage: state.authentication.restoreErrorMessage,
        message: state.authentication.message
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestorePassword);