import React,{useEffect,useState } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputOnChangeData, } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {Link} from "react-router-dom";
import {Button, Input,Modal,Dropdown,TextArea} from 'semantic-ui-react';
import './Chat.css';
import _ from "lodash";
import * as authActions from "../../store/authentication/actions"
import * as chatActions from "../../store/chat/actions"
import {ConversationMobileService, ChatStory , Member ,ConversationChatService,FilterConversation} from "../../store/chatType";
import {AuthStore, User} from "../../store/baseType";
import RoomRow from "../Messager/RoomRow";
import MessageCard from "../Messager/MessageCard";
import { boolean } from 'yargs';
export const DISPATH = "DISPATH";

type ChatStateType = {
    isMessage: boolean,
    isAddMessageModal: boolean,
    isAddMessageGroupModal: boolean,
    currentUserToAddMessage: number | any,
    currentUsesToAddMessage: number[] | any,
    groupName?: string,
    errorsGroup?: any,
    nameIsEmpty: boolean,
    //groupNameIsEmpty: boolean,
    //Данные для сервиса чата
    filterConversation: FilterConversation,
    //Статус выполнения 
    isconversationChatServices: boolean,
    
}

type ChatProps = {
    
    //Выбранный чат
    selectConversationMobileService: Function,
    connection: Function,
    //Доступные участники
    getMembers: Function,
    members: Member[],
    chatMemberInProcess: boolean,
    chatMemberError: string,
    
    //Данные для сервиса чата
    conversationChatServices: ConversationChatService[],
    
    conversationInProcess: boolean,
    conversatioError: string,
    getConversationChat: Function
    conversationCreate: Function,
    //Получение для сервиса мобильного
    conversationMobileService: ConversationMobileService[],
    chatTitlesInProcess: boolean,
    chatTitlesError: string,
    getConversationMobile: Function,
    //Пользователь
    user: User;

    //Статус подключения к Hub
    isChatConnected: boolean

}

class Chat extends React.Component<ChatProps, ChatStateType> {
    constructor(props: any) {
        super(props);
        this.state = { 
            isMessage:false,
            isAddMessageModal:false,
            isAddMessageGroupModal: false,
            currentUserToAddMessage: '',
            currentUsesToAddMessage: [],
            groupName: '',
            errorsGroup: [],
            nameIsEmpty: false,
            //groupNameIsEmpty: false,
            filterConversation: {skip: 0,take: 20},
            isconversationChatServices:false

        };
    }
    componentDidMount()  {  
       this.props.connection();
       this.getConversationChat()
       this.props.getMembers()
       document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
    }

    getConversationChat(){
        this.props.getConversationChat(this.state.filterConversation)
    }
    
    ItemSelect = (memberUID: any) => {
       var member = this.props.members?.find((el)=>{
            if(el.memberUID?.toString() === memberUID.toString())
            {
                return el;  
            }
        }) 
        if(member === undefined){
            this.setState({
                currentUserToAddMessage: '',
                nameIsEmpty: true
            })
        }else{
            this.setState({
                currentUserToAddMessage: member.memberUID,
                nameIsEmpty: false
            })
        } 
    }
    
    // проверка формы смены пароля
    validationAddGroup = () => {
        let errorsGroup = [];

        if (!this.state.groupName) {
            errorsGroup.push('Не заполнена тема группы');
        } 
        if (this.state.currentUsesToAddMessage.light === 0) {
            errorsGroup.push('Не выбраны учасники');
        } 
        this.setState({
            ...this.state, errorsGroup: errorsGroup
        });

        return errorsGroup.length === 0;
    }
    createConversationGroup(){
        if (!this.validationAddGroup()) { 
            return;
        }else{

            if(this.state.currentUsesToAddMessage.length === 1){
                this.createConversation(this.state.currentUsesToAddMessage[0])
            } else{
                this.props.conversationCreate({'memberUIDs': this.state.currentUsesToAddMessage,'name': this.state.groupName})
                this.setState({
                    ...this.state,
                    isAddMessageGroupModal: false,
                    currentUserToAddMessage: '',
                    currentUsesToAddMessage: [],
                    groupName: ''
                })
            }
            
        }
       //this.setState({ ...this.state, });
    }

    

    createConversation(conversationUID: any){
        /*
        var test = this.props.conversationMobileService.filter((value)=> {
            return value.members.find((member)=> {
                 return member.uid?.toString().toLowerCase() === this.state.currentUserToAddMessage.toString().toLowerCase() 
                 ?this.state.currentUserToAddMessage.toString() && !value.isGroupChat && value.state === "Opened"
                 : null
             })
         })
        */
        if(this.props.conversationMobileService.filter((value)=> {
            return value.members.find((member)=> {
                 return member.uid?.toString().toLowerCase() === conversationUID.toString().toLowerCase() 
                 ?conversationUID.toString() && !value.isGroupChat && value.state === "Opened"
                 : null
             })
         }).length === 0){
            if(conversationUID !== ''){
                this.props.conversationCreate({'memberUIDs': [conversationUID.toString()],'name':'чат'});
                this.setState({
                    ...this.state,
                    isAddMessageModal: false,
                    isAddMessageGroupModal: false,
                    currentUserToAddMessage: '',
                    currentUsesToAddMessage: [],
                    groupName: '',
                }) 
            }else{
                this.setState({
                    ...this.state,
                    nameIsEmpty: true
                }) 
            }
        }else{
            var currentConversation = this.props.conversationMobileService.find((value)=> {
                return value.members.find((member)=> {
                     return member.uid?.toString().toLowerCase() === conversationUID.toString().toLowerCase() 
                     ?conversationUID.toString() && !value.isGroupChat && value.state === "Opened"
                     : null
                 })
            });
            this.props.selectConversationMobileService(currentConversation);
            this.setState({
                ...this.state,
                isAddMessageModal: false,
                isAddMessageGroupModal: false,
                isMessage: true,
                currentUserToAddMessage: '',
                currentUsesToAddMessage: [],
                groupName: ''
            }) 
        }   
    }
    render() {
        return (
            <div>
                {!this.props.isChatConnected && <div className='messanger page'> Чат временно не работает, попробуйте позже.</div>}
                <div>
                {(!this.state.isMessage) ? this.props.isChatConnected &&
                    <div className='messanger page'>
                        <div className='app-input-btn app-tho'>
                            <Button className='ui-button-background' onClick={() => {
                                this.setState({
                                    ...this.state,
                                    isAddMessageModal: true,
                                    nameIsEmpty: false
                                }) 
                            }} fluid>Добавить чат</Button>
                            {
                            (this.props.user.counselor)?
                                <Button className='ui-button-background' onClick={() => 
                                {
                                    this.setState({
                                        ...this.state,
                                        isAddMessageGroupModal: true,
                                        errorsGroup: []
                                    }) 
                                }} fluid>Создать группу</Button>
                                :''
                            }
                        <div 
                            style={{marginTop:'80px', alignItems:'center'}}
                            className={this.props.chatTitlesInProcess ? "loader-container" : "ui inverted dimmer"}> 
                        </div>
                        </div>                   
                        {   
                            _.map(this.props.conversationMobileService, (item: ConversationMobileService) =>
                                <RoomRow  selectConversation ={item}
                                onChat={(item: ConversationMobileService) => { 
                                this.props.selectConversationMobileService(item);
                                this.setState({
                                    ...this.state,
                                    isMessage: true,
                                });
                            }}
                            />)
                        }
                        
                        {this.props.conversationChatServices?.length === 0 && 
                        !this.props.conversationInProcess &&
                        !this.props.chatTitlesInProcess 
                        ? <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> 
                        : ''}  
                    </div>
                    :
                    <MessageCard 
                        backToList={()=>{
                            this.setState({
                                ...this.state,
                                isMessage: false
                                });
                            }
                        }
                        closeConversation = {() => {
                            //this.getConversationChat()
                            this.setState({
                                ...this.state,
                                isMessage: false
                                });
                            }
                        }/>
                }
                <Modal
                    closeIcon
                    open={this.state.isAddMessageModal}
                    dimmer={"blurring"}
                    centered={false}
                    onClose={() => {
                        this.setState({
                            ...this.state,
                            isAddMessageModal: false,
                            currentUserToAddMessage: '',
                        })  
                    }}
                >
                    <Modal.Content>
                        <Modal.Description>
                            <div className={"modal-header-text"}>
                                + Добавить чат
                            </div>
                            <br/><br/>
                            <div className={"modal-subject-text help-dialog"}>    
                                Выберите участника
                            </div>
                            <div style={{marginBottom: '20px'}}>
                                <select className='SelectBox' onChange={(event) => this.ItemSelect(event.target.value)}>
                                <option key={0} selected>{"Не выбрано"} </option>
                                {    
                                    this.props.members.map((th) => {
                                    return (this.state.currentUserToAddMessage === th.memberUID) ? 
                                    <option key={th.memberUID} value={th.memberUID} selected>{th.name} </option> : 
                                    <option key={th.memberUID} value={th.memberUID}>{th.name} </option>;
                                    })
                                }
                                </select>
                           </div>
                            <Button className='ui-button-background' onClick={() => {
                                this.createConversation(this.state.currentUserToAddMessage);
                              
                            }} fluid>Добавить чат</Button>
                            <br/>
                                {this.state.nameIsEmpty ?
                                    <div className={"modal-error-text"}>
                                        Не выбран учасник чата
                                    </div>
                                    : ""
                                }
                            <br/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                <Modal
                    closeIcon
                    open={this.state.isAddMessageGroupModal}
                    dimmer={"blurring"}
                    centered={false}
                    onClose={() => {    
                            this.setState({
                                ...this.state,
                                isAddMessageGroupModal: false,
                                currentUsesToAddMessage: [],
                                groupName: ''
                            })  
                    }}
                >
                    <Modal.Content>
                        <Modal.Description>
                            <div className={"modal-header-text"}>
                                + Добавить группу
                            </div>
                            <br/><br/>
                            <div className={"modal-subject-text help-dialog"}>    
                                Выберите участников
                            </div>
                            <div className='gift-dropdown'>
                                <Dropdown placeholder='Все'
                                    fluid
                                    multiple
                                    selection
                                    options={_.map(this.props.members, (item: any) => { return { key: item.name, text: item.name, value: item.memberUID } })}
                                    onChange={((event, data) => {
                                        this.setState({
                                            ...this.state,
                                            currentUsesToAddMessage: data.value
                                        });
                                    })} value={this.state.currentUsesToAddMessage} />
                            </div>
                            <div className={"modal-subject-text"}>
                                Название группы
                            </div>
                                <TextArea rows={4} className={"feedback-textarea"} placeholder='' onChange={(event, data) => this.setState({
                                        ...this.state,
                                        groupName: (data.value || '').toString(),
                                       // groupNameIsEmpty: false
                                    })} value={this.state.groupName}/>
                            <br/>
                                {((this.state.errorsGroup && this.state.errorsGroup.length > 0)) ?
                                <ul>
                                    { _.map(this.state.errorsGroup, (item: any) =>
                                            <li className={"error-class"}>{item}</li>)}
                                </ul>
                                :
                                ""
                            }
                            <br/>
                            <Button className='ui-button-background' onClick={() => {
                                this.createConversationGroup() 
                                }} fluid>Добавить чат</Button>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let auth: AuthStore = state.authentication;
    let chatStore: ChatStory = state.messeger;
    return {
        //Мобильный стейт
        conversationMobileService: chatStore.conversationMobileService,
        chatTitlesInProcess: chatStore.chatTitlesInProcess,   //Получение списков комнат, изменение, создние
        chatTitlesError: chatStore.chatTitlesError,
        
        //Возможные пользователи
        members: chatStore.members,
        chatMemberInProcess: chatStore.chatMemberInProcess,
        chatMemberError: chatStore.chatMemberError,

        //Чат стейт
        conversationChatServices: chatStore.conversationChatServices,
        conversationInProcess: chatStore.conversationInProcess,
        conversatioError: chatStore.conversatioError,
        user: auth.user ?? {}, 
        
        isChatConnected: chatStore.isChatConnected
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(chatActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);