import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Button, Icon, TextArea, Input} from 'semantic-ui-react'
import * as taskActions from "./../../store/task/actions"
import {refreshUserInfo} from "../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CamperTask, FileItem, TaskStore, TaskCategory,OfferTask} from "../../store/taskType";
import './Task.css';
import {CurrentConfig} from '../../api/config';
import {AuthStore} from "../../store/baseType";
import _ from "lodash";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";



type TaskOfferState = {
    /*Название задания*/
    boutTaskName?: string;
    /* комментарий */
    commentary?: string,
    /* стомость задания*/
    price?: string
    /*Одновременное колличество*/
    simultaneously?: string;
    /* категории заданий */
    taskCategoryId: number | any,
    /*Ошибки*/
    errors?: any
     /* файлы */
    files: any | undefined

    cardMode: boolean,

}

type TaskCardOfferProp = {
    beginTask: Function,
    cancelTask: Function,
    setOfferTask: Function,
    offerTask: OfferTask,
    typeInProcessOffer: boolean,
    taskCategoriesTP: TaskCategory[],
    errorInTaskOffer: string,
    selectedItem: CamperTask | undefined,
    isDoneOffer:boolean;
    uploadPhoto: Function,
    backToList: Function,
    files: FileItem[],
    counselor: boolean, 
    admin:boolean   
}


class TaskCardOffer extends React.Component<TaskCardOfferProp, TaskOfferState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;
    upload: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            taskCategoryId:'',
            cardMode: false,
            files: undefined,
        }
    }
    onButtonClick = () => {
        if (this.upload && this.upload.click) {
            this.upload.click();
        }
    };
    ItemSelect(value: number | null | undefined)
    {
        this.props.taskCategoriesTP?.forEach((el)=>{
            if(el.id === value)
            {
                this.setState({...this.state, taskCategoryId: el});
            }
        });
    }
    // проверка формы
    validationForm = () => {
        let errors = [];

        if ((this.state.boutTaskName === '')) {
            errors.push('не указано название');
        } 
        if ((this.state.commentary === '')) {
            errors.push('не указано описание');
        } 
        if ((Number(this.state.simultaneously) === 0 || this.state.simultaneously === undefined )) {
            errors.push('не указано количество повторений');
        }
        if ((Number(this.state.price) === 0 || this.state.price === undefined)) {
            errors.push('не указана цена');
        }
        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }
    offerTask(){
        if (!this.validationForm()) {
            return;
        }
        this.props.offerTask.name = this.state.boutTaskName;
        this.props.offerTask.description = this.state.commentary;
        this.props.offerTask.taskCategoryId = this.state.taskCategoryId?.id;
        this.props.offerTask.price =   Number(this.state.price);
        this.props.offerTask.countOnBout = Number(this.state.simultaneously);
        this.props.offerTask.boutTaskId = this.props.selectedItem?.boutTaskId;
        

        this.props.setOfferTask(this.props.offerTask);
    }
    componentDidMount() {
       
        this.setState({...this.state, 
            boutTaskName: this.props.selectedItem?.boutTask.name,
            commentary: this.props.selectedItem?.boutTask.description,
            price:  this.props.selectedItem?.boutTask.price.toString(),
            simultaneously:  this.props.selectedItem?.boutTask.countOnBout.toString()
        });

        this.props.taskCategoriesTP?.forEach((el)=>{
            if(el.id === this.props.selectedItem?.boutTask.taskCategoryId)
            {
                this.setState({...this.state, taskCategoryId: el});
            }
        });
        if(this.state.taskCategoryId === ''){
            this.setState({...this.state, taskCategoryId: this.props.taskCategoriesTP[0]});    
        }  
    }
    render() {
      
        return (
            <div>
                <div
                    className={this.props.typeInProcessOffer ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Загрузка...</div>
                </div>

                <div className="task-card-back-btn" onClick={() => {
                    this.setState({
                        ...this.state,
                    });

                    this.props.backToList();
                }}><Icon name="arrow left" />назад</div>

                <div style={{ margin: "8%" }}>
                    <img src={this.basePath + '/downloadFileByLink?id=' + this.props.selectedItem?.boutTask?.linkId}
                        className={"task-card-photo2"} alt="" />
                </div>

                <div className={"offertask-card-description2"}>
                    
                    <div className={"task-card-description-title2"}>{this.props.selectedItem?.boutTask?.name}</div>
                    <table className={"task-card-info"}>
                        <tbody>
                            <tr>
                                <td className={"task-card-label2"}>Название задания</td>
                            </tr>
                            
                            <tr>  
                               <td className='inputEdit'>
                                    <Input placeholder='Название задания' className="login-input task-date" 
                                        
                                        value={this.state.boutTaskName}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                        ...this.state,
                                        boutTaskName: data.value
                                    })}/>
                                </td>
                                <td>
                                    <i className="big blue edit outline icon iconOffer" style={{float: 'right'}}></i>
                               </td>
                            </tr>
                            <tr>
                                <td className={"task-card-label2"}>Тема задания</td>
                            </tr>
                            <tr>
                                <td className='inputEdit'>
                                    <select className='SelectBox' 
                                        disabled={this.props.admin}
                                        onChange={(value) => this.ItemSelect(this.props.selectedItem?.boutTask?.taskCategoryId)}>
                                        {   
                                            this.props.taskCategoriesTP?.map((th) => {
                                            return (this.props.selectedItem?.boutTask?.taskCategoryId === th.id) ? 
                                            <option key={th.id} selected>{th.name} </option> : 
                                            <option key={th.id}>{th.name} </option>;
                                            })
                                        }
                                    </select>
                                </td>
                                {(!this.props.admin)?
                                    <td>
                                        <i className="big blue edit outline icon iconOffer" style={{float: 'right'}}></i>
                                    </td>
                                    :
                                    ''
                                }
                                
                            </tr>
                            <tr>
                                <td className={"task-card-label2"}>Описание задания</td>
                            </tr>
                            <tr>
                                <td className='inputEdit'>
                                    <TextArea rows={7} className={"task-commentary"} 
                                    onChange={(event, data) => this.setState({
                                        ...this.state,
                                        commentary: (data.value || '').toString()
                                    })}
                                    value={this.state.commentary}/>
                                </td>
                                <td>
                                    <i className="big blue edit outline icon iconOffer" style={{float: 'right'}}></i>
                                </td>
                               
                            </tr>
                            <tr>
                                <td className={"task-card-label2"}>Стоимость</td>
                            </tr>   
                            <tr>
                                <td className='inputEdit'>
                                        <Input 
                                            placeholder='Стоимость' className="login-input"
                                            value={this.state.price}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                                ...this.state,
                                                price: formatPrice(data.value),      
                                        })}      
                                    />
                                </td>
                                <td>
                                    <i className="big blue edit outline icon iconOffer" style={{float: 'right'}}></i>
                                </td>
                               
                            </tr>
                            <tr>
                                <td className={"task-card-label2"}>Одновременное кол-во</td>
                            </tr>       
                            <tr>
                                <td className='inputEdit'>
                                    <Input
                                        placeholder='Одновременное кол-во' className="login-input"
                                        value={this.state.simultaneously}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                            ...this.state,
                                            simultaneously: formatPrice(data.value),
                                           
                                    })}/>
                                </td>
                                <td>
                                    <i className="big blue edit outline icon iconOffer" style={{float: 'right'}}></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        {(!this.props.isDoneOffer)?
                    
                        <Button className="submitTaskBtn" color={"violet"} fluid onClick={() => {
                            this.offerTask();
                        }}>Предложить изменение</Button>
                        :
                        ''
                        }
                       
                       
                        
                        {(this.state.errors?.length > 0 || this.props.errorInTaskOffer) ?
                                <ul>
                                    {this.state.errors.length > 0 ? _.map(this.state.errors, (item: any) => <li
                                        className={"error-class"}>{item}</li>) :
                                        
                                        this.props.errorInTaskOffer ?
                                            <li className={"error-class"}>{this.props.errorInTaskOffer}</li> : ""}
                                </ul>
                                : ""
                            }
                    </div>
               

            </div>
        );
    }
}

var validNumber = "";
function formatPrice(number: string) {
    if (/^\d+$/.test(number) && !number.startsWith('0') || number === ''){        
        validNumber = number;
    }
    return validNumber;
}

function mapStateToProps(state: any) {
    let taskStore: TaskStore = state.task;
    let authStore: AuthStore = state.authentication;

    return {
        counselor: authStore.counselor,
        admin: authStore.admin,
        selectedItem: taskStore.selectedItem,
        taskCategoriesTP: taskStore.taskCategoriesTTTS,
        files: taskStore.loadedPhotos,
        offerTask: taskStore.offerTask,
        typeInProcessOffer: taskStore.typeInProcessOffer,
        errorInTaskOffer: taskStore.errorInTaskOffer,
        isDoneOffer: taskStore.isDoneOffer
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
    ...bindActionCreators({refreshUserInfo}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCardOffer);