import axios from "axios";
import {CurrentConfig} from './config';


import {AuthInfo,ChangeInfo, RegisterInfo,Theme, AuthChat,TokenInfo} from "../store/baseType";

export const initAxios = (token: string | undefined) => {
    //axios.defaults.headers.common["Content-Type"] = "application/json";
    
    axios.defaults.headers.common["Content-Type"] = "application/json-patch+json" 
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "";
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    document.cookie = "aiso-info=" + token;
    let config = CurrentConfig.get();
}; 
let stringify = require('qs-stringify')

const AuthApi = { 
    //авторизация в чате 
    authChat: (body: AuthChat, callback: Function) => {
        const headers = new Headers();
        let config = CurrentConfig.get();
        headers.set("Cache-Control", "no-cache");
        headers.set("Content-Type", "application/x-www-form-urlencoded");
        headers.set("User-Agent", "React");
        headers.set("Accept", "*/*");
        headers.set("Accept-Encoding", "gzip, deflate, br");
        headers.set("Connection", "keep-alive");
        headers.set("Access-Control-Allow-Origin",`${config.Api}`);
       
        var bodyRes = stringify(body)
        
        const url = `${config.Api}/connect/token`;

        fetch(url, {method: "POST", headers, body: bodyRes}).then(
            response => {
                switch (response.status) {
                    case 401: {
                        callback(response.status, response.text());
                        break;
                    }
                    case 200: {
                        response.json().then(json => {  
                            initAxios(json.access_token); 
                            callback(response.status, json);
                        });
                        break;
                    }
                    default:
                        callback(response.status, response.text());
                        break;
                }
            },
            (d) => {
                callback(500, d);
        });
    },

   
    // авторизация пользователя
    /*
    auth: (body: AuthInfo, callback: Function) => {
        const headers = new Headers();
        headers.set("Content-Type", "application/json-patch+json");
        headers.set("Accept", "application/json");

        let url = `${config.Api}/auth/auth`;
        fetch(url, {method: "POST", headers, body: JSON.stringify(body)}).then(
            response => {
                switch (response.status) {
                    case 401: {
                        callback(response.status, response.text());
                        break;
                    }
                    case 200: {
                        response.json().then(json => {
                           // initAxios(json.token);
                            callback(response.status, json);
                        });
                        break;
                    }
                    default:
                        callback(response.status, response.text());
                        break;
                }
            },
            (d) => {
                callback(500, d);
            });
    },
    */
    auth: (callback: Function) => {
        let config = CurrentConfig.get();
        let url = `${config.Api}/auth/auth`;
        axios.post(url).then(
       // fetch(url, {method: "POST", headers, body: JSON.stringify(body)}).then(
        response => {
            callback(response.status, response.data);
        },
        (error) => {
            let response = error || {};
            response = response.response || {};
            callback(response.status, response.data);
        });
    },
    registerUser: (model: RegisterInfo, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/registerUser`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },

    updateUser: (model: RegisterInfo, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/updateUser`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },    

    refreshUserInfo: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/refreshUserInfo`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },    

    changePassword: (model: ChangeInfo, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/changePassword`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });

    },

    resetPasswordByEmail: (email: string, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/resetPasswordByEmail?email=${encodeURI(email)}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });

    },

    resetPasswordBySms: (email: string, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/resetPasswordBySms?email=${encodeURI(email)}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });

    },
    updateTheme: (model: Theme, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/theme/update`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });

    },
    userApproveRule: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/auth/approveRule`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
};

export default AuthApi;