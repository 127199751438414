export const TASK_LIST_COMPLITED_SUCCESS = "TASK_LIST_COMPLITED_SUCCESS";
export const TASK_LIST_COMPLITED_FAILED = "TASK_LIST_COMPLITED_FAILED";
export const TASK_LIST_COMPLITED_PROCESS = "TASK_LIST_COMPLITED_PROCESS";

export const TASK_COMPLITED_SUCCESS = "TASK_COMPLITED_SUCCESS";
export const TASK_COMPLITED_FAILED = "TASK_COMPLITED_FAILED";
export const TASK_COMPLITED_PROCESS = "TASK_COMPLITED_PROCESS";

export const LIKE_LIST_COMPLITED_SUCCESS = "LIKE_LIST_COMPLITED_SUCCESS";

export const LIKE_LIST_SUCCESS = "LIKE_LIST_SUCCESS";
export const LIKE_LIST_FAILED = "LIKE_LIST_FAILED";
export const LIKE_LIST_PROCESS = "LIKE_LIST_PROCESS";

export const NOTIFICATIONNEWS_LIST_SUCCESS = "NOTIFICATIONNEWS_LIST_SUCCESS";
export const NOTIFICATIONNEWS_LIST_FAILED = "NOTIFICATIONNEWS_LIST_FAILED";
export const NOTIFICATIONNEWS_LIST_PROCESS = "NOTIFICATIONNEWS_LIST_PROCESS";