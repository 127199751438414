import axios from "axios";
import {CurrentConfig} from './config';

const deniedWordsApi = {
    verify: (text: FormData, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/wordRestriction/verify`, text).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
}

export default deniedWordsApi;