import axios from "axios";
import { CurrentConfig } from './config';
import * as signalR from "@microsoft/signalr";
import { User, TokenInfo } from "../store/baseType";
import {
    ConversationMobileService,
    ChatStory,
    Member,
    ConversationChatService,
    FilterConversation,
    FilterMessages,
    CreateConversation,
    SendMessage,
    SendMessageFile
} from "./../store/chatType";
let tokenInfo: TokenInfo = JSON.parse(localStorage.getItem("token") || '{}') || {};

let hubConnection = new signalR.HubConnectionBuilder()
    //.withUrl(`${CurrentConfig.get().Chat}/chat-hub`, {
    .withUrl(`${process.env.REACT_APP_CHAT_URL}/chat-hub`, {
        accessTokenFactory: () => tokenInfo.access_token!
        , transport: signalR.HttpTransportType.LongPolling
    })
    .build();

const ChatApi = {
    //Получаем комнаты от сервера чатов
    getConversationChatService: (request: FilterConversation, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.post(`${basePath}/api/v1/conversations`, request).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            })
    },
    //Получаем комнаты от мобильного сервиса
    getConversationMobileService: (model: ConversationChatService[] | ConversationChatService, callback: Function) => {
        let basePath = CurrentConfig.get().Api;
        axios.post(`${basePath}/messager/conversations`,model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    //Получаем сообщения комнаты
    getConversationMessage: (model: FilterMessages, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.post(`${basePath}/api/v1/conversations/${model.conversationId}/messages`, model.filter)
            .then(
                response => {
                    callback(response.status, response.data);
                },
                (error) => {
                    let response = error || {};
                    response = response.response || {};
                    callback(response.status, response.data);
                });

    },
    //Сохранить картинку
     // загрузка файла
    uploadFile: (data: any, fileType: number, callback: Function) => {
        let basePath = CurrentConfig.get().Api;
        axios.post(`${basePath}/UploadChatFileSave?f=${fileType}`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    // Отправка сообщения с файлом
    sendMessageFile: (model: SendMessageFile, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.put(`${basePath}/api/v1/messages/media-message`, model).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    //Создать комнату
    createConversation: (model: CreateConversation, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.put(`${basePath}/api/v1/conversations`, model)
            .then(
                response => {
                    callback(response.status, response.data);
                },
                (error) => {
                    let response = error || {};
                    response = response.response || {};
                    callback(response.status, response.data);
                });

    },
    //Закрыть комнату
    closeConversation: (model: String, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.post(`${basePath}/api/v1/conversations/${model}/close`)
            .then(
                response => {
                    callback(response.status, response.data);
                },
                (error) => {
                    let response = error || {};
                    response = response.response || {};
                    callback(response.status, response.data);
                });

    },
    
    //Подключаем Hub сообщений
    connection: (callback: Function) => {
       if(hubConnection.state !== "Connected"){
            hubConnection.start()
            .then(() => {
                hubConnection.on("ReceiveMessage", data => {
                    callback(data);
                });   
            })
            .then(() => {
                callback(200);
            })
            .catch(() => {
                callback(401);
            });    
       }   
    },

    disconnect: (callback: Function) => {
        if(hubConnection.state === "Connected"){
             hubConnection.stop().then(data => { callback(data); });
        }else{

        }
       
    },
    sendMessage: (data: SendMessage, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.put(`${basePath}/api/v1/messages/text-message`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    sendMessageReaded: (data: string, callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.post(`${basePath}/api/v1/messages/${data}/mark-as-read`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },

    //Получаем список учасников
    getMembers: (callback: Function) => {
        let basePath = CurrentConfig.get().Api;
        axios.post(`${basePath}/messager/members`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    getCountUnreadChat: (callback: Function) => {
        let basePath = CurrentConfig.get().Chat;
        axios.get(`${basePath}/api/v1/conversations`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    }
}
export default ChatApi;
