import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import StartScreen from './screens/StartScreen/StartScreen';
import Login from './screens/Login/Login';
import NavBar from './components/UI/Menu';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {applyMiddleware, createStore} from "redux";
import rootReducer from "./store/rootReducer";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import AuthRoute from "./components/UI/AuthRoute";
import NoneAuthRoute from "./components/UI/NoneAuthRoute";
import Registration from "./screens/Login/Registration";
import RestorePassword from "./screens/Login/RestorePassword";
import Chat  from "./screens/Messager/Chat";
import Approving from "./screens/Approving/Approving";
import Main from "./screens/home/main";
import {CurrentConfig} from "./api/config"
import Helmet from "react-helmet";
import Task from "./screens/Task/List";
import Notification from "./screens/Notification/List";
import Gift from "./screens/Gift/List";
import WishedGift from "./screens/Gift/WishedList"
import Rating from "./screens/Rating/List";
import RatingCamper from "./screens/Rating/camper";
import SurvayQRCard from "./screens/FeedBack/SurveyQRCard";
import GiftReservedList from "./screens/Gift/ListReserved";
import UserManual from "./screens/UserManual/UserManual";
import FeedBackComp from "./screens/FeedBack/FeedBack";
import Support from "./screens/Support/Support";
import PersonalAccount from "./screens/PersonalAccount/PersonalAccount";
import HealthApi from "../src/api/health"
import { config } from 'process';
import { AppVersion } from "./AppVersion"
import { composeWithDevTools } from 'redux-devtools-extension';
import OfferList from './screens/Offer/OfferList';

function App() {
    CurrentConfig.init();
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    

    return (
        <div>
            <Provider store={store}>
                <Helmet>
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="viewport" content="width=device-width, user-scalable=no" />
                </Helmet>
                <Router>
                    <NavBar>
                        <Switch>
                            <NoneAuthRoute
                                path="/"
                                key="main"
                                exact
                                component={StartScreen}
                            />
                            <NoneAuthRoute
                                path="/registration"
                                key="registration"
                                exact
                                component={Registration}
                            />
                            <NoneAuthRoute
                                path="/restorePassword"
                                key="restorePassword"
                                exact
                                component={RestorePassword}
                            />
                            <NoneAuthRoute
                                path="/login"
                                key="login"
                                exact
                                component={Login}
                            />
                            <AuthRoute
                                path="/main"
                                key="main"
                                exact
                                component={Main}
                            />
                            <AuthRoute
                                path="/approving"
                                key="main"
                                exact
                                component={Approving}
                            />
                            <AuthRoute
                                path="/task"
                                key="main"
                                exact
                                component={Task}
                            />
                            <AuthRoute
                                path="/notification"
                                key="main"
                                exact
                                component={Notification}
                            />
                            <AuthRoute
                                path="/gift"
                                key="main"
                                exact
                                component={Gift}
                            />
                            <AuthRoute
                                path="/wished"
                                key="main"
                                exact
                                component={WishedGift}
                            />
                            <AuthRoute
                                path="/reserved"
                                key="main"
                                exact
                                component={GiftReservedList}
                            />
                            <AuthRoute
                                path="/rating"
                                key="main"
                                exact
                                component={Rating}
                            />
                            <AuthRoute
                                path="/ratingUser"
                                key="main"
                                exact
                                component={RatingCamper}
                            />
                            <AuthRoute
                                path="/manual"
                                key="main"
                                exact
                                component={UserManual}
                            />
                            <AuthRoute
                                path="/feedback"
                                key="main"
                                exact
                                component={FeedBackComp}
                            />
                             <AuthRoute
                                path="/survayQR"
                                key="main"
                                exact
                                component={SurvayQRCard}
                            />
                            <AuthRoute
                                path="/support"
                                key="main"
                                exact
                                component={Support}
                            />
                            <AuthRoute
                                path="/account"
                                key="main"
                                exact
                                component={PersonalAccount}
                            />
                            <AuthRoute
                                path="/chat"
                                key="main"
                                exact
                                component={Chat}
                            />
                            <AuthRoute
                                path="/offerList"
                                key="main"
                                exact
                                component={OfferList}
                            />
                            
                        </Switch>
                    </NavBar>
                </Router>
            </Provider>
        {/*    <AppVersion hidden={!CurrentConfig.currentConfig.DynamicUrl } />*/}
        </div>
    );
}

export default App;
