import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as taskActions from "./../../store/task/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CurrentConfig} from "../../api/config";
import {NotificationSend} from "../../store/notificationType";
import {Icon} from "semantic-ui-react";


type NotificationSendRowState = {}

type NotificationSendRowProp = {
    notificationSend: NotificationSend,
    onClick: Function
}

class NotificationSendRow extends React.Component<NotificationSendRowProp, NotificationSendRowState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
    }

    textSubstring(theme:String){
        if(theme.length>30){
            var substr = theme.substring(0,30);
            substr +=' ...';
            return substr;
        }else{
            return theme;
        }    
    }
    render() {
        return (
            <div className="ui segment ui-container" onClick={(e) => {
                if (e.button === 0) {
                    this.props.onClick(this.props.notificationSend)
                }
            }}>
                <span className={"notification-photo"}>
                    <Icon name={ "bell outline"}/>
                </span>
                <div className={"notification-body"}>
                    <div className={"notification-name"}>
                        {this.props.notificationSend.theme}
                    </div>
                    <div className={"notification-row-label"}>
                        Тема
                    </div>
                    <div>
                        {this.textSubstring(this.props.notificationSend.text)}
                    </div>
                    <div className={"notification-row-label"}>
                        Дата
                    </div>
                    <div>
                        {this.props.notificationSend.sendDate}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {}
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSendRow);