import {
    FEEDBACK_LIST_FAILED,
    FEEDBACK_LIST_SUCCESS,
    FEEDBACK_LIST_PROCESS,
    FEEDBACK_TITLES_LIST_FAILED,
    FEEDBACK_TITLES_LIST_SUCCESS,
    FEEDBACK_TITLES_LIST_PROCESS,
    SURVEYQR_TITLES_LIST_SUCCESS,
    FEEDBACK_SEND_SUCCESS,
    FEEDBACK_SEND_PROCESS,
    FEEDBACK_SEND_FAILED,
    FEEDBACK_SET_REDIRECT_FROM_SUPPORT,
    FEEDBACK_CLEAR_REDIRECT_FROM_SUPPORT,
 //   FEEDBACK_ADD_SUPPORT_TO_TITLES,
    FEEDBACK_SELECT,
    SURVEY_TITLES_LIST_SUCCESS,
    SURVEY_TITLES_LIST_FAILED,
    SURVEY_TITLES_LIST_PROCESS,

    SURVEY_SET_SUCCESS,
    SURVEY_SET_FAILED,
    SURVEY_SET_PROCESS,
    
    SURVEYQR_SET_SUCCESS,
    SURVEYQR_SET_FAILED,
    SURVEYQR_SET_PROCESS,

    SURVEY_GET_SUCCESS,
    SURVEY_GET_FAILED,
    SURVEY_GET_PROCESS,
    SURVAY_SELECT,
    SURVAY_REDIRECT_FROM_MAIN_FALSE
} from "./types";
import {clearReduxStateInternal} from '../authentication/actions';
import feedbackApi from "../../api/feedback";
import surveyApi from "../../api/survey";
import {Feedback, FeedbackTitle,Survay,HandlingFilter} from "../feedbackType";


// получение списка обращений
export const getFeedbacksList = (filter: HandlingFilter) => (dispatch: any) => {
    feedbackApi.getFeedbacksList(filter,(status: number, data: Feedback[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: FEEDBACK_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: FEEDBACK_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка обращений'
            });
        }
    });

    return dispatch({
        type: FEEDBACK_LIST_PROCESS
    });
}


// отправить сообщение
export const sendFeedback = (data: any) => (dispatch: any) => {
    feedbackApi.sendFeedback(data, (status: number, data: Feedback | undefined) => {
        if (status === 200) {
            dispatch({
                type: FEEDBACK_SEND_SUCCESS,
                data: data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: FEEDBACK_SEND_FAILED,
                errorMessage: data || 'Ошибка отправки обращения'
            });
        }
    });

    return dispatch({
        type: FEEDBACK_SEND_PROCESS,
    });
};

// получение списка тем обращения
export const getFeedbackTitles = (onlySupport:boolean = false) => (dispatch: any) => {
    feedbackApi.getFeedbackTitles(onlySupport, (status: number, data: FeedbackTitle[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: FEEDBACK_TITLES_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: FEEDBACK_TITLES_LIST_FAILED,
                errorMessage: data || 'Ошибка получения тем обращения'
            });
        }
    });

    return dispatch({
        type: FEEDBACK_TITLES_LIST_PROCESS
    });
}


// установить признак ректиректа из технической поддержки
export const setRedirectFromSupport = () => (dispatch: any) => {
    return dispatch({type: FEEDBACK_SET_REDIRECT_FROM_SUPPORT});
}

// сбросить признак ректиректа из технической поддержки
export const clearRedirectFromSupport = () => (dispatch: any) => {
    return dispatch({type: FEEDBACK_CLEAR_REDIRECT_FROM_SUPPORT});
}

// сбросить признак ректиректа из технической поддержки
//export const addSupportToFeedbackTitles = () => (dispatch: any) => {
//    return dispatch({type: FEEDBACK_ADD_SUPPORT_TO_TITLES});
//}


// получение списка заданий опросов
export const getSurveyListTitles = (filter: HandlingFilter ,id: number) => (dispatch: any) => {
    surveyApi.getSurveyList(filter,id,(status: number, data: Survay[] | string | undefined) => {
        if (status === 200) {
            if(id === 2){
                dispatch({
                    type: SURVEY_TITLES_LIST_SUCCESS,
                    data        
                });
            }
            if(id === 1){
                dispatch({
                    type: SURVEYQR_TITLES_LIST_SUCCESS,
                    data        
                });
            }
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: SURVEY_TITLES_LIST_FAILED,
                errorMessage: data || 'Ошибка получения заданий опросов'
            });
        }
    });

    return dispatch({
        type: SURVEY_TITLES_LIST_PROCESS
    });
}

// выполнение задания опроса по QRCode
export const surveyQRCode = (data: any) => (dispatch: any) => {
    surveyApi.surveyQRCode(data,(status: number, data: Survay | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: SURVEYQR_SET_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: SURVEYQR_SET_FAILED,
                errorMessage: data || 'Ошибка выполнения опроса'
            });
        }
    });

    return dispatch({
        type: SURVEYQR_SET_PROCESS
    });
}
// выполнение задания опроса
export const setSurvey = (data: any) => (dispatch: any) => {
    surveyApi.setSurvey(data,(status: number, data: Survay | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: SURVEY_SET_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: SURVEY_SET_FAILED,
                errorMessage: data || 'Ошибка выполнения опроса'
            });
        }
    });

    return dispatch({
        type: SURVEY_SET_PROCESS
    });
}
// Получить опрос по QR Коду
export const getSurvey = (id: number) => (dispatch: any) => {
    surveyApi.getSurvey(id,(status: number, data: Survay | string ) => {
        if (status === 200) {
            dispatch({
                type: SURVEY_GET_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            let result = dispatch({
                type: SURVEY_GET_FAILED,
                errorMessage: data || 'Ошибка выполнения опроса'
            });
            return result;
        }
    });

    return dispatch({
        type: SURVEY_GET_PROCESS
    });
}
export const denieRedirectFromMain = () => (dispatch: any) => {
    return dispatch({
        type: SURVAY_REDIRECT_FROM_MAIN_FALSE
    });
}


/// выбрать задачу
export const selectSurvey = (model: Survay) => (dispatch: any) => {
    return dispatch({
        type: SURVAY_SELECT,
        data: model
    });
}
/// выбрать ситуативное сообщение
export const selectFeedBack = (model: Feedback) => (dispatch: any) => {
    return dispatch({
        type: FEEDBACK_SELECT,
        data: model
    });
}
