import {
    OFFER_LIST_SUCCESS,
    OFFER_LIST_FAILED, 
    OFFER_LIST_PROCESS,

    OFFER_TYPE_SUCCESS,
    OFFER_TYPE_FAILED,
    OFFER_TYPE_PROCESS,

    OFFER_READ_SUCCESS,
    OFFER_READ_FAILED,
    OFFER_READ_PROCESS
    
} from "./types";

import {Reducer} from "redux";
import {Offer, OfferStore, OfferType} from "../offerType";

const initialState = () => {
    return {
        offers: [] as Offer[],
        offerTypes: [] as OfferType[],
    } as OfferStore;
};

const offerStore: Reducer = (state: OfferStore = initialState(), action: any) => {
    switch (action.type) {
        //case OFFER_LIST_FAILED: {
        //    return {
        //        ...state,
        //        errorInList: action.errorMessage,
        //        listInProcessRating: false
        //    };
        //}
        case OFFER_LIST_SUCCESS: {
            return {
                ...state,
                inProcess: false,
                offers: action.data
            };
        }
        case OFFER_LIST_PROCESS: {
            return {
                ...state,
                inProcess: true,
                offers: [] as Offer[],
            };
        }
        case OFFER_TYPE_SUCCESS: {
            return {
                ...state,
                offerTypes: action.data
            };
        }
        case OFFER_READ_FAILED: {
            return {
                ...state,
                inProcess: false,
                
            };
        }
        case OFFER_READ_SUCCESS: {
            return {
                ...state,
                inProcess: false,
                offers: state.offers.map(item => {
                    if (item.id === action.data) {
                        item.notRead = false;
                        return item;
                    }else{
                        return item;
                    }
                    
                }),
            };
        }
        case OFFER_READ_PROCESS: {
            return {
                ...state,
                inProcess: true,
            };
        }
        default:
            return state;
    }
};

export default offerStore;