import {combineReducers, ReducersMapObject} from "redux";
import authentication from "./authentication/reducers";
import approving from "./approving/reducers";
import task from "./task/reducers";
import feedback from "./feedback/reducers";
import offer from "./offer/reducers";
import notification from "./notification/reducers";
import rating from "./rating/reducers";
import gift from "./gift/reducers";
import home from "./home/reducers";
import messeger from "./chat/reducers";
import webPush from "./webpush/reducers";

import {CLEAR_REDUX_STATE} from './authentication/types'

const reducers: ReducersMapObject = {
    authentication,
    approving,
    task,
    feedback,
    notification,
    rating,
    gift,
    home,
    offer,
    messeger,
    webPush
};

const appReducer = combineReducers<any>(reducers);

const main = ((state: any | undefined, action: any) => {
    if (action.type === CLEAR_REDUX_STATE) {
        state = undefined;
    }
    
    return appReducer(state, action);
});

export default main;