import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import {Button, Checkbox, Header, Icon, Input, Modal} from 'semantic-ui-react'
import * as authActions from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { AuthStore, User, Theme, RegisterInfo } from "../../store/baseType";
import _, { values } from "lodash";
import '../../components/main.css';
import PasswordInputChange from "../../components/UI/PasswordInputChange";
import QRcode from 'qrcode.react'
import icon_qr from './../../images/icon_qr.png';
import './personalAccount.css';

import avatarError from './../../images/avatarError.png';
import notification from './../../images/notification.png';
import { CurrentConfig } from '../../api/config';
import { MarkableTextArea } from '../../components/UI/MarkableTextArea/MarkableTextArea';
import { Highlight } from 'react-highlight-within-textarea';
import {delDevice, regDevice} from "../../store/webpush/actions";
import {WebPushDevice} from "../../store/webPushType";
type PersonalAccountState = {
    id?: number,
    file?: string,
    pass: string,
    passConfirm: string,
    oldPass: string,
    needChangePassword?: boolean,
    errors?: any
    nickName?: string,
    phone?: string,
    email?: string,
    editMode?: boolean,
    showKeyQrCode: boolean,
    theme?: Theme,
    saveButtonDisabled: boolean,

    nickNameDeniedCount?: number,
    emailDeniedCount?: number,
    notificationPerm: boolean
}

type PersonalAccountProp = {
    auth?: boolean;
    saveRequested: boolean;
    counselor?: boolean;
    authIoProcess?: boolean;
    userName?: string;
    email?: string;
    photoUserUrl?: string;
    user: User;
    changePassword: Function,
    uploadUserPhoto: Function,
    updateTheme: Function,
    refreshUserInfo: Function,
    updateUser: Function,
    errorMessage?: string,
    saveErrorMessage?: string,
    regDevice: Function,
    delDevice: Function,
}

class PersonalAccount extends React.Component<PersonalAccountProp, PersonalAccountState> {
    upload: HTMLInputElement | null = null;
    interval: any = 10;
    selectedOption: number = 0;
    basePath: string = CurrentConfig.get().Api;
    constructor(props: any) {
        super(props);
        this.state = {
            file: undefined,
            editMode: false,
            pass: '',
            passConfirm: '',
            oldPass: '',
            showKeyQrCode: false,
            saveButtonDisabled: true,
            notificationPerm: Notification.permission === "granted" && this.props.user.receiveNotifications,
        }
    }

    updateDisableButton() {
        this.setState({ saveButtonDisabled: (this.state.nickNameDeniedCount ?? 1) + (this.state.emailDeniedCount ?? 1) > 0 })
    }

    componentDidMount() {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        this.props.refreshUserInfo();
    }

    onButtonClick = () => {
        if (this.upload && this.upload.click) {
            this.upload.click();
        }
    };

    formatPhoneNumber = (str: string) => {
        let cleaned = ('' + str).replace(/\D/g, '');

        let match = cleaned.match(/^(1|2|3|4|5|6|7|8|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            let intlCode = (match[1] ? '+7 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')
        }

        return null;
    }

    fileChangedHandler = (event: any) => {
        this.forceUpdate();
    }

    // проверка формы смены пароля
    validationChangepassword = () => {
        let errors = [];

        if (!(this.state.pass)) {
            errors.push('Не указан новый пароль');
        } else if (this.state.pass !== this.state.passConfirm) {
            errors.push('Пароль не совпадает с его подтверждением');
        }
        if (!(this.state.pass.length >= Math.min(6, 9) && this.state.pass.length <= Math.max(6, 9))) 
        {
            errors.push('Длина пароля должна быть от 6 до 9 символов');
        }
        /*
        if (!this.state.pass.match(/[0-9]+/)) {
            errors.push('Пароль не содержит символы');
        }
        */
        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }
    //Проверка формы редактирования 
    validationSave = () => {
        let errors = [];

        if (!(this.state.phone?.match(/^[+]{1}[0-9]{11}$/))) {
            errors.push('Не указан корректный номер телефона');
        }
        this.setState({
            ...this.state, errors: errors
        });

        return errors.length === 0;
    }


    // смена пароля
    handleChangePassword = () => {
        if (!this.validationChangepassword()) {
            return;
        }

       this.props.changePassword({ id: this.props.user.id, pass: this.state.pass, oldpass: this.state.oldPass });
    };

    // сохранение
    handleSave = () => {
        if (!this.validationSave()) {
            return;
        }

        if (this.upload && this.upload.files && this.upload.files.length > 0) {
            const data = new FormData()
            data.append('file', this.upload.files[0]);


            this.props.uploadUserPhoto(data);
        }
        this.props.updateUser({ nickName: this.state.nickName, phone: this.state.phone, themeId: this.state.theme?.id, email: this.state.email } as RegisterInfo);
        this.props.refreshUserInfo();
        this.upload = null;
    };


    componentDidUpdate(prevProps: Readonly<PersonalAccountProp>, prevState: Readonly<PersonalAccountState>, snapshot?: any) {
        document.body.style.backgroundColor = JSON.parse(this.props.user.theme.jsonTheme).fon.backgroundColor;
        if (prevProps.saveRequested && !this.props.saveRequested && !this.props.saveErrorMessage) {
            this.setState({ ...this.state, editMode: false });
        }

        if (prevProps.authIoProcess && !this.props.authIoProcess && !this.props.errorMessage) {
            this.setState({ ...this.state, needChangePassword: false });
        }

        if (prevProps.authIoProcess && !this.props.authIoProcess && this.props.errorMessage) {
            this.setState({ ...this.state, errors: [this.props.errorMessage] });
        }
    }

    ItemSelect(value: string) {
        this.props.user.themes?.forEach((el) => {
            if (el.name === value) {
                this.setState({ ...this.state, theme: el });
            }
        })
    }

    regServicesWorker() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(process.env.PUBLIC_URL + "/ServiceWorker.js")
                .then((reg) => {
                    if (Notification.permission === "granted") {
                        console.log("Notification.permission === \"granted\"");
                        this.getSubscription(reg);
                        return true;
                    } else if (Notification.permission === "denied") {
                        console.log("Notification.permission === \"blocked\"");
                        return false;
                    } else {
                        console.log(" } else {");
                        this.requestNotificationAccess(reg);
                        return true;
                    }
                });
        } else {
            console.log("Push Notifications not support!")
            return false;
        }
    }

    requestNotificationAccess(reg: any) {
        Notification.requestPermission( (status)=> {
            console.log("Try have permission!")
            if (status === "granted") {
                this.getSubscription(reg);
            } else {
                console.log("Push Notifications not support!")
            }
        });
    }
    getSubscription(reg: any) {
        reg.pushManager.getSubscription().then( (sub: any) => {
            console.log(sub);
            if (sub === null) {
                reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BK6bgssfRNMO_XSPMU0Ok2Iw9D_x3qUJXgZREp5dEwJentLC3VQ6mY5Ik1KtI1oCOu__QjM63qUf9YCsJQo4pV8"
                }).then( (sub: any) => {
                    console.log("2323");
                    this.fillSubscribeFields(sub);
                }).catch(function (e: any) {
                    console.error("Unable to subscribe to push", e);
                });
            } else {
                this.fillSubscribeFields(sub);
            }
        });
        console.log(reg);
    }
    fillSubscribeFields = (sub: any)=> {
        console.log("fillSubscribeFields");
        this.props.regDevice({UserUID: this.props.user.uid,
            PushEndpoint:sub.endpoint,
            PushAuth:this.arrayBufferToBase64(sub.getKey("auth")),
            PushP256DH:this.arrayBufferToBase64(sub.getKey("p256dh"))} as WebPushDevice);
        console.log(this.props.user.uid);
        console.log(sub.endpoint);
        console.log(this.arrayBufferToBase64(sub.getKey("auth")));
        console.log(this.arrayBufferToBase64(sub.getKey("p256dh")));
    }

    arrayBufferToBase64(buffer: any) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    unRegServicesWorker() {

        this.props.delDevice();
            this.setState({
                ...this.state,
                notificationPerm: false
            })

        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.ready.then(registration => {
        //         registration.unregister().then( value => console.log(value));
        //
        //     });
        //     console.log("unRegServicesWorker");
        // }
    }

    render() {
        return (
            <div>
                <div className={this.props.saveRequested || this.props.authIoProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                    <div className="ui text loader">Сохранение...</div>
                </div>
                {(!this.props.user.bout && !this.props.counselor) ?
                    <div>
                        <div className="greetingMsg-Account row-salute">
                            <div className='column-salute-icon'>
                                <img alt="QR-код" src={notification} height='32px' width='32px' />
                            </div>

                            <div className='column-salute-text'>
                                Поздравляем, вы успешно зарегистрировались! Покажите личный код учетной записи вожатому для подтверждения.
                            </div>
                        </div>
                        <div className='App-Heder-salute' />
                    </div>

                    :
                    <div className="App-Heder-Account">

                    </div>
                }

                <div className="head-acc-desc">
                    <div className="column-desc">
                        <div className="centered">
                            <div>

                                <div className="avatar">
                                    <img 
                                        src={(this.upload?.files) && (this.upload.files.length > 0) ? URL.createObjectURL(this.upload.files[0])
                                            : (this.props.user.linkId == null) ? avatarError : this.basePath + '/downloadFileByLink?id=' + this.props.user.linkId + '&' + new Date()}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = avatarError;
                                            //this.props.user.linkId = 0;
                                        }}
                                        className="photo-property-account"
                                        style={(!this.props.user.bout && !this.props.counselor) ? { top: "140px" } : { top: "100px" }}
                                        alt="">
                                    </img>
                                    {this.state.editMode ? <div className="photo-add-account"
                                        onClick={this.onButtonClick}>
                                        <i className="plus icon" />
                                        <input type="file" ref={((ref) => this.upload = ref)} onChange={this.fileChangedHandler}
                                            style={{ display: "none" }} />
                                    </div> : ""}

                                </div>
                            </div>
                            {/*<div className="acc-key-header"><p>Код учётной записи</p><p>{this.props.user.accountKey}</p></div>*/}
                        </div>

                    </div>

                    {/*<div className="column-desc">*/}
                    {/*    <div className="cloud-info">*/}
                    {/*        <h3>{ this.props.user.totalPoints }</h3>*/}
                    {/*        <p>всего заработано баллов</p>*/}
                    {/*    </div>*/}
                    {/*    <div className="cloud-info">*/}
                    {/*        <h3>{this.props.user.totalPointsPerShift}</h3>*/}
                    {/*        <p>всего баллов за смену</p>*/}
                    {/*    </div>*/}
                    {/*    <div className="cloud-info">*/}
                    {/*        <h3>{this.props.user.countTask}</h3>*/}
                    {/*        <p>заданий выполнено</p>*/}
                    {/*    </div>*/}
                    {/*    <div className="cloud-info">*/}
                    {/*        <h3>{this.props.user.points}</h3>*/}
                    {/*        <p>баллов на счету</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className='row-discription'>
                    <div className="column-desc">
                        {this.props.user.nickName && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <div className="column-discription">
                                <div className="heder-Account">Псевдоним</div>
                                <div className="discription-Account">
                                    {this.props.user.nickName}
                                </div>
                            </div>
                        </div> : ""}

                        {this.props.user.name && this.state.needChangePassword !== true && this.state.editMode !== true ?
                            <div >
                                <div className="column-discription">
                                    <div className="heder-Account">ФИО</div>
                                    <div className="discription-Account">
                                        {this.props.user.name}
                                    </div>
                                </div>
                            </div> : ""}

                        {this.props.user.dateOfBirth && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <div className="column-discription">
                                <div className="heder-Account">Дата рождения</div>
                                <div className="discription-Account">
                                    {this.props.user.dateOfBirth}
                                </div>
                            </div>
                        </div> : ""}

                        {this.props.user.accountKey && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <div className="column-discription">
                                <div className="heder-Account">Код учётной записи</div>
                                <div className="qr-code">
                                    <div className="discription-Account">
                                        {this.props.user.accountKey.toString()}
                                    </div>
                                    <Modal className="qr-modal"
                                        closeIcon ={false}
                                        basic
                                        onClose={() => this.setState({ showKeyQrCode: false })}
                                        onOpen={() => this.setState({ showKeyQrCode: true })}
                                        open={this.state.showKeyQrCode}
                                        size='large'
                                        trigger={<Icon className="qr-btn" name="qrcode" size="big" />}
                                       
                                        >
                                            
                                        <Modal.Content>
                                            <div className='qr-modal-content'>
                                                <Icon className="close-icon red-icon qr-model-close" name="close" style={{ fontSize: '30px' }}  
                                                onClick={() => {
                                                    this.setState({ showKeyQrCode: false });
                                                }} />
                                                {this.props.user.accountKey 
                                                ?   <QRcode 
                                                    id="keyqr"
                                                    value={this.props.user.accountKey}
                                                    size={320}
                                                    includeMargin={true}
                                                />
                                                :   <p>No QR code preview</p>}
                                               
                                            </div>
                                        </Modal.Content>
                                    </Modal>
                                </div>
                            </div>
                        </div> : ""}

                        {this.props.user.email && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <br/><br/>
                            <div className="column-discription">
                                <div className="heder-Account">Электронная почта</div>
                                <div className="discription-Account">
                                    {/*this.props.user.email.length > 20 ? this.props.user.email.substring(0, 20)+".." : this.props.user.email*/}
                                    {this.props.user.email}
                                </div>
                            </div>
                        </div> : ""}

                    </div>

                    <div className="column-desc">

                        {this.state.needChangePassword !== true && this.props.user.phone && this.state.editMode !== true ?
                            <div className="column-discription">
                                <div className="heder-Account">Телефон</div>
                                <div className="discription-Account">
                                    {this.formatPhoneNumber(this.props.user.phone)?.toString()}
                                </div>
                            </div> : ""}
                        {this.props.user.bout && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <div className="column-discription">
                                <div className="heder-Account">Лагерь</div>
                                <div className="discription-Account">
                                    {this.props.user.camp}
                                </div>
                            </div>
                        </div> : ""}
                        {this.props.user.bout && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <div className="column-discription">
                                <div className="heder-Account">Смена</div>
                                <div className="discription-Account">
                                    {this.props.user.bout}
                                </div>
                            </div>
                        </div> : ""}
                        {this.props.user.party && this.state.needChangePassword !== true && this.state.editMode !== true ? <div >
                            <div className="column-discription">
                                <div className="heder-Account">
                                    Отряд
                                </div>
                                <div className='discription-Account'>
                                    {this.props.user.party}
                                </div>
                            </div>
                        </div> : ""}

                    </div>

                </div>

                <div className="ui items lk-edit">


                    {this.state.editMode ? <div className={"content content-format"}>


                        <div>&nbsp;</div>
                        <div>Псевдоним:</div>
                        <MarkableTextArea
                            icon="user"
                            className="login-area"
                            text={this.state.nickName ?? ""}
                            type="area"
                            verifyDelay={500}
                            onChange={(value: any) => {
                                this.setState({ nickName: value, saveButtonDisabled: true });
                            }}
                            onRangesUpdated={(ranges: []) => {
                                this.setState({ nickNameDeniedCount: ranges.length });
                                this.updateDisableButton();
                            }}
                        />

                        <div>Телефон:</div>
                        <Input icon='phone' iconPosition='left' placeholder='Телефон' className="login-input"
                            value={this.state.phone}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({
                                ...this.state,
                                phone: formatPhone(data.value)
                            })} />
                        <div>Электронная почта:</div>
                        <MarkableTextArea
                            icon="mail"
                            className="login-area"
                            text={this.state.email ?? ""}
                            type="area"
                            verifyDelay={500}
                            onChange={(value: any) => {
                                this.setState({ email: value, saveButtonDisabled: true });
                            }}
                            onRangesUpdated={(ranges: []) => {
                                this.setState({ emailDeniedCount: ranges.length });
                                this.updateDisableButton();
                            }}
                        />
                        <div>
                            <div className="description">Темы приложения</div>
                            <select className='SelectBox' onChange={(value) => this.ItemSelect(value.target.value)}>
                                {
                                    this.props.user.themes?.map((th) => {
                                        return (this.props.user.theme?.id === th.id) ?
                                            <option key={th.id} selected>{th.name} </option> :
                                            <option key={th.id}>{th.name} </option>;
                                    })
                                }
                            </select>
                        </div>

                        <div className="item checkbox-block">

                            {this.props.saveErrorMessage ? <label style={{ color: "red" }}>{this.props.saveErrorMessage}</label> : ""}

                            <Button disabled={this.state.saveButtonDisabled} style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive} fluid={true} color={"orange"} type="button"
                                onClick={this.handleSave}>Сохранить</Button>
                        </div>
                        <div className="item checkbox-block">
                            <Button style={JSON.parse(this.props.user.theme.jsonTheme).buttonCencel} fluid={true} type="button"
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        needChangePassword: false,
                                        editMode: false
                                    })
                                    this.upload = null;
                                }}>Отмена</Button>
                        </div>
                        {(this.state.errors?.length > 0 || this.props.errorMessage) ?
                            <ul>
                                {this.state.errors.length > 0 ? _.map(this.state.errors, (item: any) => <li
                                    className={"error-class"}>{item}</li>) :

                                    this.props.errorMessage ?
                                        <li className={"error-class"}>{this.props.errorMessage}</li> : ""}
                            </ul>
                            : ""
                        }
                    </div> : ""
                    }
                    {this.state.editMode !== true && this.state.needChangePassword !== true ?
                        <div className="item checkbox-block">
                            <div className="content content-format">
                                <Checkbox onChange={(event, data) => {
                                    if (data.checked) {
                                        if (this.regServicesWorker()){
                                            this.setState({
                                                ...this.state,
                                                notificationPerm: true
                                            })
                                        }
                                    } else {this.unRegServicesWorker();}
                                }}
                                          className={"content-format"}
                                          label={"Получать уведомления"} slider={true} toggle={true}
                                          checked={this.state.notificationPerm}/>

                                <Button fluid={true} color={"olive"} type="button" style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        editMode: true,
                                        phone: this.props.user.phone,
                                        nickName: this.props.user.nickName,
                                        email: this.props.email,
                                        theme: this.props.user.theme,
                                        errors: ''
                                    });
                                    this.upload = null;
                                }}>Редактировать профиль</Button>
                            </div>
                        </div> : ""}
                    {this.state.needChangePassword === true ?
                        <div className="item checkbox-block">
                            &nbsp;
                        </div> : ""}
                    {this.state.needChangePassword !== true && this.state.editMode !== true ?
                        <div className="item checkbox-block">
                            <div className="content content-format">
                                <Button style={JSON.parse(this.props.user.theme.jsonTheme).buttonCencel} fluid={true} color={"orange"} type="button" onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        needChangePassword: true,
                                        pass: '',
                                        passConfirm: '',
                                        errors: []
                                    })
                                }}>Сменить пароль</Button>
                            </div>
                        </div> : ""}
                    {this.state.needChangePassword === true ? <div >
                        <div className="content content-format">
                            <PasswordInputChange oldPassword={this.state.oldPass} password={this.state.pass} confirm={this.state.passConfirm}
                                changeOldPassword={(v: string) => {
                                    this.setState({
                                        ...this.state,
                                        oldPass: v

                                    });
                                }}

                                changePassword={(v: string) => {
                                    this.setState({
                                        ...this.state,
                                        pass: v

                                    });
                                }}
                                changeConfirm={(v: string) => {
                                    this.setState({
                                        ...this.state,
                                        passConfirm: v
                                    });
                                }}

                            />
                            <div className={"item checkbox-block"}>
                                <div className={"ui items"}>
                                    <Button style={JSON.parse(this.props.user.theme.jsonTheme).buttonActive} fluid={true} color={"orange"} type="button"
                                        onClick={this.handleChangePassword}>Сменить
                                        пароль</Button>
                                </div>
                            </div>
                            <div className={"item checkbox-block"}>
                                <Button style={JSON.parse(this.props.user.theme.jsonTheme).buttonCencel} fluid={true} type="button"
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            needChangePassword: false,
                                            editMode: false
                                        })
                                    }}>Отмена</Button>
                            </div>
                            {((this.state.errors && this.state.errors.length > 0)) ?
                                <ul>
                                    {_.map(this.state.errors, (item: any) =>
                                        <li className={"error-class"}>{item}</li>)}
                                </ul>
                                :
                                ""
                            }
                        </div>
                    </div> : ""}
                </div>
            </div>
        );
    }





}

function mapStateToProps(state: any) {
    let auth: AuthStore = state.authentication;
    return {
        auth: auth.isAuthUser,
        userName: auth.user ? auth.user.nickName || '' : '',
        user: auth.user ?? {},
        email: auth.user?.email,
        counselor: auth.counselor,
        errorMessage: auth.errorMessage,
        saveRequested: auth.saveRequested,
        authIoProcess: auth.authIoProcess,
        saveErrorMessage: auth.saveErrorMessage,
        photoUserUrl: auth.user && auth.user.photo ? auth.user.photo : undefined,
        themrs: auth.user?.themes
      
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch),
    ...bindActionCreators({regDevice, delDevice}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAccount)

var validPhone = "+7";
function formatPhone(phone: string) {
    if (phone.match(/^[+]{1}[0-9]+$/) && phone.length <= 12) validPhone = phone;
    return validPhone;
}

function requestNotificationAccess2() {
    Notification.requestPermission(function (status) {
        console.log("Try have permission!")
        if (status === "granted") {
            console.log("Push Notifications support!");

        } else {
            console.log("Push Notifications not support!");
        }
    });
}
