import React from 'react';
import {CurrentConfig} from '../../api/config';
import {TaskListFilterComplite, TaskStoreMenu,TaskComplited} from "../../store/homeType";
import * as taskComplitedActions from "./../../store/home/actions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {clearListStateClear, refreshUserInfo} from "../../store/authentication/actions"
import './EventCard.css';
import '../../components/main.css';
import {AuthStore, User} from "../../store/baseType";
import EventCard from "./eventCard";


import _ from "lodash";

type MainState = {
    
    name?: string,
    editMode?: boolean,
    take: number,
    skip: number,
    paginate: number; 
    
}

type ListCompliteProp = {
    listTaskComplete: Function,
    listInProcess: boolean,
    auth?: boolean;
    userName?: string;
    user: User;
    refreshUserInfo: Function,
    list: TaskComplited[] | undefined,
}



class EventTask extends React.Component<ListCompliteProp, MainState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            skip:0,
            take:10,
            paginate:10
            
        }
    }
    componentWillUnmount() {
        document.removeEventListener('scroll',this.updateList);
    }
    updateList = () => {
      if( document.documentElement.scrollHeight - (document.documentElement.scrollTop + window.innerHeight)<10 && !this.props.listInProcess)
      {
        let _skip = this.state.skip + this.state.paginate
        let _take = this.state.take
        this.setState({skip:_skip,take:_take})
        
        let filter: TaskListFilterComplite = {
            skip: this.state.skip,
            take: this.state.take,
            
        };
        this.props.listTaskComplete(filter);
      }
    }
    updateEvent() {
        let filter: TaskListFilterComplite = {
            skip: 0,
            take: 10,
            
        };
        document.addEventListener('scroll',this.updateList);
        this.setState({...this.state});
        this.props.listTaskComplete(filter);
    }

   
    componentDidMount() {
        this.props.refreshUserInfo();
        this.updateEvent();
    }
    

    render() 
    {    
        
        return (
            <div>
               <div className='text-Heder'>
                    <h3>События</h3>
                </div>
               
                <div className='event-List'>
                    {
                        _.map(this.props.list, (item: any)=><EventCard event={item} />)
                    }
                </div>
                 <div 
                    className={this.props.listInProcess ? "loader-container" : "ui inverted dimmer"}> 
                </div>
                {this.props.list?.length === 0 && !this.props.listInProcess ?
                                <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> : ''}
            </div> 
        );
    } 
}

 const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskComplitedActions, dispatch),
    ...bindActionCreators({refreshUserInfo, clearListStateClear}, dispatch)
    });
    function mapStateToProps(state: any) {
        let auth: AuthStore = state.authentication;
        let menuStore: TaskStoreMenu = state.home;
        return {
            auth: auth.isAuthUser,
            userName: auth.user ? (auth.user.name ?? auth.user.nickName) || '' : '',
            user: auth.user ?? {},
            listInProcess: menuStore.listInProcess,
            list: menuStore.list,
        }
    }
export default connect(mapStateToProps, mapDispatchToProps)(EventTask);