import React, { Component } from 'react';
import ImageModal from './ImageWrapper';

interface Props {
    url: string;
  }
  
  interface State {
    fileType: 'image' | 'video' | 'none';
  }

  class FileViewer extends Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        fileType: 'none',
      };
    }
  componentDidMount() {
    this.fetchFileType();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.url !== prevProps.url) {
      this.fetchFileType();
    }
  }

  async fetchFileType() {
    try {
      const response = await fetch(this.props.url);
      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType!.startsWith('image/')) {
          this.setState({
            fileType: 'image',
          });
        } else if (contentType!.startsWith('video/')) {
          this.setState({
            fileType: 'video',
          });
        } else {
          this.setState({
            fileType: 'none',
          });
        }
      } else {
        this.setState({
          fileType: 'none',
        });
      }
    } catch (error) {
      this.setState({
        fileType: 'none',
      });
    }
  }

  render() {
    switch (this.state.fileType) {
      case 'image':
        return (
          <ImageModal src={`${this.props.url}`} />    
        );
      case 'video':
        return (
          <video width="90%" controls>
            <source src={`${this.props.url}`} type="video/mp4" />
          </video>
        );
      default:
        return <div></div>;
    }
  }
}

export default FileViewer;