import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as taskComplitedActions from "./../../store/home/actions"
import {clearListStateClear, refreshUserInfo} from "../../store/authentication/actions"
import {AuthStore} from "../../store/baseType";
import {CurrentConfig} from "../../api/config";

import * as like from "../../store/home/actions"
import {LikeFilter,TaskComplited} from "../../store/homeType";



import liceHeardpress from './../../images/liceHeardpress.svg';
import liceHeardonpress from './../../images/liceHeardonpress.svg';
import heart_pressed from './../../images/heart_pressed.svg';
import heart_onpressed from './../../images/heart_onpressed.svg';
import error from './../../images/error.png';
import avatarError from './../../images/avatarError.png';
import maket from './../../images/maket.png';
import ImageModal from '../../components/UI/FileViwer/ImageWrapper';

import styled from 'styled-components';
import ImageLightbox from '../../components/UI/FileViwer/ImageWrap';



    type EventState = 
    {
        counselor?: boolean,
        admin?: boolean,
        isOpenImage: boolean
    }

    type EventProp = {
        likeAdd: Function ,
        likeDelete: Function ,
        event: TaskComplited,
    }


class EventCard extends React.Component<EventProp, EventState> 
{
    basePath: string = CurrentConfig.get().Api;
    constructor(props: any) {
        super(props);
        this.state = 
        {
            isOpenImage: false
        }

    }
    handleImageClick = () => {
        this.setState({
            isOpenImage: true
        })
      };
    handleCloseModal = () => {
        this.setState({
            isOpenImage: false
        })
    };
    handleClickLike = async (isLike: boolean,type:number) => 
    {
       
        let filter: LikeFilter = {
            camperTaskId: this.props.event.id,
            likeType: type
        };
        if(!this.state.counselor && !this.state.admin){
            if(isLike)
            {
                this.props.likeDelete(filter)   
            }else
            {    
                this.props.likeAdd(filter)    
            }  
        }
       
    }
    
    render() {
        return (
            <div className='event-Card'>     
                <table className='event-Table'>
                        <tr>
                            <td className='td1'> 
                                <ImageLightbox imageUrl={this.basePath + '/downloadFileByLink?id=' + this.props.event.linkId} 
                                               linkId={this.props.event.linkId} 
                                               imageClassName={'img-style'}
                                               description = {this.props.event?.taskName}/>
                                
                            </td>
                            <td className='td2'>
                              
                                    <img className='img-style-camper column' onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src=avatarError;
                                        
                                    }} 
                                    src={(this.props.event.linkCamperId == null)?avatarError:this.basePath + '/downloadFileByLink?id=' + this.props.event.linkCamperId} alt="Картинка"></img>
                                    {this.props.event?.camperName}
                                   
                                    <div>
                                          В лагере {this.props.event?.campName} успешно выполнил(ла)  задание "{this.props.event?.taskName}"
                                    </div>
                                    <div className='like-top'>
                                        <div className='event-row'>
                                            <div className='event-column'>
                                                <img className='img-comment' src={(this.props.event.camperLike)?heart_pressed:heart_onpressed} alt="Картинка" onClick={() => this.handleClickLike(this.props.event.camperLike,1)}></img>
                                            </div>
                                            <div className='event-column event-complited'>
                                                {this.props.event.countLike}
                                            </div>
                                        </div>
                                    
                                        <div className='event-row'>
                                            <div className='event-column'>
                                                <img className='img-heart' src={(this.props.event.camperSuperLike)?liceHeardpress:liceHeardonpress} alt="Картинка" onClick={() => this.handleClickLike(this.props.event.camperSuperLike,0)}></img>
                                            </div>
                                            <div className='event-column event-complited'>
                                                {this.props.event.countSuperLike}
                                            </div>
                                        </div>
                                    </div>
                                   
                           
                            </td>
                        </tr>
                        <tr>
                            <td>

                            </td>
                            <td style={{textAlign:'right'}}>
                                <h6>
                                    {this.props.event.compliteDate}
                                </h6>
                            </td>
                        </tr>
                </table>              
            </div>
        );

    }

}

function mapStateToProps(state: any) {
   let authStore: AuthStore = state.authentication;
  
    return {
        counselor: authStore.counselor,
        admin: authStore.admin
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskComplitedActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCard);