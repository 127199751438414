import axios from "axios";
import {CurrentConfig} from './config';
import {ProcessOperationDto, TaskListFilter,TaskListFilterComplite} from "../store/taskType";

const offerApi = {
    list: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/offer/list`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    getOfferTypes: (callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/offer/getOfferTypes`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    newOffer: (offer: any, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/offer/newOffer`, offer).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    setOfferState: (data: any, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/offer/setOfferState`, data).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
    setReadOffer: (type: number, objectId: number, callback: Function) => {
        let config = CurrentConfig.get();
        axios.post(`${config.Api}/newNotification/read?type=${type}&objectId=${objectId}`).then(
            response => {
                callback(response.status, response.data);
            },
            (error) => {
                let response = error || {};
                response = response.response || {};
                callback(response.status, response.data);
            });
    },
}

export default offerApi;