import { Camper } from "./approvingType"

export interface AuthStore {
    isAuthUser?: boolean,
    errorMessage?: string,
    user?: User,
    photoUser?: string,
    roles?: any,
    counselor?: boolean,
    admin?: boolean,
    male?: boolean,
    isLoading?: boolean,

    authIoProcess?: boolean,
    isAfterLogin?: boolean,
    
    restoreRequested?: boolean,
    restoreErrorMessage?: string,
    
    registrationRequested?: boolean,
    registrationErrorMessage?: string,
    
    saveRequested?: boolean,
    saveErrorMessage?: string,
    
    clearCardState?: boolean,
    clearCardStateClear?: boolean

    tokenInfo: TokenInfo,
    isTokenInfoProcess:boolean,
    isTokenInfoError: string
}
//информация для авторизации пользователя в чате
export interface AuthChat{
 grant_type: string,
 scope:string,
 client_id:string,
 username:string,
 password: string
}
export interface TokenInfo{
    access_token: string,
    expires_in: number,
    token_type: string,
    refresh_token: string,
    scope: string
}

// информация для авторизации пользователя
export interface AuthInfo {
    // идентификатор
    id: number | undefined,
    // имя пользователя
    login: string,
    // пароль
    pass: string,
    // ввод/вывод
    authIoProcess: boolean
}
// информация для смены пароля
export interface ChangeInfo
{
    // идентификатор
    id: number | undefined,
    // пароль
    pass: string,
    //текущий пароль
    oldPass: string,
    // ввод/вывод
    authIoProcess: boolean
}
// информация для регистрации пользователя
export interface RegisterInfo {
    nickName: string,
    email: string,
    phone: string,
    pass: string,
    themeId?: number,
    passConfirm: string,
    errors: string[],
    approveRule: boolean,
    approveMailing:boolean,
    readRule: boolean,

}

// информация для регистрации ребенка вожатым
export interface RegisterChildInfo {
    nickName: string,
    email: string,
    phone: string,
    dateOfBirth: Date | null | undefined,
    camper: Camper
}

// пользователь
export interface User {
    // идентификатор
    id: number,
    // псевдоним
    nickName: string,
    //имя
    name: string,
    //пол
    male: boolean,
    //признак вожатый или нет
    counselor: boolean,
    //признак администратор смены или нет
    admin: boolean,
    // всего заработано очков
    totalPoints?: number,
    // всего заработано очков за смену
    totalPointsPerShift?: number,
    // баллов на счету
    points?: number,
    // количество выполненных заданий
    countTask?: number,
    // код учетной записи
    accountKey?: string,
    // телефон
    phone?: string,
    // электронная почта
    email?: string,
    // лагерь
    camp?: string,
    // смена
    bout?: string,
    // отряд
    party?: string,
    // фотография
    photo?: string,
    // фотография
    token?: string,
    // дата рождения
    dateOfBirth?: string,
    //темы
    themes?: Theme[],
    //текущая тема 
    theme: Theme ,
    //является победителем заезда
    winner: BoutWinner,
    //аватарка
    linkId: number,
    //согласен с правилами использования
    approveRule: boolean
    //статус смены
    currentBoutState: number | null;
    uid: string;
    receiveNotifications: boolean
}
export interface BoutWinner{
    winnerId: number,
    isWinner:boolean,
    drawingDescription: string,
    startDate: Date,
    finishDate: Date
}

export interface Theme {

    id: number;
    name: string;
    jsonTheme: string;
}


//статус
export interface State {
    name: string;
    sortOrder: number | null;
    id: number;
}

// Статус учасника
export enum CurrentBoutOutcome{
    /// <summary>
    ///     На смене
    /// </summary>
    OnBout = 1,
    /// <summary>
    ///     Смена закончилась
    /// </summary>
    OffBout = 2,
     /// <summary>
    ///     Не в смене
    /// </summary>
    notBout = 3,
}


// статус задачи
export enum CamperState {
    /// <summary>
    ///     на проверке
    /// </summary>
    OnApproving = 1,

    /// <summary>
    ///     на выполнении
    /// </summary>
    OnExecution = 2,

    /// <summary>
    ///     отменен
    /// </summary>
    Canceled = 3,

    /// <summary>
    ///     выполнен
    /// </summary>
    Done = 4,

    /// <summary>
    ///     не выполнен
    /// </summary>
    NotDone = 5,

    /// <summary>
    ///     Не назначено
    /// </summary>
    UnAssign = 6
}