import _ from "lodash";
import React, { Component } from "react";
import {
    Icon,
    Menu,
    Sidebar,
    Label
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import './Menu.css';
import * as authActions from "./../../store/authentication/actions"
import { refreshUserInfo } from "../../store/authentication/actions"
import { bindActionCreators } from "redux";
import { NotificationStore } from "../../store/notificationType";
import { User, AuthStore ,CurrentBoutOutcome} from "../../store/baseType";

import qrCode from './../../images/qrCode.png';
import Management from './../../images/Management.png';
import Reverse from './../../images/Reverce.png';
import Chat from './../../images/chat.png';

//import menu_icon_child_feedback from './../../images/menu-icon-child-feedback.png';
import menu_icon_home from './../../images/menu-icon-home.svg';
import menu_icon_approving from './../../images/menu-icon-approving.svg';
import menu_icon_child_support from './../../images/menu-icon-child-support.svg';
import menu_icon_gifts_shop from './../../images/menu-icon-gifts-shop.svg';
import menu_icon_offer_list from './../../images/menu-icon-offer-list.svg';
import menu_icon_my_gifts from './../../images/menu-icon-my-gifts.svg';
import menu_icon_my_rating from './../../images/menu-icon-my-rating.svg';
import menu_icon_notification from './../../images/menu-icon-notification.svg';
import menu_icon_personal_account from './../../images/menu-icon-personal-account.svg';
import menu_icon_tasks from './../../images/menu-icon-tasks.svg';
import menu_icon_view_tasks from './../../images/menu-icon-view-tasks.svg';
//import to_home_from_menu from './../../images/to-home_from_menu.png';
//import menu_icon_to_main_page from './../../images/menu-icon-to-main-page.svg';
import menu_icon_logout from './../../images/menu-icon-logout.svg';
import { HandySvg } from 'handy-svg';
import iconSrc from './../../images/borker.svg';
import { QRScanner } from "./QRScanner/QRScanner";
import { getSurvey } from "../../store/feedback/actions";
import { Redirect } from "react-router-dom";

type NavBarProps = {
    children?: any;
    rightItems?: any;
    leftItems?: any;
    onPusherClick?: any;
    visible?: boolean;
    auth?: boolean;
    counselor?: boolean;
    admin?: boolean;
    userName: any[];
    onToggle?: Function;
    clearReduxState?: Function;
    logoutClick?: Function;
    clearListState?: Function;
    notificationCount?: number;
    notificationOfferCount?: number;
    notificationgSurveyCount?: number;
    user: User;
}

export const IconBuck = () => (
    <HandySvg
        src={iconSrc}
        className="icon"
        width="28"
        height="28"
    />
);

const NavBarMobile = ({
    children,
    rightItems,
    leftItems,
    onPusherClick,
    onToggle,
    logoutClick,
    clearListState,
    visible,
    userName,
    counselor,
    admin,
    user,
    notificationCount,
}: NavBarProps) => {
    return (
        <Sidebar.Pushable>
            <Sidebar
                id={"menu-slider2"}
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible={visible}>
                <Menu.Item id={"header-item-main"} as={Link} onClick={() => {
                    if (onToggle) {
                        onToggle();
                    }
                }}>
                    <div id={"menu-header-line21"}></div>
                    <div id={"menu-header-line22"}></div>
                    <div id={"menu-header-line23"}></div>

                    <div id={"menu-header-sphere21"}></div>
                    <div id={"menu-header-sphere22"}></div>
                    <div id={"menu-header-text2"}>Главное меню</div>
                </Menu.Item>

                <Menu.Item id={"menu-start-item2"}>
                </Menu.Item>

                {_.map(leftItems, (item: any) => <Menu.Item to={item.to} content={item.content || item.name} name={item.key} key={item.key} id={"menu-middle-item2"} as={Link} fitted='vertically'
                    onClick={() => {
                        if (onToggle) {
                            onToggle();
                        }
                        if (clearListState) {
                            clearListState();
                        }
                    }}><img id={"menu-middle-icon2"} alt="" src={
                        item.key === 'mainPage' ? menu_icon_home :
                            item.key === 'personalAccount' ? menu_icon_personal_account :
                                item.key === 'task' ? menu_icon_tasks :
                                    item.key === 'viewTask' ? menu_icon_view_tasks :
                                        item.key === 'offerList' ? menu_icon_offer_list :
                                            (item.key === 'childSupport' || item.key === 'counselorSupport') ? menu_icon_child_support :
                                                item.key === 'giftsShop' ? menu_icon_gifts_shop :
                                                    item.key === 'myGifts' ? menu_icon_my_gifts :
                                                        (item.key === 'myRating' || item.key === 'rating') ? menu_icon_my_rating :
                                                            item.key === 'approveChild' ? menu_icon_approving :
                                                                item.key === 'notification' ? menu_icon_notification : undefined
                    } /><div id={"menu-middle-text2"}>{item.content || item.name}</div><div id={"menu-middle-item-low-hr2"}></div></Menu.Item>)}
                <Menu.Item id={"menu-middle-item2"} onClick={() => {
                    if (logoutClick) {
                        logoutClick();
                    }
                }}>
                    <img id={"menu-middle-icon2"} alt="" src={menu_icon_logout} /> <div id={"menu-middle-text2"}>Выход</div>
                </Menu.Item>

                <Menu.Item id={"menu-end-item2"}>
                    <div id={"menu-header-sphere23"}></div>
                    <div id={"menu-header-sphere24"}></div>
                </Menu.Item>

            </Sidebar>
            <Sidebar.Pusher
                dimmed={visible}
                onClick={onPusherClick}
                style={{ minHeight: "100vh" }}>
                <Menu fixed="top" id="navBar"
                //</Sidebar.Pusher> style={(user.theme?.jsonTheme !== undefined) ? JSON.parse(user.theme?.jsonTheme).fon : {minHeight: "100vh"}}
                >
                    <Menu.Item id="menuIcon" onClick={() => {
                        if (onToggle) {
                            onToggle();
                        }
                    }}>
                        <IconBuck />
                        {notificationCount!  > 0 ?
                            <div className={"notification"}>
                                <Label circular color={"blue"} key={"blue"} className={""}>
                                    {notificationCount! < 100 ? notificationCount! : "99+"}
                                </Label> </div> : ""}

                    </Menu.Item>

                    <Menu.Menu className="rightName" id="sectionName">
                        {/*{formWindowName(window.location.pathname, counselor, admin)}*/}
                    </Menu.Menu>

                    <Menu.Menu className="MainPosition" position="right" id="sectionName">
                        {/*{formWindowIcon(window.location.pathname, counselor, admin, userName, rightItems)}*/}
                    </Menu.Menu>

                </Menu>
                {children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>

    );
}

class NavBar extends Component<NavBarProps> {
    state = {
        visible: false
    };
    handlePusher = () => {
        const { visible } = this.state;

        if (visible) this.setState({ visible: false });
    };
    handleToggle = () => this.setState({ visible: !this.state.visible });
    logoutClick = () => {
        if (this.props.clearReduxState) {
            this.props.clearReduxState();
        }

        this.setState({ visible: false });
    }


    /*componentDidUpdate(prevProps: Readonly<NavBarProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.notificationCount != prevProps.notificationCount){
            this.
        }
    }*/

    componentDidMount() {
        calcSectionNameLeftMargin();
        window.addEventListener('resize', calcSectionNameLeftMargin);
    }

    componentDidUpdate() {
        calcSectionNameLeftMargin();
    }

    render() {
        const { children, rightItems, auth, userName, counselor, admin }: NavBarProps = this.props;
        const { visible } = this.state;

        if (!auth) {
            return (<div>{children}</div>);
        }

        let leftItems = !counselor && !admin ?  //Меню для ребёнка
            
                (this.props.user.currentBoutState === CurrentBoutOutcome.notBout)
                ?
                [
                    { to: '/', name: "Главная", key: 'mainPage' },
                    { to: '/account', name: "Личный кабинет", key: 'personalAccount' },
                    { to: '/gift', name: "Магазин подарков", key: 'giftsShop' },
                    { to: '/support', name: "Техническая поддержка", key: 'childSupport' }
                ]
                :
                [
                    { to: '/', name: "Главная", key: 'mainPage' },
                    { to: '/account', name: "Личный кабинет", key: 'personalAccount' },
                    { to: '/task', name: "Задания", key: 'task' },
                    {
                        to: '/offerList',
                        name: "Предложения",
                        content: (<span>
                            Предложения {this.props.notificationOfferCount! > 0 ?
                                <span className={"ui circular label blue notificationRed"}>
                                    {this.props.notificationOfferCount! < 100 ? this.props.notificationOfferCount! : "99+"}
                                </span>
                                : ""}
                        </span>),
                        key: 'offerList'
                    },
                    {
                        to: '/notification',
                        name: "Уведомления",
                        content: (<span>
                            Уведомления {this.props.notificationCount! > 0 ?
                                <span className={"ui circular label blue notificationRed"}>
                                    {this.props.notificationCount! < 100 ? this.props.notificationCount : "99+"}
                                </span>
                                : ""}
                        </span>),
                        key: 'notification'
                    },
                    //{ to: '/offerList', name: "Предложения", key: 'offerList' },
                    { to: '/gift', name: "Магазин подарков", key: 'giftsShop' },
                    //{to: '/wished', name: "Список желаний", key: 'wishList'},
                    { to: '/reserved', name: "Мои подарки", key: 'myGifts' },
                    { to: '/rating', name: "Мой рейтинг", key: 'myRating' },
                    //{to: '/manual', name: "Руководство пользователя", key: 'userManual'},
                    //{to: '/feedback', name: "Обратная связь", key: 'childFeedBack'},
                    { to: '/support', name: "Техническая поддержка", key: 'childSupport' },
                ]
            : counselor && !admin ? [     //Меню для вожатого

                { to: '/', name: "Главная", key: 'mainPage' },
                { to: '/account', name: "Личный кабинет", key: 'personalAccount' },
                { to: '/approving', name: "Подтверждение детей", key: 'approveChild' },
                {
                    to: '/notification',
                    name: "Уведомления",
                    content: (<span>
                        Уведомления {this.props.notificationCount! > 0 ?
                            <span className={"ui circular label blue notificationRed"}>
                                {this.props.notificationCount! < 100 ? this.props.notificationCount : "99+"}
                            </span>
                            : ""}
                    </span>),
                    key: 'notification'
                },
                //{ to: '/offerList', name: "Предложения", key: 'offerList' },
                { to: '/task', name: "Просмотр заданий", key: 'viewTask' },
                { to: '/gift', name: "Магазин подарков", key: 'giftsShop' },
                { to: '/rating', name: "Рейтинг", key: 'rating' },
                { to: '/support', name: "Техническая поддержка", key: 'counselorSupport' },
            ] : !counselor && admin ? [     //Меню для Администратора смены

                { to: '/', name: "Главная", key: 'mainPage' },
                { to: '/account', name: "Личный кабинет", key: 'personalAccount' },
                { to: '/approving', name: "Подтверждение детей", key: 'approveChild' },
                {
                    to: '/notification',
                    name: "Уведомления",
                    content: (<span>
                        Уведомления {this.props.notificationCount! > 0 ?
                            <span className={"ui circular label blue notificationRed"}>
                                {this.props.notificationCount! < 100 ? this.props.notificationCount : "99+"}
                            </span>
                            : ""}
                    </span>),
                    key: 'notification'
                },
                { to: '/task', name: "Просмотр заданий", key: 'viewTask' },
                {
                    to: '/offerList',
                    name: "Предложения",
                    content: (<span>
                        Предложения {this.props.notificationOfferCount! > 0 ?
                        <span className={"ui circular label blue notificationRed"}>
                                    {this.props.notificationOfferCount! < 100 ? this.props.notificationOfferCount! : "99+"}
                                </span>
                        : ""}
                    </span>),
                    key: 'offerList'
                },
                { to: '/gift', name: "Магазин подарков", key: 'giftsShop' },
                { to: '/rating', name: "Рейтинг", key: 'rating' },
                { to: '/support', name: "Техническая поддержка", key: 'counselorSupport' },
            ] : [];

        return (
            <div className={(visible) ? 'visibleslider menu-pushable' : 'hiddenslider'}>
                <NavBarMobile
                    leftItems={leftItems}
                    onPusherClick={this.handlePusher}
                    onToggle={this.handleToggle}
                    logoutClick={this.logoutClick}
                    clearListState={this.props.clearListState}
                    rightItems={rightItems}
                    visible={visible}
                    userName={userName}
                    counselor={counselor}
                    admin={admin}
                    user={this.props.user}
                    notificationCount={this.props.notificationCount! + this.props.notificationOfferCount!}
                    notificationOfferCount={this.props.notificationOfferCount}
                >
                    {children}
                </NavBarMobile>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    let notificationStore: NotificationStore = state.notification;
    let authStore: AuthStore = state.authentication
    return {
        auth: state.authentication.isAuthUser,
        userName: [],
        counselor: authStore.counselor,
        admin: authStore.admin,
        notificationCount: notificationStore.notificationCount,
        notificationOfferCount: notificationStore.notificationOfferTaskCount! + notificationStore.notificationOfferChangeTaskCount!,
        notificationgSurveyCount : notificationStore.notificationSyrvayCount,
        user: state.authentication.user,
        rightItems: [
            <div className="NavBar">
                <Link to="/chat" className="NavBarItem NavBar">
                    <img className='NavBarImg NavBarItem' src={Chat} alt="Картинка"></img>
                    <div className="NavBarItem textNavBar">Чат</div>
                </Link>
                {(!authStore.admin && !authStore.counselor) ?
                    <Link to="/feedback" className="NavBarItem NavBar">
                        <img className='NavBarImg NavBarItem' src={Reverse} alt="Картинка"></img>
                        <div className="NavBarItem textNavBar">
                            Обратная связь
                            <span className={"ui circular label blue notificationRed"}>
                                    {notificationStore.notificationSyrvayCount! < 100 ? notificationStore.notificationSyrvayCount! : "99+"}
                            </span>
                        </div>
                       
                    </Link> : ''
                }

                <Link to="/manual" className="NavBarItem NavBar">
                    <img className='NavBarImg NavBarItem' src={Management} alt="Картинка"></img>
                    <div className="NavBarItem textNavBar">Руководство пользователя</div>
                </Link>
                {(!authStore.admin && !authStore.counselor) ?
                    <Link to="/survayQR" className="NavBarItem NavBar">
                        <img className='NavBarImg NavBarItem' src={qrCode} alt="Картинка"></img>
                    </Link> 
                    : ''
                }
            </div>],

    }
}
function formWindowIcon(pathName: string, counselor?: boolean, admin?: boolean, userName?: String[], rightItems?: String) {
    let windowNames = !counselor ?
        [
            { path: '/', name: rightItems },
            { path: '/main', name: rightItems },
        ] : [
            { path: '/', name: rightItems },
            { path: '/main', name: rightItems },
        ];
    var res = "";
    windowNames.forEach(function (el: any) {
        if (el["path"] == pathName) {
            res = el["name"]
        }
    })

    return res;
}
function formWindowName(pathName: string, counselor?: boolean, admin?: boolean, userName?: String[], rightItems?: String) {
    let windowNames = !counselor && !admin ?
        [
            { path: '/', name: userName },
            { path: '/main', name: userName },
            { path: '/account', name: "Личный кабинет" },
            { path: '/task', name: "Задания" },
            { path: '/offerList', name: "Предложения" },
            { path: '/notification', name: "Уведомления" },
            { path: '/feedback', name: "Обратная связь" },
            { path: '/gift', name: "Магазин подарков" },
            { path: '/reserved', name: "Мои подарки" },
            { path: '/rating', name: "Мой рейтинг" },
            { path: '/manual', name: "Руководство пользователя" },
            { path: '/wished', name: "Список желаний" },
            {path: '/', name: userName},
            {path: '/main', name: userName},
            {path: '/account',name: "Личный кабинет"},
            {path: '/task', name: "Задания"},
            {path: '/offerList', name: "Предложения"},           
            {path: '/notification', name: "Уведомления"},
            {path: '/feedback', name: "Обратная связь"},
            {path: '/chat', name: "Чаты"},
            {path: '/gift', name: "Магазин подарков"},
            {path: '/reserved', name: "Мои подарки"},
            {path: '/rating', name: "Мой рейтинг"},
            {path: '/manual', name: "Руководство пользователя"},
            {path: '/wished', name: "Список желаний"},
        ] : [
            { path: '/', name: userName },
            { path: '/main', name: userName },
            { path: '/account', name: "Личный кабинет" },
            { path: '/approving', name: "Подтверждение детей" },
            { path: '/feedback', name: "Обратная связь" },
            { path: '/task', name: "Задания" },
            { path: '/offerList', name: "Предложения" },
            { path: '/notification', name: "Уведомления" },
            { path: '/rating', name: "Общий рейтинг" },
            { path: '/manual', name: "Руководство пользователя" },
            {path: '/', name: userName},
            {path: '/main', name: userName},
            {path: '/account',name: "Личный кабинет"},
            {path: '/approving', name: "Подтверждение детей"}, 
            {path: '/feedback', name: "Обратная связь"},
            {path: '/chat', name: "Чаты"},
            {path: '/task', name: "Задания"},
            {path: '/offerList', name: "Предложения"},
            {path: '/notification', name: "Уведомления"},
            {path: '/rating', name: "Общий рейтинг"},
            {path: '/manual', name: "Руководство пользователя"},
        ];


    var res = "";
    windowNames.forEach(function (el: any) {
        if (el["path"] == pathName) {
            res = el["name"]
        }
    })

    return res;
}

function calcSectionNameLeftMargin() {
    var menuIcon = document.getElementById('menuIcon');
    menuIcon?.getBoundingClientRect();
    var menuIconWidth = menuIcon?.getBoundingClientRect()?.width;

    var sectionName = document.getElementById('sectionName');
    sectionName?.getBoundingClientRect();
    var sectionNameWidth = sectionName?.getBoundingClientRect()?.width;

    var navBar = document.getElementById('navBar');
    navBar?.getBoundingClientRect();
    var navBarWidth = navBar?.getBoundingClientRect()?.width;

    if (sectionName && menuIconWidth && sectionNameWidth && navBarWidth) {
        var leftMargin = navBarWidth / 2 - sectionNameWidth / 2 - menuIconWidth;
        sectionName.style.marginLeft = leftMargin.toString() + "px";
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
