import React from 'react';
import logo from './../../images/playhelp-logo.png';
import './StartScreen.css';
import '../../components/main.css';
import 'semantic-ui-css/semantic.min.css'
import {Button} from 'semantic-ui-react'
import {Link} from "react-router-dom";
import * as authActions from "./../../store/authentication/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


class StartScreen extends React.Component<{ authorizeUser: Function, wrapper: any }>{
    wrapper:any
    constructor(props: any) {
        super(props);
        this.wrapper = React.createRef();
    }
    render() {
        return (
            <div className="App" ref={this.wrapper}>
                <div className="start-screen-background">
                    <div className="start-screen-header">
                        <br/><br/><br/>
                        <img alt='ГУАК "Мосгортур"' src={logo} height="165px"/>
                        <br/><br/><br/><br/>
                        <div className="start-screen-header-text1">Отдыхай, развивайся, помогай вожатым - создай свои незабываемые каникулы!</div>
                        <br/><br/>
                        <Button id="start-screen-login-button" fluid={true} color={"violet"} to='/login' as={Link}>Войти</Button>
                        <Button id="start-screen-reg-button" fluid={true} to='/registration' as={Link}>Регистрация</Button>
                        <br/>
                        <a id="start-screen-detail-text" href={"https://mosgortur.ru/igrai-pomogai"}>Подробнее</a>
                        <br/><br/><br/>
                    </div>
                </div>
            </div>            
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(authActions, dispatch),
});

export default connect(undefined, mapDispatchToProps)(StartScreen);