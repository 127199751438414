import {
    FEEDBACK_LIST_FAILED,
    FEEDBACK_LIST_SUCCESS,
    FEEDBACK_LIST_PROCESS,
    FEEDBACK_TITLES_LIST_FAILED,
    FEEDBACK_TITLES_LIST_SUCCESS,
    FEEDBACK_TITLES_LIST_PROCESS,
    SURVEYQR_TITLES_LIST_SUCCESS,
    FEEDBACK_SEND_FAILED,
    FEEDBACK_SEND_SUCCESS,
    FEEDBACK_SEND_PROCESS,
    FEEDBACK_SET_REDIRECT_FROM_SUPPORT,
    FEEDBACK_CLEAR_REDIRECT_FROM_SUPPORT,
    //FEEDBACK_ADD_SUPPORT_TO_TITLES,
    SURVEY_TITLES_LIST_SUCCESS,
    SURVEY_TITLES_LIST_FAILED,
    SURVEY_TITLES_LIST_PROCESS,

    SURVEY_SET_FAILED,
    SURVEY_SET_SUCCESS,
    SURVEY_SET_PROCESS,

    SURVEYQR_SET_FAILED,
    SURVEYQR_SET_SUCCESS,
    SURVEYQR_SET_PROCESS,

    SURVEY_GET_SUCCESS,
    SURVEY_GET_FAILED,
    SURVEY_GET_PROCESS,
    SURVAY_SELECT,
    FEEDBACK_SELECT,
    SURVAY_REDIRECT_FROM_MAIN_TRUE,
    SURVAY_REDIRECT_FROM_MAIN_FALSE
} from "./types";

import {Reducer} from "redux";
import {Feedback, FeedbackStore, FeedbackTitle, Survay} from "../feedbackType";
import { remove } from "lodash";

const initialState = () => {
    return {
        feedbacksList: [] as Feedback[],
        errorInFeedbacksList: '',
        feedbacksListInProcess: false,

        feedbackTitles: [] as FeedbackTitle[],
        errorInfeedbackTitles: '',
        feedbackTitlesInProcess: false,
        
        errorInSendFeedback: '',
        sendFeedbackInProcess: false,

        selectedFeedbackItem: {} as Feedback,

        isRedirectFromSupport: false,

        errorInSendSurvay: '',
        sendSurvayTitlesInProcess: false,
        surveyTitlesList: [] as  Survay[],
        surveyTitlesQRList: [] as Survay[],
        selectedSurvayItem: {} as Survay,
        qrReaderMode: false,
        errorSendGetSurvay: '',
        allowRedirectFromMain: false,

    } as FeedbackStore;
};

const feedbackStore: Reducer = (state: FeedbackStore = initialState(), action: any) => {
    switch (action.type) {
        case FEEDBACK_LIST_FAILED: {
            return {
                ...state,
                errorInFeedbacksList: action.errorMessage,
                feedbacksListInProcess: false
            };
        }
        case FEEDBACK_LIST_SUCCESS: {
            return {
                ...state,
                feedbacksListInProcess: false,
                feedbacksList: action.data
            };
        }
        case FEEDBACK_LIST_PROCESS: {
            return {
                ...state,
                errorInFeedbacksList: '',
                feedbacksListInProcess: true
            };
        }

        case FEEDBACK_TITLES_LIST_FAILED: {
            return {
                ...state,
                errorInfeedbackTitles: action.errorMessage,
                feedbackTitlesInProcess: false
            };
        }
        case FEEDBACK_TITLES_LIST_SUCCESS: {
            return {
                ...state,
                feedbackTitlesInProcess: false,
                feedbackTitles: action.data
            };
        }
        case FEEDBACK_TITLES_LIST_PROCESS: {
            return {
                ...state,
                errorInfeedbackTitles: '',
                feedbackTitlesInProcess: true
            };
        }

        case FEEDBACK_SEND_FAILED: {
            return {
                ...state,
                errorInSendFeedback: action.errorMessage,
                sendFeedbackInProcess: false
            };
        }
        case FEEDBACK_SEND_SUCCESS: {
            return {
                ...state,
                errorInSendFeedback: '',
                sendFeedbackInProcess: false,
                feedbacksList: [action.data , ...state.feedbacksList]
            };
        }
        case FEEDBACK_SEND_PROCESS: {
            return {
                ...state,
                errorInSendFeedback: '',
                sendFeedbackInProcess: true
            };
        }

        case FEEDBACK_SET_REDIRECT_FROM_SUPPORT: {
            return {
                ...state,
                isRedirectFromSupport: true
            };
        }
        case FEEDBACK_CLEAR_REDIRECT_FROM_SUPPORT: {
            return {
                ...state,
                isRedirectFromSupport: false
            };
        }
        //case FEEDBACK_ADD_SUPPORT_TO_TITLES: {
        //    return {
        //        ...state,
        //        feedbackTitles: action.feedbackTitles,
        //    };
        //}

        case SURVEY_TITLES_LIST_FAILED: {
            return {
                ...state,
                errorInSendSurvay: action.errorMessage,
                sendSurvayTitlesInProcess: false,
                surveyTitlesList:null
            };
        }
        case SURVEY_TITLES_LIST_SUCCESS: {
            return {
                ...state,
                sendSurvayTitlesInProcess: false,
                surveyTitlesList: action.data   
            };
        }
        case SURVEYQR_TITLES_LIST_SUCCESS: {
            return {
                ...state,
                sendSurvayTitlesInProcess: false,
                surveyTitlesQRList: action.data,  
            };
        }
        case SURVEY_TITLES_LIST_PROCESS: {
            return {
                ...state,
                errorInSendSurvay: '',
                sendSurvayTitlesInProcess: true
            };
        }     
        case SURVEY_SET_FAILED: {
            return {
                ...state,
                errorInSendSurvay: action.errorMessage,
                sendSurvayTitlesInProcess: false
            };
        }
        case SURVEY_SET_SUCCESS: {
            return {
                ...state,
                sendSurvayTitlesInProcess: false,
                surveyTitlesList: state.surveyTitlesList.map(item => {
                    if (item.id === action.data.id) {
                      return action.data;
                    }
                    return item;
                }),
                selectedSurvayItem: action.data
            };
        }
        case SURVEY_SET_PROCESS: {
            return {
                ...state,
                errorInSendSurvay: '',
                sendSurvayTitlesInProcess: true
            };
        } 
        case SURVEYQR_SET_FAILED: {
            return {
                ...state,
                errorInSendSurvay: action.errorMessage,
                sendSurvayTitlesInProcess: false
            };
        }
        case SURVEYQR_SET_SUCCESS: {
            return {
                ...state,
                sendSurvayTitlesInProcess: false,
                selectedSurvayItem: action.data
            };
        }
        case SURVEYQR_SET_PROCESS: {
            return {
                ...state,
                errorInSendSurvay: '',
                sendSurvayTitlesInProcess: true
            };
        }
        case SURVEY_GET_FAILED: {
            return {
                ...state,
                errorSendGetSurvay: action.errorMessage,
                sendSurvayTitlesInProcess: false,
                qrReaderMode: false
            };
        }
        case SURVEY_GET_SUCCESS: {
            return {
                ...state,
                errorSendGetSurvay:'',
                qrReaderMode: true,
                allowRedirectFromMain: true,
                sendSurvayTitlesInProcess: false,
                selectedSurvayItem: action.data
            };
        }
        case SURVEY_GET_PROCESS: {
            return {
                ...state,
                errorInSendSurvay: '',
                sendSurvayTitlesInProcess: true
            };
        }
        case SURVAY_SELECT: {
            return {
                ...state,
                selectedSurvayItem: action.data,
                errorInSendSurvay:''
            };
        }
        case FEEDBACK_SELECT: {
            return {
                ...state,
                selectedFeedbackItem: action.data,
            };
        }
        case SURVAY_REDIRECT_FROM_MAIN_FALSE: {
            return {
                ...state,
                allowRedirectFromMain: false,
            }
        }
        default:
            return state;
    }
};

export default feedbackStore;
