interface Config {
    Api?: string,
    BasePath?: string,
    Chat?: string,
    FeedbackPhotoUrl?: string,
    DynamicUrl?:boolean
}

export class CurrentConfig {
    static currentConfig: Config = {
        Api: "",
        BasePath: "",
        Chat: "",
        FeedbackPhotoUrl: "",
        DynamicUrl: false
    };

    static init = () => {
        const config = {
            Api:  process.env.REACT_APP_API_URL,
            BasePath: process.env.REACT_APP_URL,
            Chat: process.env.REACT_APP_CHAT_URL,
            FeedbackPhotoUrl: process.env.REACT_APP_API_URL,
            DynamicUrl: process.env.REACT_APP_DYNAMIC_URL == "true" ? true : false
        };
        CurrentConfig.set(config);
    };

    static set = (config: Config) => {
        CurrentConfig.currentConfig.Api = config.Api;
        CurrentConfig.currentConfig.BasePath = config.BasePath || "";
        CurrentConfig.currentConfig.Chat = config.Chat;
        CurrentConfig.currentConfig.FeedbackPhotoUrl = config.FeedbackPhotoUrl;
        CurrentConfig.currentConfig.DynamicUrl = config.DynamicUrl;
    };

    static get = () => ({
        Api: CurrentConfig.currentConfig.Api || "https://m.aiso.mos.ru/api",
        BasePath: CurrentConfig.currentConfig.BasePath || "https://m.aiso.mos.ru",
        Chat: CurrentConfig.currentConfig.Chat || "https://aisochat.mos.ru",
        FeedbackPhotoUrl: CurrentConfig.currentConfig.FeedbackPhotoUrl || "https://m.aiso.mos.ru/api",
        DynamicUrl: CurrentConfig.currentConfig.DynamicUrl || false
    });
}


//interface Config {
//    Api?: string,
//    BasePath?: string,
//    Chat?: string,
//    FeedbackPhotoUrl?: string,
//    DynamicUrl?:boolean
//}

//export class CurrentConfig {
//    static currentConfig: Config = {
//        Api: "",
//        BasePath: "",
//        Chat: "",
//        FeedbackPhotoUrl: "",
//        DynamicUrl: false,
//    };

//    static init = () => {
//        let dynamicUrl: boolean = JSON.parse((process.env.REACT_APP_DYNAMIC_URL ?? "false").toLowerCase());
//        console.log("process.env.REACT_APP_CHAT_URL");
//        console.log(process.env.REACT_APP_CHAT_URL);
//        const config = {
//            Api: !(dynamicUrl) ? process.env.REACT_APP_API_URL : window.location.origin,
//            BasePath: window.location.origin,
//            Chat: process.env.REACT_APP_CHAT_URL,
//            FeedbackPhotoUrl: !(dynamicUrl) ? process.env.REACT_APP_API_URL : window.location.origin,
//            DynamicUrl: dynamicUrl,
//        };
//        CurrentConfig.set(config);
//    };

//    static set = (config: Config) => {
//        console.log("CC.set");
//        CurrentConfig.currentConfig.Api = config.Api;
//        CurrentConfig.currentConfig.BasePath = config.BasePath;
//        CurrentConfig.currentConfig.Chat = config.Chat;
//        CurrentConfig.currentConfig.FeedbackPhotoUrl = config.FeedbackPhotoUrl;
//        CurrentConfig.currentConfig.DynamicUrl = config.DynamicUrl

//    };

//    static get = () => ({
//        Api: CurrentConfig.currentConfig.Api || "https://m.aiso.mos.ru/api",
//        BasePath: CurrentConfig.currentConfig.BasePath || "https://m.aiso.mos.ru",
//        Chat: CurrentConfig.currentConfig.Chat || "https://chat.aiso.mos.ru",
//        FeedbackPhotoUrl: CurrentConfig.currentConfig.FeedbackPhotoUrl || "https://m.aiso.mos.ru/api",
//        DynamicUrl: CurrentConfig.currentConfig.DynamicUrl || false
//    });
//}