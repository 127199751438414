import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import * as taskActions from "./../../store/task/actions"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {CurrentConfig} from "../../api/config";
import {Notification} from "../../store/notificationType";
import {Icon} from "semantic-ui-react";

type NotificationRowState = {}

type NotificationRowProp = {
    notification: Notification,
    onClick: Function
}


class NotificationRow extends React.Component<NotificationRowProp, NotificationRowState> {
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="ui segment ui-container" onClick={(e) => {
                if (e.button === 0) {
                    this.props.onClick(this.props.notification)
                }
            }}>
                <span className={"notification-photo"}>
                    <Icon name={this.props.notification.answer ? "envelope open outline" : "envelope outline"}/>
                </span>
                <div className={"notification-body"}>
                    <div className={"notification-name"}>
                        {this.props.notification?.camperTask?.boutTask?.name}
                    </div>
                    <div>
                        {this.props.notification.whoAsk?.name}
                    </div>
                    <div className={"notification-row-label"}>
                        Вопрос
                    </div>
                    <div>
                        {this.props.notification.description}
                    </div>
                    {this.props.notification.answer ? (
                        <div>
                            <div className={"notification-row-label"}>
                                Ответ
                            </div>
                            <div>
                                {this.props.notification.answer}
                            </div>
                        </div>
                    ) : ""}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {}
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationRow);