import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Button, Dropdown, Input } from 'semantic-ui-react'
import * as taskActions from "../../store/gift/actions"
import { clearListState, clearListStateClear, refreshUserInfo } from "../../store/authentication/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputOnChangeData } from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import { Gift, ListGiftFilter, GiftStore, GiftCategory } from "../../store/giftType";
import _ from "lodash";
import GiftRow from "./GiftRow";
import './Gift.css';
import { CurrentConfig } from '../../api/config';
import GiftCard from "./GiftCard";
import { AuthStore } from "../../store/baseType";

type GiftState = {
    filter: string,

    /* стомость от */
    pf?: number

    /* стоимость до */
    pt?: number,

    /* видно фильтр */
    filterView: boolean,

    /* видно карточку */
    cardMode: boolean,

    /* категории подарков */
    giftCategoriesFilter: number[] | any,
}

type GiftProp = {
    listGifts: Function,
    refreshUserInfo: Function,
    listReservedGifts: Function
    selectGift: Function
    listInProcess: boolean,
    clearCardState: boolean,
    clearListStateClear: Function,
    list: Gift[] | undefined,
    wishList: Gift[] | undefined,
    errorInList: string | undefined,
    selectedItem: Gift | undefined,
    getGiftCategories: Function,
    typeInProcess: boolean,
    errorInType: string | undefined,
    giftCategories: GiftCategory[] | undefined

}


class GiftList extends React.Component<GiftProp, GiftState> {
    interval: any = undefined;
    basePath: string = CurrentConfig.get().Api;

    constructor(props: any) {
        super(props);
        this.state = {
            filter: '',
            filterView: false,
            cardMode: false,
            giftCategoriesFilter: []
        }
    }

    searchItems() {
        let filter: ListGiftFilter = {
            pf: this.state.pf,
            pt: this.state.pt,
            t: this.state.filter,
            giftCategoriesFilter: this.state.giftCategoriesFilter
        };

        this.setState({ ...this.state, filterView: false });
        this.props.listGifts(filter);
        //this.props.getGiftCategories();
    }

    componentDidMount() {
        this.props.refreshUserInfo();
        this.searchItems();

    }

    componentDidUpdate(prevProps: Readonly<GiftProp>, prevState: Readonly<GiftState>, snapshot?: any) {
        if (!prevProps.clearCardState && this.props.clearCardState) {
            this.setState({
                ...this.state,
                cardMode: false
            });
        }

        if (this.props.clearCardState) {
            this.props.clearListStateClear();
        }
    }

    render() {
        return (
            <div>
                {(!this.state.cardMode) ?
                    <table className="wish-list-table">
                        <tr>
                            <td>
                                <div className="taskBlock">
                                    <div>
                                        <div className={this.state.filterView ? "" : "hidden"}></div>
                                        <div>
                                            <div
                                                className={this.props.listInProcess ? "ui active inverted dimmer" : "ui inverted dimmer"}>
                                                <div className="ui text loader">Загрузка...</div>
                                            </div>
                                            {_.map(this.props.wishList, (item: any) => <GiftRow gift={item} onClick={(item: Gift) => { }} inGiftLent={false} />)}
                                            {!this.props.wishList || this.props.wishList?.length === 0 ?
                                                <div className={"approve-not-found"}>В результате поиска ничего не найдено</div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="gift-lent-title">
                                    <b>Лента подарков</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="scrolling-wrapper">
                                    {_.map(this.props.list, (item: any) => <div className="card"><GiftRow gift={item} onClick={(item: Gift) => {
                                        this.props.selectGift(item);
                                        this.setState({
                                            ...this.state,
                                            cardMode: true
                                        });
                                    }} inGiftLent={true} /></div>)}
                                </div>
                            </td>
                        </tr>
                    </table> :
                    <div className="taskBlock">
                        <GiftCard
                            backToList={() => {
                                this.setState({
                                    ...this.state,
                                    cardMode: false,
                                });
                            }}
                            updateGiftList={() => {
                                this.searchItems();
                            }}
                        />
                    </div>}
            </div>);
    }
}

function mapStateToProps(state: any) {
    let giftStore: GiftStore = state.gift;
    let authStore: AuthStore = state.authentication;
    return {
        listInProcess: giftStore.listInProcess,
        list: giftStore.list,
        errorInList: giftStore.errorInList,
        clearCardState: authStore.clearCardState,
        wishList: giftStore.wishList,
        giftCategories: giftStore.giftCategories,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(taskActions, dispatch),
    ...bindActionCreators({ refreshUserInfo, clearListStateClear }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftList);