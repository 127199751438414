export const APPROVING_LIST_SUCCESS = "APPROVING_LIST_SUCCESS";
export const APPROVING_LIST_FAILED = "APPROVING_LIST_FAILED";
export const APPROVING_LIST_PROCESS = "APPROVING_LIST_PROCESS";

export const APPROVING_CHILD_SUCCESS = "APPROVING_CHILD_SUCCESS";
export const APPROVING_CHILD_FAILED = "APPROVING_CHILD_FAILED";
export const APPROVING_CHILD_PROCESS = "APPROVING_CHILD_PROCESS";

export const CHILD_SELECT = "CHILD_SELECT";
export const REGISTER_CHILD_SUCCESS = "REGISTER_CHILD_SUCCESS";
export const REGISTER_CHILD_FAILED = "REGISTER_CHILD_FAILED";
export const REGISTER_CHILD_PROCESS = "REGISTER_CHILD_PROCESS";