import {
    TASK_LIST_COMPLITED_FAILED,
    TASK_LIST_COMPLITED_SUCCESS,
    TASK_LIST_COMPLITED_PROCESS,
    LIKE_LIST_SUCCESS,
    LIKE_LIST_FAILED,
    LIKE_LIST_PROCESS,
    NOTIFICATIONNEWS_LIST_SUCCESS,
    NOTIFICATIONNEWS_LIST_FAILED,
    NOTIFICATIONNEWS_LIST_PROCESS
} from "./types";
import {clearReduxStateInternal} from '../authentication/actions'
import taskApi from "../../api/task";
import likeApi from "../../api/like";
import notificationNews from "../../api/notificationNews";
import {TaskListFilterComplite, TaskComplited, LikeFilter, MassNotificationNew} from "../homeType";
// получение списка выполненых заданий смены
export const listTaskComplete = (filter: TaskListFilterComplite) => (dispatch: any) => {
    taskApi.listComplited(filter, (status: number, data: TaskComplited[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_LIST_COMPLITED_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_LIST_COMPLITED_FAILED,
                errorMessage: data || 'Ошибка получения списка заданий'
            });
        }
    });

    return dispatch({
        type: TASK_LIST_COMPLITED_PROCESS
    });
}
// прикрепить лайк к задаче
export const likeAdd =  (filter: LikeFilter) => (dispatch: any) => {
   
    likeApi.Add(filter, (status: number, data: TaskComplited) => {
     if (status === 200) {
         dispatch({
             type: LIKE_LIST_SUCCESS,
             data
         });
     } else if (status === 401) {
         dispatch(clearReduxStateInternal());
     } else {
         return dispatch({
             type: LIKE_LIST_FAILED,
             errorMessage: data || 'Ошибка прикрепления лайка к заданию'
         });
     }
     });
     return dispatch({
         type: LIKE_LIST_PROCESS
     });
 }
    // открепить лайк от задачи
 export const likeDelete = (filter: LikeFilter) => (dispatch: any) => {
     likeApi.Delete(filter, (status: number, data: TaskComplited) => {
         if (status === 200) {
             dispatch({
                 type: LIKE_LIST_SUCCESS,
                 data
             });
         } else if (status === 401) {
             dispatch(clearReduxStateInternal());
         } else {
             return dispatch({
                 type: LIKE_LIST_FAILED,
                 errorMessage: data || 'Ошибка прикрепления лайка к заданию'
             });
         }
     });
     return dispatch({
         type: LIKE_LIST_PROCESS
     });
 }

 // получение уведомлений
export const getNotificationNews = () => (dispatch: any) => {
    notificationNews.list((status: number, data: MassNotificationNew[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: NOTIFICATIONNEWS_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: NOTIFICATIONNEWS_LIST_FAILED,
                errorMessage: data || 'Ошибка получения уведомлений'
            });
        }
    });

    return dispatch({
        type: NOTIFICATIONNEWS_LIST_PROCESS
    });
}