import React, { useState } from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  color: transparent;
  border: none;
  font-size: 20px;
`;



const ImageModal = ({ src }: { src: string }) => {
  const [modalOpen, setModalOpen] = useState(false);
 

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  
  

  return (
    <>
      <img src={src}  width="90%" alt="Image" onClick={handleModalOpen} />
      {modalOpen && (
        <ModalWrapper onClick={handleModalClose}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={handleModalClose}>&times;</CloseButton>
            <ModalImg src={src} alt="Image" />
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};

export default ImageModal;