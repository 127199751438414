import {
    TASK_LIST_FAILED,
    TASK_LIST_SUCCESS,
    TASK_LIST_PROCESS,
    TASK_SUCCESS,
    TASK_PROCESS,
    TASK_FAILED,
    TASK_PHOTO_SUCCESS,
    TASK_PHOTO_FAILED,
    TASK_PHOTO_PROCESS,
    TASK_SELECT,
    TASK_PHOTO_REMOVE,
    TASK_CATEGORIES_SUCCESS,
    TASK_CATEGORIES_FAILED,
    TASK_CATEGORIES_PROCESS,
    TASK_OFFER_SUCCESS,
    TASK_OFFER_FAILED,
    TASK_OFFER_PROCESS
} from "./types";
import {clearReduxStateInternal} from '../authentication/actions'
import taskApi from "../../api/task";
import {CamperTask, FileItem, ProcessOperationDto, TaskListFilter, TaskCategory} from "../taskType";


// получение списка заданий
export const listTask = (filter: TaskListFilter) => (dispatch: any) => {
    taskApi.list(filter, (status: number, data: CamperTask[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_LIST_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_LIST_FAILED,
                errorMessage: data || 'Ошибка получения списка заданий'
            });
        }
    });

    return dispatch({
        type: TASK_LIST_PROCESS
    });
}

/// выполнение задания
export const beginTask = (id: number) => (dispatch: any) => {
    taskApi.beginTask(id, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_FAILED,
                errorMessage: data || 'Ошибка начала выполнения задания'
            });
        }
    });

    return dispatch({
        type: TASK_PROCESS
    });
}

/// отказ от выполнения задания
export const cancelTask = (model: ProcessOperationDto) => (dispatch: any) => {
    taskApi.cancelTask(model, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_FAILED,
                errorMessage: data || 'Ошибка отказа от выполнения задания'
            });
        }
    });

    return dispatch({
        type: TASK_PROCESS
    });
}


/// отметить факт выполнения задания
export const done = (model: ProcessOperationDto) => (dispatch: any) => {
    taskApi.done(model, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_FAILED,
                errorMessage: data || 'Ошибка проставления признака что задание выполнено'
            });
        }
    });

    return dispatch({
        type: TASK_PROCESS
    });
}

/// отметить факт не выполнения задания
export const notDone = (model: ProcessOperationDto) => (dispatch: any) => {
    taskApi.notDone(model, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_FAILED,
                errorMessage: data || 'Ошибка проставления признака что задание не выполнено'
            });
        }
    });

    return dispatch({
        type: TASK_PROCESS
    });
}

/// отметить факт отправки на согласовании
export const onApproving = (model: ProcessOperationDto) => (dispatch: any) => {
    taskApi.onApproving(model, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_FAILED,
                errorMessage: data || 'Ошибка проставления признака что задание выполнено и должно быть проверено'
            });
        }
    });

    return dispatch({
        type: TASK_PROCESS
    });
}

/// отправить запрос на разъяснения
export const onAsk = (model: ProcessOperationDto) => (dispatch: any) => {
    taskApi.onAsk(model, (status: number, data: CamperTask | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_FAILED,
                errorMessage: data || 'Ошибка отправки запроса на разъяснение'
            });
        }
    });

    return dispatch({
        type: TASK_PROCESS
    });
}

// загрузить фотографию
export const uploadPhoto = (data: any) => (dispatch: any) => {
    taskApi.uploadPhoto(data, 2, (status: number, data: string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_PHOTO_SUCCESS,
                data: data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_PHOTO_FAILED,
                errorMessage: data || 'Ошибка загрузки фотографии'
            });
        }
    });

    return dispatch({
        type: TASK_PHOTO_PROCESS,
    });
};

// удалить фотографию
export const removePhoto = (data: FileItem) => (dispatch: any) => {
    return dispatch({
        type: TASK_PHOTO_REMOVE,
        data: data
    });
};

/// выбрать задачу
export const selectTask = (model: CamperTask) => (dispatch: any) => {
    return dispatch({
        type: TASK_SELECT,
        data: model
    });
}

// получение типов заданий
export const getTaskCategory = () => (dispatch: any) => {
    taskApi.getTaskCategory((status: number, data: TaskCategory[] | string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_CATEGORIES_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_CATEGORIES_FAILED,
                errorMessage: data || 'Ошибка получения типов заданий'
            });
        }
    });

    return dispatch({
        type: TASK_CATEGORIES_PROCESS
    });
}
// отправка предложение задания
export const setOfferTask = (model: any) => (dispatch: any) => {
    taskApi.setOfferTask(model,(status: number, data: string | undefined) => {
        if (status === 200) {
            dispatch({
                type: TASK_OFFER_SUCCESS,
                data
            });
        } else if (status === 401) {
            dispatch(clearReduxStateInternal());
        } else {
            return dispatch({
                type: TASK_OFFER_FAILED,
                errorMessage: data || 'Ошибка получения типов заданий'
            });
        }
    });

    return dispatch({
        type: TASK_OFFER_PROCESS
    });
}